import axios from "axios";
import {url} from "./urls";

export const zavrsneKontrole = async () => {
    return axios.get(url + '/zavrsne-kontrole', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const startZavrsnaKontrola = async (formData) => {
    return axios.post(url + '/start-zavrsna-kontrola', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaZavrsnuKontrolu = async (id) => {
    return axios.get(url + '/za-zavrsnu-kontrolu/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const iskontrolisiteZK = async (formData) => {
    return axios.post(url + '/iskontrolisite-zk', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const endZavrsnaKontrola = async (formData) => {
    return axios.post(url + '/end-zavrsna-kontrola', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const spakujteProizvod = async (formData) => {
    return axios.post(url + '/spakujte-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const formirajtePakovanje = async (formData) => {
    return axios.post(url + '/formirajte-pakovanje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}