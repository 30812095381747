import axios from "axios";
import {url} from "./urls";

export const poliranja = async (id_radnik) => {
    return axios.get(url + '/poliranja/' + id_radnik, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const poliranje = async (id) => {
    return axios.get(url + '/poliranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const startPoliranje = async (formData) => {
    return axios.post(url + '/start-poliranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaPoliranje = async (id) => {
    return axios.get(url + '/za-poliranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaKontroluPoliranja = async (id) => {
    return axios.get(url + '/za-kontrolu-poliranja/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const ispolirajteProizvod = async (formData) => {
    return axios.post(url + '/ispolirajte-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const iskontrolisiteProizvodPoliranje = async (formData) => {
    return axios.post(url + '/iskontrolisite-proizvod-poliranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const proslediteKontroloruPoliranje = async (formData) => {
    return axios.post(url + '/prosledite-kontroloru-poliranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const finishPoliranje = async (formData) => {
    return axios.post(url + '/end-poliranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const poliranjaZaKontrolu = async () => {
    return axios.get(url + '/poliranje-za-kontrolu', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
