import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createKupac } from "../../../apis/kupciApi";
import {
    Container,
    Paper,
    Typography,
    Button,
    TextField,
} from "@mui/material";

export const KreirajKupacPage = () => {
    const [formData, setFormData] = useState({ naziv: "", adresa: "", telefon: "", email: "" });
    const navigate = useNavigate();

    const handleCreate = async () => {
        try {
            await createKupac(formData);
            navigate("../kupci");
        } catch (error) {
            console.error("Greška pri kreiranju kupca:", error);
        }
    };

    return (
        <Container>
            <Paper sx={{ p: 3, mt: 2 }}>
                <Typography variant="h4">Novi Kupac</Typography>
                <TextField label="Naziv" fullWidth value={formData.naziv} onChange={(e) => setFormData({ ...formData, naziv: e.target.value })} sx={{ mb: 2 }} />
                <TextField label="GLN" fullWidth value={formData.gln} onChange={(e) => setFormData({ ...formData, gln: e.target.value })} sx={{ mb: 2 }} />
                <TextField label="Šifra" fullWidth value={formData.sifra} onChange={(e) => setFormData({ ...formData, sifra: e.target.value })} sx={{ mb: 2 }} />
                <TextField label="Adresa" fullWidth value={formData.adresa} onChange={(e) => setFormData({ ...formData, adresa: e.target.value })} sx={{ mb: 2 }} />
                <TextField label="Telefon" fullWidth value={formData.telefon} onChange={(e) => setFormData({ ...formData, telefon: e.target.value })} sx={{ mb: 2 }} />
                <TextField label="Email" fullWidth value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} sx={{ mb: 2 }} />
                <Button variant="contained" color="primary" onClick={handleCreate}>Kreiraj</Button>
            </Paper>
        </Container>
    );
};