import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {otpremnica} from "../../../apis/otpremnicaApi";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import Grid from "@mui/material/Grid2";
import {Alert, LinearProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";

const SkladistarOtpremnicaPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['otpremnica', id],
        queryFn: () => otpremnica(id)
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o otpremnici
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o otpremnici!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.otpremnica.id}</b></Item>
                            <Divider/>
                            <Item>Radnik: <b>{data?.data.otpremnica.radnik}</b></Item>
                            <Divider/>
                            <Item>Kupac: <b>{data?.data.otpremnica.naziv_kupca} {data?.data.otpremnica.gln_kupca} {data?.data.otpremnica.sifra_kupca}</b></Item>
                            <Divider/>
                            <Item>Mesto
                                isporuke: <b>{data?.data.otpremnica.gln_mesta_isporuke} {data?.data.otpremnica.sifra_mesta_isporuke}</b></Item>
                            <Divider/>
                            <Item>Registracija kamiona: <b>{data?.data.otpremnica.registracija_kamiona}</b></Item>
                            <Divider/>
                            <Item>Naziv šofera: <b>{data?.data.otpremnica.naziv_sofera}</b></Item>
                            <Divider/>
                            <Item>Status: <b>{data?.data.otpremnica.status == 1 ? "Otpremljena" : "Nije otpremljena"}</b></Item>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.otpremnica.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 0.5, justifyContent: "center", alignItems: "center", height: 400}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data.proizvodi}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SkladistarOtpremnicaPage;