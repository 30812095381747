import React from 'react';
import {Navigate, Outlet} from "react-router";
import PoliranjeLayout from "../layouts/PoliranjeLayout";

const PoliranjeProtectedRoute = () => {
    let role_id = localStorage.getItem("role_id");

    if (role_id != 4) {
        return <Navigate to="/"/>;
    }

    return (
        <PoliranjeLayout>
            <Outlet/>
        </PoliranjeLayout>
    );
};

export default PoliranjeProtectedRoute;