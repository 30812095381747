import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {prijemRobe} from "../../apis/prijemRobeApi";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import Grid from "@mui/material/Grid2";
import {Item} from "../../components/additional/Item";
import Stack from "@mui/material/Stack";
import {Alert, LinearProgress} from "@mui/material";
import Divider from "@mui/material/Divider";
import {tableLang} from "../../language/tableLang";
import {DataGrid} from "@mui/x-data-grid";

const PrijemRobePage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["PrijemRobeInfo", id],
        queryFn: () => prijemRobe(id)
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra robe",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv robe",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "status",
            headerName: "Stanje robe",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Ime i prezime kontrolora",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <span>{params.row.ime} {params.row.prezime}</span>
                )
            }
        },
        {
            field: "naziv_dobavljaca",
            headerName: "Dobavljač",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme prijema",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()}/{(date.getMonth() + 1)}/{date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o ovom prijemu robe
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 18, textAlign: "center"}}>Osnovne informacije</Typography>
                    <Divider sx={{m: 2}}/>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podataka o prijemu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Item>Ime i prezime
                                kontrolora: <b>{data?.data.prijem_robe.ime} {data?.data.prijem_robe.prezime}</b></Item>
                            <Item>Status prijema robe: <b>{data?.data.prijem_robe.status}</b></Item>
                            <Item>Datum i vreme početka: <b>{data?.data.prijem_robe.vreme_prijema}</b></Item>
                            <Item>Datum i vreme završetka: <b>{data?.data.prijem_robe.vreme_zavrsetka}</b></Item>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 18, textAlign: "center"}}>Analitika</Typography>
                    <Divider sx={{m: 2}}/>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja analize podataka!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Item>Ispravna roba: <b>{data?.data.rezultati.ispravna_roba}</b></Item>
                            <Item>Rizična roba: <b>{data?.data.rezultati.rizicna_roba}</b></Item>
                            <Item>Roba za povrat dobavljaču: <b>{data?.data.rezultati.neispravna_roba}</b></Item>
                            <Item>Sva roba: <b>{data?.data.rezultati.sva_roba}</b></Item>
                            <Divider/>
                            <Item>Ukupno vreme trajanja prijema: <b>{data?.data.rezultati.razlika_human}</b></Item>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 18, textAlign: "center"}}>Tabelarni prikaz robe</Typography>
                    <Divider sx={{m: 2}}/>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podataka o primljenim proizvodima!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data.primljena_roba}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PrijemRobePage;