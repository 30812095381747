import React from 'react';
import {Navigate, Outlet} from "react-router";
import LakiranjeLayout from "../layouts/LakiranjeLayout";

const LakiranjeProtectedRoute = () => {
    let role_id = localStorage.getItem("role_id");

    if (role_id != 3) {
        return <Navigate to="/"/>;
    }

    return (
        <LakiranjeLayout>
            <Outlet/>
        </LakiranjeLayout>
    );
};

export default LakiranjeProtectedRoute;