import React from "react";
import { Alert, Snackbar } from "@mui/material";

const Notification = ({ type, message, open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      key={"Notification"}
      sx={{ width: "50%", textAlign: "center" }}
    >
      {type == 1 ? (
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%", justifyContent: "center" }}
        >
          {message}
        </Alert>
      ) : (
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%", justifyContent: "center" }}
        >
          {message}
        </Alert>
      )}
    </Snackbar>
  );
};

export default Notification;
