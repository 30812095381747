import React, {useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    LinearProgress
} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import {createSastavnica, gotoviProizvodiKojiImajuVezu, reproMaterijali, sastavnice} from "../../../apis/sastavnicaApi";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import {Item} from "../../../components/additional/Item";
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const FormDialog = ({open, onClose, onSubmit, setOpenN, setMessage, setType}) => {
    const [gotovProizvod, setGotovProizvod] = useState(null);
    const [repromaterijal, setRepromaterijal] = useState(null);
    const [kolicina, setKolicina] = useState(1);

    const [sastojci, setSastojci] = useState([]);

    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const [openAutocomplete1, setOpenAutocomplete1] = useState(false);
    const handleOpenAutocomplete1 = () => setOpenAutocomplete1(true);
    const handleCloseAutocomplete1 = () => setOpenAutocomplete1(false);

    const {data: gotovi_proizvodi, isLoading: loadingGotoviProizvodi, isError: errorGotoviProizvodi} = useQuery({
        queryKey: ['gotoviProizvodiKojiImajuVezu'],
        queryFn: gotoviProizvodiKojiImajuVezu,
        enabled: openAutocomplete
    });

    const {data: repromaterijali, isLoading: loadingRepromaterijali, isError: errorRepromaterijali} = useQuery({
        queryKey: ['reproMaterijali'],
        queryFn: reproMaterijali,
        enabled: openAutocomplete1
    })

    const handleSubmit = () => {
        const formData = new FormData();

        if (gotovProizvod == null) {
            setType(2);
            setMessage("Morate izabrati gotov proizvod!");
            setOpenN(true);
            return;
        }

        if (sastojci.length == 0) {
            setType(2);
            setMessage("Morate dodati repromaterijale u listu!");
            setOpenN(true);
            return;
        }

        formData.append('sifra_gotovog_proizvoda', gotovProizvod?.sifra);
        formData.append('sastojci', JSON.stringify(sastojci));
        formData.append('sifra_sirovog_proizvoda', gotovProizvod?.sifra_sirov)

        setGotovProizvod(null);
        setKolicina(1);
        setSastojci([]);

        onSubmit(formData);
        onClose();
    };

    const handleAddProizvod = (event) => {
        event.preventDefault();

        const newSastojak = {
            'sifra': repromaterijal?.sifra,
            'naziv': repromaterijal?.naziv,
            'kolicina': kolicina,
            'jedinica': repromaterijal?.jedinica,
            'opis': repromaterijal?.opis,
            'tip': 1
        }

        setSastojci((prevSastojci) => {
            if (prevSastojci.some((sastojak) => sastojak.sifra == newSastojak.sifra)) {
                setType(2);
                setMessage("Repromaterijal je već u spisku za sastavnicu!");
                setOpenN(true);
                return prevSastojci;
            }
            return [...prevSastojci, newSastojak];
        })
    }

    const handleRemoveProizvod = (sifra) => {
        setSastojci((prevSastojci) =>
            prevSastojci.filter((sastojak) => sastojak.sifra != sifra)
        );
    }

    return (
        <Dialog fullWidth={true} maxWidth={"xl"} open={open} onClose={onClose}>
            <DialogTitle>Unos nove sastavnice</DialogTitle>
            <DialogContent>
                <Autocomplete
                    fullWidth
                    open={openAutocomplete}
                    onOpen={handleOpenAutocomplete}
                    onClose={handleCloseAutocomplete}
                    isOptionEqualToValue={(option, value) =>
                        option.naziv == value.naziv || option.sifra == value.sifra
                    }
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setRepromaterijal(null);
                            setKolicina(1);
                            setSastojci([]);
                            setGotovProizvod(newValue);
                        } else {
                            console.log("Nema ove robe u sistemu!");
                        }
                    }}
                    value={gotovProizvod}
                    getOptionLabel={(option) => {
                        return "Naziv: " + option.naziv + " | Šifra: " + option.sifra;
                    }}
                    options={loadingGotoviProizvodi ? [] : gotovi_proizvodi?.data}
                    loading={loadingGotoviProizvodi}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            margin="normal"
                            label="Izaberite gotov proizvod"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loadingGotoviProizvodi ? (
                                                <CircularProgress color="inherit" size={20}/>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                },
                            }}
                        />
                    )}
                />
                <Divider/>
                {gotovProizvod == null ? (
                    <Alert sx={{mt: 1}} severity="info">
                        Molimo vas izaberite prvo gotov proizvod!
                    </Alert>
                ) : (
                    <Box sx={{mt: 2, textAlign: 'center'}}>
                        <Typography>
                            Sirov proizvod: <b>{gotovProizvod?.sifra_sirov}</b> | <b>{gotovProizvod?.naziv_sirov}</b>
                        </Typography>
                        <Divider/>
                        <Grid container spacing={2} sx={{mt: 2, mb: 2}}>
                            <Grid size={{xl: 8, lg: 8, sm: 8, xs: 12}}>
                                <Autocomplete
                                    fullWidth
                                    open={openAutocomplete1}
                                    onOpen={handleOpenAutocomplete1}
                                    onClose={handleCloseAutocomplete1}
                                    isOptionEqualToValue={(option, value) =>
                                        option.naziv == value.naziv || option.sifra == value.sifra || option.opis == value.opis
                                    }
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setRepromaterijal(newValue);
                                        } else {
                                            console.log("Nema ove robe u sistemu!");
                                        }
                                    }}
                                    value={repromaterijal}
                                    getOptionLabel={(option) => {
                                        return "Naziv: " + option.naziv + " | Šifra: " + option.sifra + " | Opis: " + option.opis;
                                    }}
                                    options={loadingRepromaterijali ? [] : repromaterijali?.data}
                                    loading={loadingRepromaterijali}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            margin="normal"
                                            label="Izaberite repromaterijal"
                                            slotProps={{
                                                input: {
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loadingRepromaterijali ? (
                                                                <CircularProgress color="inherit" size={20}/>
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={{xl: 4, lg: 4, sm: 4, xs: 12}}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="kolicina"
                                    name="kolicina"
                                    label="Unesite količinu repromaterijala"
                                    helperText="Ceo broj ili dve decimale"
                                    variant="outlined"
                                    value={kolicina}
                                    onChange={(event) => setKolicina(event.target.value)}
                                    slotProps={{
                                        input: {
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {repromaterijal?.jedinica}
                                                </InputAdornment>,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="button"
                                    endIcon={<AddCircleTwoToneIcon/>}
                                    disabled={gotovProizvod == null}
                                    onClick={handleAddProizvod}
                                >
                                    Dodaj u sastavnicu
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Stack spacing={2} sx={{mt: 2}}>
                            {sastojci.length == 0 ? (
                                <Alert severity="info">
                                    Dodajte repromaterijal u sastavnicu!
                                </Alert>
                            ) : sastojci.map((item) => (
                                <Item>
                                    <b>{item.sifra} | {item.naziv} | {item.opis} | {item.kolicina} {item.jedinica}
                                    </b>
                                    <IconButton
                                        sx={{ml: 5}}
                                        color="error"
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => handleRemoveProizvod(item.sifra)}
                                    >
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </Item>
                            ))}
                        </Stack>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SastavnicePage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["sastavnice"],
        queryFn: sastavnice,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()} / {(date.getMonth() + 1)} / {date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../sastavnica/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = (data) => {
        data.append('id_kreator', localStorage.getItem("user_id"));
        mutation.mutateAsync(data).then(() => {
        }).catch((e) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createSastavnica(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['sastavnice']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <ArticleTwoToneIcon size={22} sx={{mr: 2}}/>
                        Sve sastavnice
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Nova sastavnica</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja sastavnica!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleSubmit}
                setOpenN={setOpenN}
                setType={setType}
                setMessage={setMessage}
            />
        </Grid>
    );
};

export default SastavnicePage;