import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    Alert,
    Autocomplete,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import {
    proveriStanjeRepromaterijala,
    stanjeSkladistaSirovihProizvodaKojiImajuVezu
} from "../../../apis/stanjeSkladistaApi";
import TextField from "@mui/material/TextField";
import {sastavnicaPrekoSifreGotovogProizvoda} from "../../../apis/sastavnicaApi";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";
import Stack from "@mui/material/Stack";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import IconButton from "@mui/material/IconButton";
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import {adminRadniNalozi, createAdminRadniNalog} from "../../../apis/adminRadniNalogApi";

const FormDialog = ({
                        open,
                        onClose,
                        onSubmit,
                        setOpenN,
                        setType,
                        setMessage,
                        proizvod,
                        setProizvod,
                        arr,
                        setArr,
                        repromaterijali,
                        setRepromaterijali
                    }) => {
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('repromaterijali', JSON.stringify(getSummedMaterials()));
        setRepromaterijali(getSummedMaterials());

        mutation.mutateAsync(formData).catch((err) => {
        });

        onClose();
    };

    const mutation = useMutation({
        mutationFn: (formData) => proveriStanjeRepromaterijala(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            onSubmit();
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const {data, isLoading, isError} = useQuery({
        queryKey: ['stanjeSkladistaSirovihProizvodaKojiImajuVezu'],
        queryFn: stanjeSkladistaSirovihProizvodaKojiImajuVezu,
        enabled: openAutocomplete
    });

    const {data: sastavnica, isLoading: loadingSastavnica, isError: errorSastavnica} = useQuery({
        queryKey: ['sastavnica', proizvod],
        queryFn: () => sastavnicaPrekoSifreGotovogProizvoda(proizvod?.sifra_gotov),
        enabled: proizvod != null
    });

    const dodajURadniNalog = () => {
        if (proizvod == null) return;

        let newElement = {
            'serijski_broj': proizvod?.serijski_broj,
            'sifra_gotov': proizvod?.sifra_gotov,
            'sifra_sirov': proizvod?.sifra_sirov,
            'repromaterijali': JSON.stringify(sastavnica?.data),
            'naziv_sirov': proizvod?.naziv_sirov,
        };

        setArr((prevArr) => {
            // Check if serijski_broj already exists
            const exists = prevArr.some(item => item.serijski_broj === newElement.serijski_broj);

            if (!exists) {
                return [...prevArr, newElement]; // Add if not exists
            }

            return prevArr; // Return previous array if exists
        });

        setProizvod(null);
    }

    const izbrisiIzRadnogNaloga = (serijski_broj) => {
        setArr((prevArr) => prevArr.filter(item => item.serijski_broj !== serijski_broj));
    }

    const getSummedMaterials = () => {
        const materialMap = new Map();

        arr.forEach((item) => {
            const repromaterijali = JSON.parse(item.repromaterijali);

            repromaterijali.forEach((material) => {
                if (materialMap.has(material.sifra)) {
                    materialMap.get(material.sifra).kolicina += material.kolicina;
                } else {
                    materialMap.set(material.sifra, {...material});
                }
            });
        });

        return Array.from(materialMap.values());
    };

    return (
        <Dialog maxWidth={false} fullWidth open={open} onClose={onClose}>
            <DialogTitle>Unos novog radnog naloga</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid size={{xl: 6, lg: 6, md: 6, sm: 6, xs: 12}}>
                        <Autocomplete
                            fullWidth
                            open={openAutocomplete}
                            onOpen={handleOpenAutocomplete}
                            onClose={handleCloseAutocomplete}
                            isOptionEqualToValue={(option, value) =>
                                option.naziv_sirov == value.naziv_sirov || option.sifra == value.sifra || option.serijski_broj == value.serijski_broj
                            }
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setProizvod(newValue);
                                } else {
                                    console.log("Nema ove robe u sistemu!");
                                }
                            }}
                            value={proizvod}
                            getOptionLabel={(option) => {
                                return "Serijski broj: " + option.serijski_broj + " | Naziv: " + option.naziv_sirov + " | Šifra: " + option.sifra_sirov;
                            }}
                            options={isLoading ? [] : data?.data.filter(
                                item => !arr.some(el => el.serijski_broj === item.serijski_broj)
                            )}
                            loading={isLoading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Izaberite sirov proizvod"
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isLoading ? (
                                                        <CircularProgress color="inherit" size={20}/>
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        },
                                    }}
                                />
                            )}
                        />
                        <Button
                            sx={{mt: 2}}
                            fullWidth
                            variant="contained"
                            endIcon={<AddBoxTwoToneIcon/>}
                            disabled={proizvod == null}
                            onClick={dodajURadniNalog}
                        >
                            <b>Dodaj ovaj serijski broj u radni nalog</b>
                        </Button>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 6, sm: 6, xs: 12}}>
                        {proizvod == null ? (
                            <Alert severity="info">
                                Prvo izaberite sirov proizvod sa skladišta koji hoćete da radite u ovom radnom nalogu!
                            </Alert>
                        ) : (
                            loadingSastavnica ? (
                                <LinearProgress size={24} color="inherit"/>
                            ) : (
                                <Stack spacing={2}>
                                    <Divider>
                                        <Chip label="Potrebni repromaterijali" size="small"/>
                                    </Divider>
                                    {sastavnica?.data.map((item) => {
                                        return (
                                            <>
                                                <Item>{item.sifra} | {item.naziv} | {item.tip} | {item.kolicina} {item.jedinica} | {item.opis}</Item>
                                                <Divider/>
                                            </>
                                        );
                                    })}
                                </Stack>
                            )
                        )}
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 6, sm: 6, xs: 12}}>
                        <Paper sx={{p: 0.5, justifyContent: "center", alignItems: "center"}}>
                            {arr.length == 0 ? (
                                <Alert severity="warning">
                                    Morate dodati neki od proizvoda sa serijskim broj u radni nalog!
                                </Alert>
                            ) : (
                                <Stack spacing={2}>
                                    <Divider>
                                        <Chip label="Proizvodi u radnom nalogu
                                        " size="small"/>
                                    </Divider>
                                    {arr.map((item) => {
                                        return (
                                            <>
                                                <Item>
                                                    {item.serijski_broj} | {item.sifra_sirov} | {item.naziv_sirov} | 1
                                                    STK
                                                    <IconButton sx={{ml: 2}} color="primary"
                                                                aria-label="info o sastavnici">
                                                        <InfoTwoToneIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => izbrisiIzRadnogNaloga(item.serijski_broj)}
                                                        sx={{ml: 2}} color="error"
                                                        aria-label="delete iz rn">
                                                        <DeleteSweepTwoToneIcon/>
                                                    </IconButton>
                                                </Item>
                                                <Divider/>
                                            </>
                                        );
                                    })}
                                </Stack>
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 6, sm: 6, xs: 12}}>
                        <Paper sx={{p: 0.5, justifyContent: "center", alignItems: "center"}}>
                            {arr.length == 0 ? (
                                <Alert severity="warning">
                                    Morate dodati neki od proizvoda sa serijskim broj u radni nalog!
                                </Alert>
                            ) : (
                                <Stack spacing={2}>
                                    <Divider>
                                        <Chip label="Ukupno potrebno repromaterijala - jednistveno" size="small"/>
                                    </Divider>
                                    {getSummedMaterials().map((item) => {
                                        return (
                                            <>
                                                <Item>{item.sifra} | {item.naziv} | {item.opis} | {item.kolicina} {item.jedinica}</Item>
                                                <Divider/>
                                            </>
                                        );
                                    })}
                                </Stack>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const RadniNaloziPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [proizvod, setProizvod] = useState(null);
    const [arr, setArr] = useState([]);
    const [repromaterijali, setRepromaterijali] = useState([]);

    const {data, isLoading, isError} = useQuery({
        queryKey: ["adminRadniNalozi"],
        queryFn: adminRadniNalozi,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "txt_status",
            headerName: "Status",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()} / {(date.getMonth() + 1)} / {date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../radni-nalog/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = () => {
        if (arr.length == 0) {
            setType(2);
            setMessage("Morate dodati bar jedan proizvod u radni nalog!");
            setOpenN(true);
            return;
        }

        if (repromaterijali.length == 0) {
            setType(2);
            setMessage("Morate dodati bar jedan repromaterijal u radni nalog!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_kreator', localStorage.getItem('user_id'));
        formData.append('proizvodi', JSON.stringify(arr));
        formData.append('repromaterijali', JSON.stringify(repromaterijali));

        mutation.mutateAsync(formData).catch((err) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createAdminRadniNalog(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['adminRadniNalozi']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Svi radni nalozi
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Novi radni nalog</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja radnih naloga!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleSubmit}
                setOpenN={setOpenN}
                setType={setType}
                setMessage={setMessage}
                proizvod={proizvod}
                setProizvod={setProizvod}
                arr={arr}
                setArr={setArr}
                repromaterijali={repromaterijali}
                setRepromaterijali={setRepromaterijali}
            />
        </Grid>
    );
};

export default RadniNaloziPage;