import React, {useState} from 'react';
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, Box, CircularProgress, InputAdornment} from "@mui/material";
import TextField from "@mui/material/TextField";
import QrCodeTwoToneIcon from '@mui/icons-material/QrCodeTwoTone';
import Notification from "../../components/Notification";
import {useMutation} from "@tanstack/react-query";
import {pretragaSerijskogBroja} from "../../apis/pretragaApi";
import Button from "@mui/material/Button";
import PageviewTwoToneIcon from '@mui/icons-material/PageviewTwoTone';
import {Item} from "../../components/additional/Item";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const ProveraSerijskogBrojaPage = () => {
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [informacije, setInformacije] = useState(null);
    const [stanje, setStanje] = useState(null);

    const mutation = useMutation({
        mutationFn: (formData) => pretragaSerijskogBroja(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpen(true);
            setLoading(false);

            setInformacije(response.data.rezultat);
            setStanje(response.data.stanje);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpen(true);
        }
    });

    const handlePretragaSerijskogBroja = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        setLoading(true);

        if (formData.get('serijski_broj') == "" || formData.get('serijski_broj') == null) {
            setType(2);
            setMessage("Molimo vas unesite ispravan serijski broj!");
            setOpen(true);
            setLoading(false);
            return;
        }

        mutation.mutateAsync(formData).catch((error) => {
        });
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={open}
                setOpen={setOpen}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Proverite informacije o serijskom broju
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Box component="form" noValidate onSubmit={handlePretragaSerijskogBroja}>
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            id="serijski_broj"
                            name="serijski_broj"
                            label="Skenirajte serijski broj"
                            placeholder="Skenirajte serijski broj"
                            variant="outlined"
                            helperText="Ako niste u mogućnosti da skenirate serijski broj, unesite ga ručno!"
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">
                                        <QrCodeTwoToneIcon/>
                                    </InputAdornment>
                                }
                            }}
                        />
                        <Button
                            endIcon={<PageviewTwoToneIcon/>}
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={20}/> : "Pretraži"}
                        </Button>
                    </Box>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        {informacije == null && stanje == null ? (
                            <Alert severity="warning">
                                Molimo vas unesite serijski broj koji postoji u sistemu!
                            </Alert>
                        ) : (
                            <Stack spacing={2}>
                                <Item>Naziv: <b>{informacije?.naziv}</b></Item>
                                <Divider/>
                                <Item>Šifra proizvoda: <b>{informacije?.sifra}</b></Item>
                                <Divider/>
                                <Item>Količina: <b>{informacije?.kolicina} {informacije?.jedinica}</b></Item>
                                <Divider/>
                                <Item>Dobavljač: <b>{informacije?.naziv_dobavljaca}</b></Item>
                                <Divider/>
                                <Item>Datum prijema: <b>{informacije?.vreme}</b></Item>
                                <Divider/>
                                <Item>Naziv skladišta: <b>{stanje?.naziv_skladista}</b></Item>
                                <Divider/>
                                <Item>Naziv lokacije: <b>{stanje?.naziv}</b></Item>
                                <Divider/>
                                <Item>Barkod lokacije: <b>{stanje?.barkod}</b></Item>
                                <Divider/>
                                <Item>Koordinate: <b>{stanje?.koordinate}</b></Item>
                                <Divider/>
                                <Item>Status: <b>{stanje?.info}</b></Item>
                                <Divider/>
                            </Stack>
                        )}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ProveraSerijskogBrojaPage;