import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {podesavanja, updatePodesavanja} from "../apis/podesavanjaApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import {Alert, Box, LinearProgress} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import Notification from "../components/Notification";

const PodesavanjaPage = () => {
    const queryClient = useQueryClient();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const {data, isLoading, isError} = useQuery({
        queryKey: ['podesavanja'],
        queryFn: podesavanja,
    });

    const mutation = useMutation({
        mutationFn: (formData) => updatePodesavanja(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['podesavanja']);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const [formData, setFormData] = useState({
        gln: "",
        email: "",
        adresa: "",
        telefon: "",
    });

    // Update state when data is fetched
    useEffect(() => {
        if (data?.data) {
            setFormData({
                gln: data.data.gln || "",
                email: data.data.email || "",
                adresa: data.data.adresa || "",
                telefon: data.data.telefon || "",
            });
        }
    }, [data]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSave = () => {
        mutation.mutateAsync(formData).catch((error) => {
        });
    };

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Podaci o Tren-u
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja informacija o Tren-u!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress size={24} color="inherit"/>
                    ) : (
                        <Box>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="gln"
                                label="GLN"
                                variant="outlined"
                                value={formData.gln}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="email"
                                label="Email"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="adresa"
                                label="Adresa"
                                variant="outlined"
                                value={formData.adresa}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="telefon"
                                label="Telefon"
                                variant="outlined"
                                value={formData.telefon}
                                onChange={handleChange}
                            />

                            <Button onClick={handleSave} fullWidth variant="contained" endIcon={<SaveTwoToneIcon/>}>
                                Sačuvaj izmene
                            </Button>
                        </Box>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PodesavanjaPage;