import {Link, Typography} from "@mui/material";
import React from "react";

const Copyright = (props) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Sva prava zadržana. © "}
            <Link color="inherit" target="_blank" href="http://kala.rs/">
                Kala Consulting
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
            <br/>
            <Link color="inherit" target="_blank" href="https://tren.ba/">
                Tren doo
            </Link>
            {" edition"}
        </Typography>
    );
};

export default Copyright;
