import axios from "axios";
import {url} from "./urls";

export const otpremnice = async () => {
    return axios.get(url + '/otpremnice', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const createOtpremnica = async (formData) => {
    return axios.post(url + '/create-otpremnica', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const dodajteKutije = async (formData) => {
    return axios.post(url + '/dodajte-kutije', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const proveriKutiju = async (formData) => {
    return axios.post(url + '/proveri-kutiju', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const otpremnica = async (id) => {
    return axios.get(url + '/otpremnica/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zavrsiOtpremnicu = async (formData) => {
    return axios.post(url + '/zavrsi-otpremnicu', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
