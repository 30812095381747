import React from 'react';
import {useNavigate, useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {skladiste} from "../../../apis/skladistaApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";
import Nalepnica from "../../../components/stampanje/Nalepnica";
import Button from "@mui/material/Button";
import LocalPrintshopTwoToneIcon from "@mui/icons-material/LocalPrintshopTwoTone";
import IconButton from "@mui/material/IconButton";
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';

const SkladistePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['skladiste', id],
        queryFn: () => skladiste(id)
    });

    const printDiv = (divId) => {
        const container = document.getElementById(divId);
        const width = "100%";
        const height = "100%";
        const printWindow = window.open(
            "",
            "PrintMap",
            "width=" + width + ",height=" + height,
        );
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o skladištu
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o mašini!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.skladiste.id}</b></Item>
                            <Divider/>
                            <Item>Šifra: <b>{data?.data.skladiste.barkod}</b></Item>
                            <Divider/>
                            <Item>Naziv: <b>{data?.data.skladiste.naziv}</b></Item>
                            <Divider/>
                            <Item>Tip: <b>{data?.data.skladiste.tip}</b></Item>
                            <Divider/>
                            <div style={{
                                justifyContent: "center",
                                display: "flex",
                            }} id="stampanje">
                                <Nalepnica serijski_broj={data?.data.skladiste.barkod}
                                           naziv={data?.data.skladiste.naziv}
                                           tip={data?.data.skladiste.tip}/>
                            </div>
                            <Button
                                sx={{mt: 2, mb: 2}}
                                endIcon={<LocalPrintshopTwoToneIcon/>}
                                variant="contained"
                                onClick={() => printDiv("stampanje")}
                            >
                                Štampajte nalepnicu
                            </Button>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.skladiste.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o lokacijama na skladištu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            {data?.data.lokacije.length == 0 ? (
                                <Alert severity="warning">
                                    Nema lokacija na ovom skladištu!
                                </Alert>
                            ) : (
                                data?.data.lokacije.map((item, index) => (
                                    <>
                                        <Item>
                                            <b>{item.id} | {item.naziv} | {item.barkod} | {item.x}; {item.y}; {item.z} | {item.fiksna_lokacija == 0 ? "Nije fiksna lokacija." : "Jeste fiksna lokacija."} {item.fiksna_lokacija == 0 ? "" : (" | " + item.sifra_proizvoda)}</b>
                                            <IconButton onClick={() => navigate('../lokacija/' + item.id)}
                                                        color="primary" aria-label="delete" size="small">
                                                <HelpCenterTwoToneIcon fontSize="small"/>
                                            </IconButton>
                                        </Item>
                                        <Divider/>
                                    </>
                                ))
                            )}
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SkladistePage;