import axios from "axios";
import {url} from "./urls";

export const sifrarnici = async () => {
    return axios.get(url + `/sifrarnici`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const sifrarnik = async (id) => {
    return axios.get(url + `/sifrarnik/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const create = async (formData) => {
    return axios.post(url + `/create-sifrarnik`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const vezeSirovGotov = async () => {
    return axios.get(url + `/veze-sirov-gotov`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const siroviProizvodi = async () => {
    return axios.get(url + `/sirovi-proizvodi`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const gotoviProizvodi = async () => {
    return axios.get(url + `/gotovi-proizvodi`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createVeza = async (formData) => {
    return axios.post(url + `/create-veza`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const deleteVeza = async (formData) => {
    return axios.post(url + `/delete-veza`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}