import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Grid from "@mui/material/Grid2";
import Notification from "../../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {
    Alert,
    Autocomplete,
    Box,
    ButtonGroup,
    CircularProgress,
    InputAdornment,
    LinearProgress,
    MenuItem
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../../language/tableLang";
import TextField from "@mui/material/TextField";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import Button from "@mui/material/Button";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import SendToMobileTwoToneIcon from "@mui/icons-material/SendToMobileTwoTone";
import {
    finishGiterKontrola,
    formiranjeVezeGiterKontrola,
    iskontrolisiProizvodGiter,
    lansirajProizvodGiterKontrola,
    zaGiterKontrolu
} from "../../../../apis/giterKontrolaApi";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import TouchAppTwoToneIcon from "@mui/icons-material/TouchAppTwoTone";
import {radniciNaPoliranju} from "../../../../apis/userApi";

const GiterKontrolaProcesPage = () => {
    const {id} = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);
    const [postoji, setPostoji] = useState(false);

    const [serijskiBroj, setSerijskiBroj] = useState("");
    const [sifraLokacije, setSifraLokacije] = useState("");
    const [stanjeProizvoda, setStanjeProizvoda] = useState(1);

    const [radnik, setRadnik] = useState(null);
    const [sifraGajbica, setSifraGajbica] = useState("");
    const [vezaId, setVezaId] = useState(null);

    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const columns_za_kontrolu = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stanje",
            headerName: "Stanje",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 70,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_iskontrolisano = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stanje",
            headerName: "Stanje",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 70,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const columns_za_rasporedjivanje = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_rasporedjeno = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_veze = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 30,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "id_veza",
            headerName: "Pristup",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<TouchAppTwoToneIcon/>}
                        onClick={() => setVezaId(params.row.id)}
                        disabled={vezaId == params.row.id}
                    >
                        Izaberite
                    </Button>
                );
            },
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv tovarnog medijuma",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const {data, isLoading, isError} = useQuery({
        queryKey: ['zaGiterKontrolu', id],
        queryFn: () => zaGiterKontrolu(id)
    });

    const {
        data: radnici,
        isLoading: loadingRadnici,
        error: errorRadnici
    } = useQuery({
        queryKey: ['radniciNaPoliranju'],
        queryFn: radniciNaPoliranju,
        enabled: openAutocomplete
    });

    const mutationIskontrolisi = useMutation({
        mutationFn: (formData) => iskontrolisiProizvodGiter(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSerijskiBroj("");
            setSifraLokacije("");

            queryClient.invalidateQueries(['zaGiterKontrolu', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationZavrsi = useMutation({
        mutationFn: (formData) => finishGiterKontrola(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            if (response.data.status == 2) {
                queryClient.invalidateQueries(['zaGiterKontrolu', id]).then((res) => {
                });
            } else {
                setTimeout(() => {
                    navigate('../giter-kontrole');
                }, 2000);
            }
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationFormirajuNovuVezu = useMutation({
        mutationFn: (formData) => formiranjeVezeGiterKontrola(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setVezaId(response.data.id);

            queryClient.invalidateQueries(['zaGiterKontrolu', id]).then((res) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationDodajProizvodUGajbicu = useMutation({
        mutationFn: (formData) => lansirajProizvodGiterKontrola(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['zaGiterKontrolu', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const handleDodajNovuVezu = (event) => {
        event.preventDefault();

        if (radnik == null) {
            setType(2);
            setMessage('Morate izabrati radnika kojeg želite da dodate u lansiranje!');
            setOpenN(true);
            return;
        }

        if (sifraGajbica == "") {
            setType(2);
            setMessage('Morate uneti šifru tovarnog medijuma!');
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_giter_kontrola', id);
        formData.append('id_radnik', radnik?.id);
        formData.append('sifra_gajbice', sifraGajbica)

        mutationFormirajuNovuVezu.mutateAsync(formData).catch((err) => {
        });
    }

    const handleLansirajProizvod = (event) => {
        event.preventDefault();
        if (vezaId == null) {
            setType(2);
            setMessage('Morate izabrati radnika kojem lansirate proizvod!');
            setOpenN(true);
            return;
        }

        if (serijskiBroj == "") {
            setType(2);
            setMessage('Morate izabrati serijski broj koji je odgovarajuć!');
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_poliranje', vezaId);
        formData.append('serijski_broj', serijskiBroj);

        mutationDodajProizvodUGajbicu.mutateAsync(formData).catch((err) => {
        });
    }

    const handleIskontrolisi = (event) => {
        event.preventDefault();

        if (serijskiBroj == "") {
            setType(2);
            setMessage("Unesite serijski broj koji je ispravan!");
            setOpenN(true);
            return;
        }

        if (sifraLokacije == "" && (stanjeProizvoda == 3)) {
            setType(2);
            setMessage("Unesite ispravnu šifru lokacije!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('id_giter_kontrole', id);
        formData.append('serijski_broj', serijskiBroj);
        formData.append('sifra_lokacija', sifraLokacije);
        formData.append('stanje', stanjeProizvoda);

        mutationIskontrolisi.mutateAsync(formData).catch((error) => {
        });
    }

    const handleZavrsi = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id', id);

        mutationZavrsi.mutateAsync(formData).catch((err) => {
        });
    }

    const checkSerijskiBrojGiter = (value) => {
        setPostoji(data?.data?.za_giter_kontrolu?.some(item => item.serijski_broj === value) || false);
    }

    const checkSerijskiBrojRasporedjivanje = (value) => {
        setPostoji(data?.data?.za_rasporedjivanje?.some(item => item.serijski_broj === value) || false);
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            {isError ? (
                <Grid size={{xs: 12}}>
                    <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su za giter kontrolu!
                        </Alert>
                    </Paper>
                </Grid>
            ) : isLoading ? (
                <Grid size={{xs: 12}}>
                    <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                        <LinearProgress size={24} color="inherit"/>
                    </Paper>
                </Grid>
            ) : (
                data?.data.status == 1 ? (
                    <>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                    <PolicyIcon size={22} sx={{mr: 2}}/>
                                    Proizvodi za kontrolu
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                    <PolicyIcon size={22} sx={{mr: 2}}/>
                                    Iskontrolisani proizvodi
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                                {isError ? (
                                    <Alert severity="error">
                                        Greška prilikom učitavanja proizvoda koji su za kontrolu šlajfanja!
                                    </Alert>
                                ) : isLoading ? (
                                    <LinearProgress/>
                                ) : (
                                    <DataGrid
                                        localeText={tableLang}
                                        columns={columns_za_kontrolu}
                                        rows={isLoading ? [] : data?.data.za_giter_kontrolu}
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                                {isError ? (
                                    <Alert severity="error">
                                        Greška prilikom učitavanja proizvoda koji su iskontrolisani!
                                    </Alert>
                                ) : isLoading ? (
                                    <LinearProgress/>
                                ) : (
                                    <DataGrid
                                        localeText={tableLang}
                                        columns={columns_iskontrolisano}
                                        rows={isLoading ? [] : data?.data.giter_kontrolisani}
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                                    data?.data.za_giter_kontrolu.length == 0 ? (
                                        <Alert severity="info">
                                            Svi proizvodi su iskontrolisani!
                                        </Alert>
                                    ) : (
                                        <Box>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                required
                                                id="serijski_broj"
                                                name="serijski_broj"
                                                label="Unesite serijski broj"
                                                placeholder="Unesite serijski broj"
                                                helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                                variant="outlined"
                                                value={serijskiBroj}
                                                onChange={(event) => {
                                                    setSerijskiBroj(event.target.value);
                                                    checkSerijskiBrojGiter(event.target.value);
                                                }}
                                                slotProps={{
                                                    input: {
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <QrCodeScannerTwoToneIcon/>
                                                            </InputAdornment>,
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                select
                                                label="Stanje proizvoda"
                                                name="stanje_proizvoda"
                                                value={stanjeProizvoda}
                                                onChange={(event) => setStanjeProizvoda(event.target.value)}
                                                required
                                            >
                                                <MenuItem value={1}>
                                                    Dobar proizvod
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Za doradu
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    Za škart
                                                </MenuItem>
                                            </TextField>

                                            <TextField
                                                disabled={stanjeProizvoda == 1 || stanjeProizvoda == 2}
                                                margin="normal"
                                                fullWidth
                                                required={stanjeProizvoda != 1 && stanjeProizvoda != 2}
                                                id="sifra_lokacije"
                                                name="sifra_lokacije"
                                                label="Unesite šifru odgovarajuće lokacije"
                                                placeholder="Unesite šifru odgovarajuće lokacije"
                                                helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                                variant="outlined"
                                                value={sifraLokacije}
                                                onChange={(event) => setSifraLokacije(event.target.value)}
                                                slotProps={{
                                                    input: {
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <QrCodeScannerTwoToneIcon/>
                                                            </InputAdornment>,
                                                    },
                                                }}
                                            />

                                            <Button
                                                variant="contained"
                                                fullWidth
                                                type="button"
                                                endIcon={<ArrowForwardTwoToneIcon/>}
                                                onClick={handleIskontrolisi}
                                                disabled={!postoji}
                                            >
                                                Iskontrolišite proizvod
                                            </Button>
                                        </Box>
                                    )
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        type="button"
                                        endIcon={<SendToMobileTwoToneIcon/>}
                                        onClick={handleZavrsi}
                                        disabled={data?.data.za_giter_kontrolu.length != 0}
                                    >
                                        Završite kontrolu i predjite na rasporedjivanje
                                    </Button>
                                )}
                            </Paper>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                    <PolicyIcon size={22} sx={{mr: 2}}/>
                                    Potrebno lansirati
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                    <PolicyIcon size={22} sx={{mr: 2}}/>
                                    Lansirano
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                                {isError ? (
                                    <Alert severity="error">
                                        Greška prilikom učitavanja proizvoda koje treba lansirati!
                                    </Alert>
                                ) : isLoading ? (
                                    <LinearProgress/>
                                ) : (
                                    <DataGrid
                                        localeText={tableLang}
                                        columns={columns_za_rasporedjivanje}
                                        rows={isLoading ? [] : data?.data.za_rasporedjivanje}
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                                {isError ? (
                                    <Alert severity="error">
                                        Greška prilikom učitavanja proizvoda koji su lansirani!
                                    </Alert>
                                ) : isLoading ? (
                                    <LinearProgress/>
                                ) : (
                                    <DataGrid
                                        localeText={tableLang}
                                        columns={columns_rasporedjeno}
                                        rows={isLoading ? [] : data?.data.rasporedjeno}
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xl: 6, lg: 6, md: 6, sm: 12, xs: 12}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                {isLoading ? (
                                    <LinearProgress size={24} color="inherit"/>
                                ) : data?.data.za_rasporedjivanje.length == 0 ? (
                                    <Alert severity="info">
                                        Svi proizvodi iz ovog trebovanja su rasporedjeni!
                                    </Alert>
                                ) : vezaId == null ? (
                                    <Box>
                                        <Autocomplete
                                            fullWidth
                                            open={openAutocomplete}
                                            onOpen={handleOpenAutocomplete}
                                            onClose={handleCloseAutocomplete}
                                            isOptionEqualToValue={(option, value) =>
                                                option.ime == value.ime || option.prezime == value.prezime
                                            }
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setRadnik(newValue);
                                                }
                                            }}
                                            value={radnik}
                                            getOptionLabel={(option) => {
                                                return "Ime: " + option.ime + " | Prezime: " + option.prezime + " | Pozicija: " + option.naziv;
                                            }}
                                            options={loadingRadnici ? [] : radnici?.data}
                                            loading={loadingRadnici}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    margin="normal"
                                                    label="Izaberite radnika"
                                                    slotProps={{
                                                        input: {
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loadingRadnici ? (
                                                                        <CircularProgress color="inherit" size={20}/>
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            required
                                            id="tovarni_medijum"
                                            name="tovarni_medijum"
                                            label="Unesite šifru tovarnog medijuma"
                                            placeholder="Unesite šifru tovarnog medijuma"
                                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                            variant="outlined"
                                            value={sifraGajbica}
                                            onChange={(event) => {
                                                setSifraGajbica(event.target.value);
                                            }}
                                            slotProps={{
                                                input: {
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <QrCodeScannerTwoToneIcon/>
                                                        </InputAdornment>,
                                                },
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            type="button"
                                            endIcon={<AssignmentTurnedInTwoToneIcon/>}
                                            onClick={handleDodajNovuVezu}
                                            disabled={vezaId != null}
                                        >
                                            Dodajte gajbicu i radnika u lansiranje
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            required
                                            id="serijski_broj"
                                            name="serijski_broj"
                                            label="Unesite serijski broj proizvoda"
                                            placeholder="Unesite serijski broj proizvoda"
                                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                            variant="outlined"
                                            value={serijskiBroj}
                                            onChange={(event) => {
                                                setSerijskiBroj(event.target.value);
                                                checkSerijskiBrojRasporedjivanje(event.target.value);
                                            }}
                                            slotProps={{
                                                input: {
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <QrCodeScannerTwoToneIcon/>
                                                        </InputAdornment>,
                                                },
                                            }}
                                        />
                                        <ButtonGroup fullWidth variant="contained" aria-label="Basic button group">
                                            <Button color="error" onClick={() => setVezaId(null)}>Završi dodavanje za
                                                ovog
                                                radnika</Button>
                                            <Button color="success" disabled={!postoji}
                                                    onClick={handleLansirajProizvod}>Dodaj
                                                proizvod</Button>
                                        </ButtonGroup>
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xl: 6, lg: 6, md: 6, sm: 12, xs: 12}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                                {isError ? (
                                    <Alert severity="error">
                                        Greška prilikom učitavanja tovarnih medijuma i radnika koji su u ovom
                                        lansiranju!
                                    </Alert>
                                ) : isLoading ? (
                                    <LinearProgress/>
                                ) : (
                                    <DataGrid
                                        localeText={tableLang}
                                        columns={columns_veze}
                                        rows={isLoading ? [] : data?.data.veze}
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                                {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        type="button"
                                        endIcon={<SendToMobileTwoToneIcon/>}
                                        onClick={handleZavrsi}
                                        disabled={data?.data.za_rasporedjivanje.length != 0}
                                    >
                                        Završite lansiranje poliranja
                                    </Button>
                                )}
                            </Paper>
                        </Grid>
                    </>
                )
            )}
        </Grid>
    );
};

export default GiterKontrolaProcesPage;