import axios from "axios";
import {url} from "./urls";

export const mubeaMaterijali = async () => {
    return axios.get(url + `/mubea-materijali`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const mubeaMaterijal = async (id) => {
    return axios.get(url + `/mubea-materijal/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createMubeaMaterijal = async (formData) => {
    return axios.post(url + `/create-mubea-materijal`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}