import axios from "axios";
import {url} from "./urls";

export const giterKontrole = async () => {
    return axios.get(url + `/giter-kontrole`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const startGiterKontrola = async (formData) => {
    return axios.post(url + `/start-giter-kontrola`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const zaGiterKontrolu = async (id) => {
    return axios.get(url + `/za-giter-kontrolu/` + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const iskontrolisiProizvodGiter = async (formData) => {
    return axios.post(url + `/iskontrolisi-proizvod-giter`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const finishGiterKontrola = async (formData) => {
    return axios.post(url + `/finish-giter-kontrola`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const formiranjeVezeGiterKontrola = async (formData) => {
    return axios.post(url + `/giter-kontrola-formiranje-veze`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const lansirajProizvodGiterKontrola = async (formData) => {
    return axios.post(url + `/giter-kontrola-lansiraj-proizvod`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};
