import React from 'react';
import {useNavigate, useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import Button from "@mui/material/Button";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../components/additional/Item";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../language/tableLang";
import {poliranje} from "../../apis/poliranjeApi";

const PoliranjeRadniNalogPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['poliranje', id],
        queryFn: () => poliranje(id)
    });

    const columns_za_slajfanje = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 70,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_odslajfano = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stanje",
            headerName: "Stanje",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "status_number",
            headerName: "Pristup",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                if (params.row.id_lokacije == -1) {
                    return (
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center"}}
                            endIcon={<LocationOnTwoToneIcon/>}
                            disabled={true}
                        >
                            Za doradu
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            variant="contained"
                            sx={{width: "100%", alignSelf: "center"}}
                            endIcon={<LocationOnTwoToneIcon/>}
                            onClick={() => navigate('../lokacija/' + params.row.id_lokacije)}
                        >
                            Lokacija
                        </Button>
                    );
                }

            },
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o radnom nalogu za poliranje
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o radnom nalogu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.poliranje.id}</b></Item>
                            <Divider/>
                            <Item>Ime i prezime
                                radnika: <b>{data?.data.poliranje.ime} {data?.data.poliranje.prezime}</b></Item>
                            <Divider/>
                            <Item>Status: <b>{data?.data.poliranje.status}</b></Item>
                            <Divider/>
                            <Item>Trajanje: <b>{data?.data.trajanje}</b></Item>
                            <Divider/>
                            <Item>Vreme početka: <b>{data?.data.poliranje.vreme_pocetak}</b></Item>
                            <Divider/>
                            <Item>Vreme završetka: <b>{data?.data.poliranje.vreme_kraj}</b></Item>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.poliranje.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Za poliranje
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Ispolirano
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su za poliranje!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_za_slajfanje}
                            rows={isLoading ? [] : data?.data.za_poliranje}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su ispolirani!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_odslajfano}
                            rows={isLoading ? [] : data?.data.ispolirani_proizvodi}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PoliranjeRadniNalogPage;