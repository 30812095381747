import React from "react";
import ReactDOM from "react-dom/client";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AdminProtectedRoute from "./routes/AdminProtectedRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import PocetnaPage from "./pages/administrator/PocetnaPage";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import KontrolaPocetnaPage from "./pages/kontrola/KontrolaPocetnaPage";
import KontrolaProtectedRoute from "./routes/KontrolaProtectedRoute";
import PrijemiRobePage from "./pages/kontrola/PrijemiRobePage";
import NoviPrijemRobePage from "./pages/kontrola/NoviPrijemRobePage";
import PrijemRobePage from "./pages/kontrola/PrijemRobePage";
import ProveraSerijskogBrojaPage from "./pages/kontrola/ProveraSerijskogBrojaPage";
import SifrarniciPage from "./pages/administrator/sifrarnici/SifrarniciPage";
import SifrarnikPage from "./pages/administrator/sifrarnici/SifrarnikPage";
import SiroviGotoviSifrarniciPage from "./pages/administrator/sifrarnici/SiroviGotoviSifrarniciPage";
import SastavnicePage from "./pages/administrator/sastavnice/SastavnicePage";
import SastavnicaPage from "./pages/administrator/sastavnice/SastavnicaPage";
import TovarniMedijumiPage from "./pages/administrator/tovarni_medijumi/TovarniMedijumiPage";
import TovarniMedijumPage from "./pages/administrator/tovarni_medijumi/TovarniMedijumPage";
import RepromaterijaliPage from "./pages/administrator/repromaterijali/RepromaterijaliPage";
import RepromaterijalPage from "./pages/administrator/repromaterijali/RepromaterijalPage";
import SkladistarProtectedRoute from "./routes/SkladistarProtectedRoute";
import SkladistarPocetnaPage from "./pages/skladistar/SkladistarPocetnaPage";
import MasinePage from "./pages/administrator/masine/MasinePage";
import MasinaPage from "./pages/administrator/masine/MasinaPage";
import DobavljaciPage from "./pages/administrator/dobavljaci/DobavljaciPage";
import {KreirajDobavljacPage} from "./pages/administrator/dobavljaci/KreirajDobavljacPage";
import {DobavljacPage} from "./pages/administrator/dobavljaci/DobavljacPage";
import KupciPage from "./pages/administrator/kupci/KupciPage";
import {KreirajKupacPage} from "./pages/administrator/kupci/KreirajKupacPage";
import {KupacPage} from "./pages/administrator/kupci/KupacPage";
import RadniNaloziPage from "./pages/administrator/radni_nalozi/RadniNaloziPage";
import RadniNalogPage from "./pages/administrator/radni_nalozi/RadniNalogPage";
import SkladistaPage from "./pages/administrator/skladista/SkladistaPage";
import SkladistePage from "./pages/administrator/skladista/SkladistePage";
import LokacijePage from "./pages/administrator/lokacije/LokacijePage";
import LokacijaPage from "./pages/administrator/lokacije/LokacijaPage";
import TrebovanjaPage from "./pages/skladistar/trebovanje/TrebovanjaPage";
import NovoTrebovanjePage from "./pages/skladistar/trebovanje/NovoTrebovanjePage";
import TrebovanjePage from "./pages/skladistar/trebovanje/TrebovanjePage";
import LansiranjaPage from "./pages/skladistar/lansiranje/LansiranjaPage";
import NovoLansiranjePage from "./pages/skladistar/lansiranje/NovoLansiranjePage";
import LansiranjePage from "./pages/skladistar/lansiranje/LansiranjePage";
import SlajfanjeProtectedRoute from "./routes/SlajfanjeProtectedRoute";
import SlajfanjePocetnaPage from "./pages/slajfanje/SlajfanjePocetnaPage";
import PIPProtectedRoute from "./routes/PIPProtectedRoute";
import PipPocetnaPage from "./pages/plazma_i_pranje/PIPPocetnaPage";
import SlajfanjeRadniNaloziPage from "./pages/slajfanje/SlajfanjeRadniNaloziPage";
import SlajfanjeProcesPage from "./pages/slajfanje/SlajfanjeProcesPage";
import SlajfanjeRadniNalogPage from "./pages/slajfanje/SlajfanjeRadniNalogPage";
import KontrolorRadniNaloziPage from "./pages/kontrola/KontrolorRadniNaloziPage";
import KontrolorSlajfanjePage from "./pages/kontrola/radni_nalozi/slajfanje/KontrolorSlajfanjePage";
import KontrolorSlajfanjaPage from "./pages/kontrola/radni_nalozi/slajfanje/KontrolorSlajfanjaPage";
import PipRadniNaloziPage from "./pages/plazma_i_pranje/PIPRadniNaloziPage";
import PipProcesPage from "./pages/plazma_i_pranje/PIPProcesPage";
import PipRadniNalogPage from "./pages/plazma_i_pranje/PIPRadniNalogPage";
import KontrolorPlazmaIPranjaPage from "./pages/kontrola/radni_nalozi/plazma_i_pranje/KontrolorPlazmaIPranjaPage";
import KontrolorPlazmaIPranjePage from "./pages/kontrola/radni_nalozi/plazma_i_pranje/KontrolorPlazmaIPranjePage";
import LakiranjeProtectedRoute from "./routes/LakiranjeProtectedRoute";
import LakiranjePocetnaPage from "./pages/lakiranje/LakiranjePocetnaPage";
import KontrolorLakiranjaPage from "./pages/kontrola/radni_nalozi/lakiranje/KontrolorLakiranjaPage";
import KontrolorLakiranjePage from "./pages/kontrola/radni_nalozi/lakiranje/KontrolorLakiranjePage";
import LakiranjeRadniNaloziPage from "./pages/lakiranje/LakiranjeRadniNaloziPage";
import LakiranjeProcesPage from "./pages/lakiranje/LakiranjeProcesPage";
import LakiranjeRadniNalogPage from "./pages/lakiranje/LakiranjeRadniNalogPage";
import PecenjeProcesPage from "./pages/lakiranje/PecenjeProcesPage";
import GiterKontrolePage from "./pages/kontrola/radni_nalozi/giter_kontrola/GiterKontrolePage";
import GiterKontrolaPage from "./pages/kontrola/radni_nalozi/giter_kontrola/GiterKontrolaPage";
import GiterKontrolaProcesPage from "./pages/kontrola/radni_nalozi/giter_kontrola/GiterKontrolaProcesPage";
import PoliranjeProtectedRoute from "./routes/PoliranjeProtectedRoute";
import PoliranjePocetnaPage from "./pages/poliranje/PoliranjePocetnaPage";
import PoliranjeRadniNaloziPage from "./pages/poliranje/PoliranjeRadniNaloziPage";
import PoliranjeProcesPage from "./pages/poliranje/PoliranjeProcesPage";
import PoliranjeRadniNalogPage from "./pages/poliranje/PoliranjeRadniNalogPage";
import KontrolorPoliranjaPage from "./pages/kontrola/radni_nalozi/poliranje/KontrolorPoliranjaPage";
import KontrolorPoliranjePage from "./pages/kontrola/radni_nalozi/poliranje/KontrolorPoliranjePage";
import ZavrsneKontrolePage from "./pages/kontrola/radni_nalozi/zavrsna_kontrola/ZavrsneKontrolePage";
import ZavrsnaKontrolaPage from "./pages/kontrola/radni_nalozi/zavrsna_kontrola/ZavrsnaKontrolaPage";
import PodesavanjaPage from "./pages/PodesavanjaPage";
import KorisniciPage from "./pages/administrator/korisnici/KorisniciPage";
import {KreirajKorisnikPage} from "./pages/administrator/korisnici/KreirajKorisnikPage";
import RadnePozicijePage from "./pages/administrator/radni_centri/RadnePozicijePage";
import RadnaPozicijaPage from "./pages/administrator/radni_centri/RadnaPozicijaPage";
import KorisnikPage from "./pages/administrator/korisnici/KorisnikPage";
import ProfilePage from "./pages/ProfilePage";
import SkladistarOtpremnicePage from "./pages/skladistar/otpremnice/SkladistarOtpremnicePage";
import NovaOtpremnicaPage from "./pages/skladistar/otpremnice/NovaOtpremnicaPage";
import SkladistarOtpremnicaPage from "./pages/skladistar/otpremnice/SkladistarOtpremnicaPage";
import OtpremnicePage from "./pages/administrator/otpremnice/OtpremnicePage";
import OtpremnicaPage from "./pages/administrator/otpremnice/OtpremnicaPage";
import StanjaSkladistaPage from "./pages/administrator/stanja_skladista/StanjaSkladistaPage";
import StanjeSkladistaPage from "./pages/administrator/stanja_skladista/StanjeSkladistaPage";

const ApplicationRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="" index element={<LoginPage/>}/>
                <Route path="/*" element={<ProtectedRoute/>}>
                    <Route path="administrator/*" element={<AdminProtectedRoute/>}>
                        <Route path="pocetna" element={<PocetnaPage/>}/>
                        <Route path="profil/:id" element={<ProfilePage/>}/>
                        <Route path="podesavanja" element={<PodesavanjaPage/>}/>

                        <Route path="dobavljaci" element={<DobavljaciPage/>}/>
                        <Route path="dobavljac/create" element={<KreirajDobavljacPage/>}/>
                        <Route path="dobavljac/:id" element={<DobavljacPage/>}/>

                        <Route path="kupci" element={<KupciPage/>}/>
                        <Route path="kupac/create" element={<KreirajKupacPage/>}/>
                        <Route path="kupac/:id" element={<KupacPage/>}/>

                        <Route path="skladista" element={<SkladistaPage/>}/>
                        <Route path="skladiste/:id" element={<SkladistePage/>}/>

                        <Route path="stanja_skladista" element={<StanjaSkladistaPage/>}/>
                        <Route path="stanje_skladista/:id" element={<StanjeSkladistaPage/>}/>

                        <Route path="lokacije" element={<LokacijePage/>}/>
                        <Route path="lokacija/:id" element={<LokacijaPage/>}/>

                        <Route path="sifrarnici" element={<SifrarniciPage/>}/>
                        <Route path="sifrarnik/:id" element={<SifrarnikPage/>}/>
                        <Route path="veze-izmedju-sirovog-i-gotovog-proizvoda" element={<SiroviGotoviSifrarniciPage/>}/>

                        <Route path="sastavnice" element={<SastavnicePage/>}/>
                        <Route path="sastavnica/:id" element={<SastavnicaPage/>}/>

                        <Route path="tovarni-medijumi" element={<TovarniMedijumiPage/>}/>
                        <Route path="tovarni-medijum/:id" element={<TovarniMedijumPage/>}/>

                        <Route path="repromaterijali" element={<RepromaterijaliPage/>}/>
                        <Route path="repromaterijal/:id" element={<RepromaterijalPage/>}/>

                        <Route path="masine" element={<MasinePage/>}/>
                        <Route path="masina/:id" element={<MasinaPage/>}/>

                        <Route path="radni-nalozi" element={<RadniNaloziPage/>}/>
                        <Route path="radni-nalog/:id" element={<RadniNalogPage/>}/>

                        <Route path="radni-centri" element={<RadnePozicijePage/>}/>
                        <Route path="radni-centar/:id" element={<RadnaPozicijaPage/>}/>

                        <Route path="korisnici" element={<KorisniciPage/>}/>
                        <Route path="korisnik/create" element={<KreirajKorisnikPage/>}/>
                        <Route path="user-info/:id" element={<KorisnikPage/>}/>

                        <Route path="otpremnice" element={<OtpremnicePage/>}/>
                        <Route path="otpremnica/:id" element={<OtpremnicaPage/>}/>
                    </Route>

                    <Route path="kontrola/*" element={<KontrolaProtectedRoute/>}>
                        <Route path="pocetna" element={<KontrolaPocetnaPage/>}/>

                        <Route path="prijemi-robe" element={<PrijemiRobePage/>}/>
                        <Route path="novi-prijem-robe/:id" element={<NoviPrijemRobePage/>}/>
                        <Route path="prijem-robe/:id" element={<PrijemRobePage/>}/>

                        <Route path="provera-serijskog-broja" element={<ProveraSerijskogBrojaPage/>}/>

                        <Route path="radni-nalozi" element={<KontrolorRadniNaloziPage/>}/>

                        <Route path="slajfanja" element={<KontrolorSlajfanjaPage/>}/>
                        <Route path="slajfanje/:id" element={<KontrolorSlajfanjePage/>}/>

                        <Route path="plazma-i-pranja" element={<KontrolorPlazmaIPranjaPage/>}/>
                        <Route path="plazma-i-pranje/:id" element={<KontrolorPlazmaIPranjePage/>}/>

                        <Route path="lakiranja" element={<KontrolorLakiranjaPage/>}/>
                        <Route path="lakiranje/:id" element={<KontrolorLakiranjePage/>}/>

                        <Route path="giter-kontrole" element={<GiterKontrolePage/>}/>
                        <Route path="giter-kontrola-proces/:id" element={<GiterKontrolaProcesPage/>}/>
                        <Route path="giter-kontrola/:id" element={<GiterKontrolaPage/>}/>

                        <Route path="poliranja" element={<KontrolorPoliranjaPage/>}/>
                        <Route path="poliranje/:id" element={<KontrolorPoliranjePage/>}/>

                        <Route path="zavrsne-kontrole" element={<ZavrsneKontrolePage/>}/>
                        <Route path="zavrsna-kontrola/:id" element={<ZavrsnaKontrolaPage/>}/>
                    </Route>

                    <Route path="skladistar/*" element={<SkladistarProtectedRoute/>}>
                        <Route path="pocetna" element={<SkladistarPocetnaPage/>}/>

                        <Route path="trebovanja" element={<TrebovanjaPage/>}/>
                        <Route path="novo-trebovanje/:id" element={<NovoTrebovanjePage/>}/>
                        <Route path="trebovanje/:id" element={<TrebovanjePage/>}/>

                        <Route path="radni-nalog/:id" element={<RadniNalogPage/>}/>
                        <Route path="lokacija/:id" element={<LokacijaPage/>}/>

                        <Route path="lansiranja" element={<LansiranjaPage/>}/>
                        <Route path="novo-lansiranje/:id" element={<NovoLansiranjePage/>}/>
                        <Route path="lansiranje/:id" element={<LansiranjePage/>}/>

                        <Route path="otpremnice" element={<SkladistarOtpremnicePage/>}/>
                        <Route path="nova-otpremnica/:id" element={<NovaOtpremnicaPage/>}/>
                        <Route path="otpremnica/:id" element={<SkladistarOtpremnicaPage/>}/>

                        <Route path="stanja_skladista" element={<StanjaSkladistaPage/>}/>
                        <Route path="stanje_skladista/:id" element={<StanjeSkladistaPage/>}/>
                    </Route>

                    <Route path="slajfanje/*" element={<SlajfanjeProtectedRoute/>}>
                        <Route path="pocetna" element={<SlajfanjePocetnaPage/>}/>
                        <Route path="radni-nalozi" element={<SlajfanjeRadniNaloziPage/>}/>
                        <Route path="proces/:id" element={<SlajfanjeProcesPage/>}/>
                        <Route path="radni-nalog/:id" element={<SlajfanjeRadniNalogPage/>}/>

                        <Route path="lokacija/:id" element={<LokacijaPage/>}/>
                    </Route>

                    <Route path="plazma-i-pranje/*" element={<PIPProtectedRoute/>}>
                        <Route path="pocetna" element={<PipPocetnaPage/>}/>

                        <Route path="radni-nalozi" element={<PipRadniNaloziPage/>}/>
                        <Route path="proces/:id" element={<PipProcesPage/>}/>
                        <Route path="radni-nalog/:id" element={<PipRadniNalogPage/>}/>

                        <Route path="lokacija/:id" element={<LokacijaPage/>}/>
                    </Route>

                    <Route path="lakiranje/*" element={<LakiranjeProtectedRoute/>}>
                        <Route path="pocetna" element={<LakiranjePocetnaPage/>}/>
                        <Route path="radni-nalozi" element={<LakiranjeRadniNaloziPage/>}/>
                        <Route path="proces/:id" element={<LakiranjeProcesPage/>}/>
                        <Route path="proces-pecenje/:id" element={<PecenjeProcesPage/>}/>
                        <Route path="radni-nalog/:id" element={<LakiranjeRadniNalogPage/>}/>

                        <Route path="lokacija/:id" element={<LokacijaPage/>}/>
                    </Route>

                    <Route path="poliranje/*" element={<PoliranjeProtectedRoute/>}>
                        <Route path="pocetna" element={<PoliranjePocetnaPage/>}/>
                        <Route path="radni-nalozi" element={<PoliranjeRadniNaloziPage/>}/>
                        <Route path="proces/:id" element={<PoliranjeProcesPage/>}/>
                        <Route path="radni-nalog/:id" element={<PoliranjeRadniNalogPage/>}/>

                        <Route path="lokacija/:id" element={<LokacijaPage/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

function App() {
    const queryClient = new QueryClient();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
                <ApplicationRoutes></ApplicationRoutes>
            </QueryClientProvider>
        </LocalizationProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);
