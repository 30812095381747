import React, { useState } from 'react';
import { useQuery } from "@tanstack/react-query";
import { getUserInfo, updateUserPassword } from "../apis/korisniciApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { Alert, LinearProgress, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Item } from "../components/additional/Item";
import NalepnicaKorisnik from "../components/stampanje/NalepnicaKorisnik";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LocalPrintshopTwoToneIcon from "@mui/icons-material/LocalPrintshopTwoTone";

const ProfilePage = () => {
    const userId = localStorage.getItem("user_id");
    const { data, isLoading, isError } = useQuery({
        queryKey: ['korisnik', userId],
        queryFn: () => getUserInfo(userId)
    });

    const [open, setOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlePasswordChange = async () => {
        setError("");
        setSuccess("");

        // Check if new password and confirm password match
        if (newPassword !== confirmPassword) {
            setError("Nova šifra i potvrda šifre se ne podudaraju.");
            return;
        }

        // Check if the new password length is at least 8 characters
        if (newPassword.length < 8) {
            setError("Nova šifra mora imati najmanje 8 karaktera.");
            return;
        }

        try {
            const response = await updateUserPassword({
                id: userId,
                oldPassword,
                newPassword
            });
            setSuccess(response.data.message);
            setOpen(false);
        } catch (err) {
            setError(err.response?.data?.message || "Greška pri ažuriranju šifre");
        }
    };

    const printDiv = (divId) => {
        const container = document.getElementById(divId);
        const width = "100%";
        const height = "100%";
        const printWindow = window.open(
            "",
            "PrintUser",
            "width=" + width + ",height=" + height,
        );
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Paper sx={{ p: 2, justifyContent: "center", alignItems: "center" }}>
            <Typography sx={{ fontSize: 22, textAlign: "center" }}>
              <PersonIcon size={22} sx={{ mr: 2 }} />
              Moj Profil
            </Typography>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Paper sx={{ p: 2, justifyContent: "center", alignItems: "center" }}>
            {isError ? (
              <Alert severity="error">
                Greška prilikom preuzimanja podataka o korisniku!
              </Alert>
            ) : isLoading ? (
              <LinearProgress size={24} color="inherit" />
            ) : (
              <Stack spacing={2}>
                <Divider />
                <Item>
                  Ime: <b>{data?.data.ime}</b>
                </Item>
                <Divider />
                <Item>
                  Prezime: <b>{data?.data.prezime}</b>
                </Item>
                <Divider />
                <Item>
                  Email: <b>{data?.data.email}</b>
                </Item>
                <Divider />
                <Button variant="contained" onClick={() => setOpen(true)}>
                  Promeni šifru
                </Button>
                <Divider />
                <Divider />
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                  id="stampanje"
                >
                  <NalepnicaKorisnik
                    ime={data?.data.ime}
                    prezime={data?.data.prezime}
                    email={data?.data.email}
                    barkod={data?.data.barkod}
                  />
                </div>
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  endIcon={<LocalPrintshopTwoToneIcon />}
                  variant="contained"
                  onClick={() => printDiv("stampanje")}
                >
                  Štampajte nalepnicu
                </Button>
                <Divider />
              </Stack>
            )}
          </Paper>
        </Grid>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Promena šifre</DialogTitle>
          <DialogContent>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <TextField
              margin="dense"
              label="Stara šifra"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              label="Nova šifra"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              label="Potvrdi novu šifru"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Otkaži</Button>
            <Button onClick={handlePasswordChange} variant="contained">
              Sačuvaj
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
};

export default ProfilePage;
