import axios from "axios";
import {url} from "./urls";

export const plazmaIPranja = async () => {
    return axios.get(url + '/plazma-i-pranja', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const plazmaIPranje = async (id) => {
    return axios.get(url + '/plazma-i-pranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const startaPlazmaIPranje = async (formData) => {
    return axios.post(url + '/start-plazma-i-pranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaPlazmuIPranje = async (id) => {
    return axios.get(url + '/za-plazmu-i-pranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaKontroluPlazmaIPranje = async (id) => {
    return axios.get(url + '/za-kontrolu-plazma-i-pranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const plazmirajIOperiProizvod = async (formData) => {
    return axios.post(url + '/plazmirajte-i-operite-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const iskontrolisiteProizvodPlazmaIPranje = async (formData) => {
    return axios.post(url + '/iskontrolisite-proizvod-plazma-i-pranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const proslediteKontroloruPlazmaIPranje = async (formData) => {
    return axios.post(url + '/prosledite-kontroloru-plazma-i-pranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const finishPlazmaIPranje = async (formData) => {
    return axios.post(url + '/end-plazma-i-pranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const plazmaIPranjeZaKontrolu = async () => {
    return axios.get(url + '/plazma-i-pranje-za-kontrolu', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}