import axios from "axios";
import {url} from "./urls";

export const getDobavljaci = async () => {
    return axios.get(url + `/dobavljaci`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const dobavljac = async (id) => {
    return axios.get(url + `/dobavljac/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createDobavljac = async (formData) => {
    return axios.post(url + '/create-dobavljac', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const updateDobavljac = async (formData) => {
    return axios.post(url + '/update-dobavljac', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
