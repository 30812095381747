import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {mubeaMaterijal} from "../../../apis/repromaterijaliApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";

const RepromaterijalPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['mubeaMaterijal', id],
        queryFn: () => mubeaMaterijal(id)
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o repromaterijalu
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o repromaterijalu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.id}</b></Item>
                            <Divider/>
                            <Item>Šifra: <b>{data?.data.sifra}</b></Item>
                            <Divider/>
                            <Item>Naziv: <b>{data?.data.naziv}</b></Item>
                            <Divider/>
                            <Item>Opis: <b>{data?.data.opis}</b></Item>
                            <Divider/>
                            <Item>Jedinica: <b>{data?.data.jedinica}</b></Item>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default RepromaterijalPage;