import axios from "axios";
import { url } from "./urls";

export const getRadnePozicije = async () => {
    return axios.get(url + `/radne-pozicije`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const getRadnaPozicijaById = async (id) => {
    return axios.get(`${url}/radna-pozicija/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};