import React, {useState} from 'react';
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import MenuButton from "./MenuButton";
import Badge from "@mui/material/Badge";
import {Alert, Chip, LinearProgress, MenuItem} from "@mui/material";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import {useQuery} from "@tanstack/react-query";
import {notifications} from "../../apis/userApi";

const Notifications = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ['notifications'],
        queryFn: notifications,
    });

    return (
        <>
            <MenuButton id="menu-button" onClick={handleClick} aria-label="open-notifications">
                <Badge badgeContent={isLoading ? 0 : data?.data.pbgp_count} color="error">
                    <NotificationsRoundedIcon/>
                </Badge>
            </MenuButton>

            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'open-notifications',
                }}
            >
                <Divider>
                    <Chip label="Proizvodi kojima fali veza gotovim proizvodom" size="small"/>
                </Divider>
                {isLoading ? (
                    <LinearProgress size={22} color="inherit"/>
                ) : (
                    data?.data.proizvodi_bez_gotovog_proizvoda.length == 0 ? (
                        <Alert severity="info">
                            Nema obaveštenja o ovome!
                        </Alert>
                    ) : (
                        data?.data.proizvodi_bez_gotovog_proizvoda.map((item) => {
                            return (
                                <>
                                    <MenuItem>{item.id} | {item.sifra} | {item.naziv} | {item.tip == 0 ? "Sirov proizvod" : "Gotov proizvod"}</MenuItem>
                                    <Divider/>
                                </>
                            );
                        })
                    )
                )}
                <Divider>
                    <Chip label="Ostala obaveštenja" size="small"/>
                </Divider>
                <Alert severity="info">
                    Nema ostalih obaveštenja!
                </Alert>
            </Menu>
        </>
    );
};

export default Notifications;