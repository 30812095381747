import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {radniNaloziKontrolor} from "../../apis/kontrolorApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import Button from "@mui/material/Button";
import ArrowOutwardTwoToneIcon from "@mui/icons-material/ArrowOutwardTwoTone";
import {LinearProgress} from "@mui/material";
import {useNavigate} from "react-router";

const KontrolorRadniNaloziPage = () => {
    const navigate = useNavigate();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["radniNaloziKontrolor"],
        queryFn: radniNaloziKontrolor,
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Spisak radnih naloga za kontrolora
                    </Typography>
                </Paper>
            </Grid>
            {isLoading ? <LinearProgress size={24} color="inherit"/> :
                data?.data.map((item, index) => (
                    <Grid size={{xs: 12, md: 6, lg: 6, xl: 6}}>
                        <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                            <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                {item.naziv} | Broj otvorenih naloga: <b>{item.broj_otvorenih_naloga}</b>
                            </Typography>
                            <Button
                                endIcon={<ArrowOutwardTwoToneIcon/>}
                                sx={{mt: 2}}
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    navigate(item.route);
                                }}
                            >
                                Pristup
                            </Button>
                        </Paper>
                    </Grid>
                ))}
        </Grid>
    );
};

export default KontrolorRadniNaloziPage;