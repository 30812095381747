import axios from "axios";
import {url} from "./urls";

export const trebovanja = async () => {
    return axios.get(url + '/trebovanja', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const trebovanje = async (id) => {
    return axios.get(url + '/trebovanje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const createTrebovanje = async (formData) => {
    return axios.post(url + '/create-trebovanje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const potrebnoTrebovati = async (id) => {
    return axios.get(url + '/potrebno-trebovati/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const trebujProizvod = async (formData) => {
    return axios.post(url + '/trebuj-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const trebujDodatniProizvod = async (formData) => {
    return axios.post(url + '/trebuj-dodatni-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zavrsiTrebovanje = async (formData) => {
    return axios.post(url + '/finish-trebovanje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}