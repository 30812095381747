import axios from "axios";
import {url} from "./urls";

export const radniNaloziKontrolor = async () => {
    return axios.get(url + `/radni-nalozi-kontrolor`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};