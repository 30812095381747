import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {
    Alert,
    Autocomplete,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Button from "@mui/material/Button";
import Notification from "../../components/Notification";
import MoveToInboxTwoToneIcon from '@mui/icons-material/MoveToInboxTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import {
    finishPrijem,
    noviProizvodIliRepromaterijal,
    primiRobu,
    primljenaRoba,
    proizvodiIRepromaterijali
} from "../../apis/prijemRobeApi";
import {getDobavljaci} from "../../apis/dobavljaciApi";
import TextField from "@mui/material/TextField";
import NalepnicaProizvod from "../../components/stampanje/NalepnicaProizvod";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";

const jedinicaOptions = ["STK", "L", "ROLLE", "PAK"];
const opisOptions = [
    "Reinigungswasser", "Politure", "Schleifpapier", "Mikrofasertücher",
    "Klarlack", "Härter", "Verdünner", "Primer",
    "Schleifteller", "Schleifmittel", "Schleifmaschine",
    "Waschmittel", "Folie"
];


const PrimljeniProizvodiTable = ({rows}) => {
    const columns = [
        {id: 'id', label: 'ID', minWidth: 50},
        {id: 'stampa', label: 'Štampa', minWidth: 150},
        {id: 'sifra', label: 'Šifra', minWidth: 150},
        {id: 'naziv', label: 'Naziv', minWidth: 150},
        {id: 'serijski_broj', label: 'Serijski broj', minWidth: 200},
        {id: 'kolicina', label: 'Količina', minWidth: 100},
        {id: 'tip', label: 'Tip', minWidth: 75},
        {id: 'status', label: 'Status', minWidth: 110},
        {id: 'vreme', label: 'Vreme', minWidth: 150},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const printDiv = (divId) => {
        const container = document.getElementById(divId);
        const width = "100%";
        const height = "100%";
        const printWindow = window.open(
            "",
            "PrintMap",
            "width=" + width + ",height=" + height,
        );
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
        <>
            <TableContainer sx={{maxHeight: 700}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={1}
                                    align="center"
                                    style={{minWidth: column.minWidth}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <TableCell key={1} align="center">
                                            {row.id}
                                        </TableCell>
                                        <TableCell key={2} align="center">
                                            <div
                                                style={{
                                                    justifyContent: "center",
                                                    display: "none",
                                                }}
                                                id={"stampa" + row.id}
                                            >
                                                <NalepnicaProizvod
                                                    naziv={row.naziv}
                                                    serijski_broj={row.serijski_broj}
                                                    sifra={row.sifra}
                                                />
                                            </div>
                                            <Button onClick={() => printDiv("stampa" + row.id)}
                                                    disabled={row.tip == "Repromaterijal"} color="secondary"
                                                    variant="contained" endIcon={<LocalPrintshopTwoToneIcon/>}>
                                                Štampaj
                                            </Button>
                                        </TableCell>
                                        <TableCell key={3} align="center">
                                            {row.sifra}
                                        </TableCell>
                                        <TableCell key={4} align="center">
                                            {row.naziv}
                                        </TableCell>
                                        <TableCell key={5} align="center">
                                            {row.serijski_broj}
                                        </TableCell>
                                        <TableCell key={6} align="center">
                                            {row.kolicina} {row.jedinica}
                                        </TableCell>
                                        <TableCell key={7} align="center">
                                            {row.tip}
                                        </TableCell>
                                        <TableCell key={8} align="center">
                                            {row.status}
                                        </TableCell>
                                        <TableCell key={9} align="center">
                                            {row.vreme}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

const NoviPrijemRobePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [sifra, setSifra] = useState("");
    const [naziv, setNaziv] = useState("");
    const [tip, setTip] = useState(null); // Tip: "Proizvod" or something else
    const [jedinica, setJedinica] = useState("");
    const [opis, setOpis] = useState("");

    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const [openAutocomplete1, setOpenAutocomplete1] = useState(false);
    const handleOpenAutocomplete1 = () => setOpenAutocomplete1(true);
    const handleCloseAutocomplete1 = () => setOpenAutocomplete1(false);

    const [dobavljac, setDobavljac] = useState(null);
    const [dobavljacError, setDobavljacError] = useState(false);
    const [dobavljacErrorMessage, setDobavljacErrorMessage] = useState("");

    const [roba, setRoba] = useState(null);
    const [robaError, setRobaError] = useState(false);
    const [robaErrorMessage, setRobaErrorMessage] = useState("");

    const [kolicina, setKolicina] = useState(1);
    const [kolicinaError, setKolicinaError] = useState(false);
    const [kolicinaErrorMessage, setKolicinaErrorMessage] = useState("");

    const [barkodLokacija, setBarkodLokacija] = useState("");
    const [barkodLokacijaError, setBarkodLokacijaError] = useState(false);
    const [barkodLokacijaErrorMessage, setBarkodLokacijaErrorMessage] = useState("");

    const [status, setStatus] = useState(0);

    const {data, isLoading, isError} = useQuery({
        queryKey: ['PrimljenaRoba', id],
        queryFn: () => primljenaRoba(id)
    });

    const {data: dobavljaci, isLoading: loadingDobavljaci, isError: errorDobavljaci} = useQuery({
        queryKey: ['Dobavljaci'],
        queryFn: getDobavljaci,
        enabled: openAutocomplete
    });

    const {data: proizvodi_i_repromaterijali, isLoading: loadingPIR, isError: errorPIR} = useQuery({
        queryKey: ['PIR'],
        queryFn: proizvodiIRepromaterijali,
        enabled: openAutocomplete1
    });

    const primanjeRobe = useMutation({
        mutationFn: (formData) => primiRobu(formData),
        onSuccess: (response) => {
            setOpen(true);
            setType(1);
            setMessage(response.data.message);

            handlePrimiProizvod();

            queryClient.invalidateQueries(['PrimljenaRoba', id]).then(r => {
            });
        },
        onError: (error) => {
            setOpen(true);
            setType(2);
            setMessage(error.response.data.message);
        }
    });

    const noviPIR = useMutation({
        mutationFn: (formData) => noviProizvodIliRepromaterijal(formData),
        onSuccess: (response) => {
            setOpen(true);
            setType(1);
            setMessage(response.data.message);

            handleClosePopup();
        },
        onError: (error) => {
            setOpen(true);
            setType(2);
            setMessage(error.response.data.message);
        }
    });

    const zavrsiPrijem = useMutation({
        mutationFn: (formData) => finishPrijem(formData),
        onSuccess: (response) => {
            setOpen(true);
            setType(1);
            setMessage(response.data.message);

            setTimeout(() => {
                navigate("/kontrola/prijemi-robe");
            }, 2000);
        },
        onError: (error) => {
            setOpen(true);
            setType(2);
            setMessage(error.response.data.message);
        }
    });

    const handlePrimanjeRobe = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (dobavljac == null) {
            setOpen(true);
            setType(2);
            setMessage("Morate izabrati dobavljača!");
            return;
        }

        if (roba == null) {
            setOpen(true);
            setType(2);
            setMessage("Morate izabrati robu koju primate!");
            return;
        }

        if (roba?.tip == 1) {
            if (kolicina < 1) {
                setOpen(true);
                setType(2);
                setMessage("Morate uneti količinu koja je veća ili jednaka jedinici!");
                return;
            }
        }

        if (barkodLokacija == "") {
            setOpen(true);
            setType(2);
            setMessage("Morate uneti barkod lokacije!");
            return;
        }

        formData.append("id_prijem", id);
        formData.append("id_radnik", localStorage.getItem("user_id"));
        formData.append('sifra', roba?.sifra);
        formData.append('naziv', roba?.naziv);
        formData.append('tip', roba?.tip);
        formData.append('status', status);
        formData.append('kolicina', roba?.tip == 0 ? 1 : kolicina);
        formData.append('jedinica', roba?.jedinica);
        formData.append('id_dobavljac', dobavljac?.id);
        formData.append('barkod_lokacija', barkodLokacija);

        primanjeRobe.mutateAsync(formData).catch((err) => {
        });
    }

    const handleInputChange = (event, value) => {
        setSearchQuery(value);

        // If the user typed something and there are no matching results, show a notification
        if (value && !loadingPIR && !proizvodi_i_repromaterijali?.data?.some(item =>
            item.naziv.toLowerCase().includes(value.toLowerCase()) ||
            item.sifra.toLowerCase().includes(value.toLowerCase())
        )) {
            setOpenSnackbar(true);
        }
    };

    const handleOpenPopup = () => {
        setOpenSnackbar(false);
        setOpenDialog(true);
    };

    const handleClosePopup = () => {
        setOpenDialog(false);
        setSifra("");
        setNaziv("");
        setTip("");
        setJedinica("");
        setOpis("");
    };

    const handleSaveProduct = () => {
        // Here you can handle form submission (send to API, etc.)
        console.log({sifra, naziv, tip, jedinica, opis});
        const formData = new FormData();

        if (sifra == "") {
            setOpen(true);
            setType(2);
            setMessage("Morate uneti šifru robe!");
            return;
        }

        if (naziv == "") {
            setOpen(true);
            setType(2);
            setMessage("Morate uneti naziv robe!");
            return;
        }

        if (tip == null) {
            setOpen(true);
            setType(2);
            setMessage("Morate izabrati tip robe!");
            return;
        }

        if (tip == 1) {
            if (jedinica == "") {
                setOpen(true);
                setType(2);
                setMessage("Morate izabrati naziv repromaterijala!");
                return;
            }

            if (opis == "") {
                setOpen(true);
                setType(2);
                setMessage("Morate izabrati opis repromaterijala!");
                return;
            }
        }


        formData.append("id_prijem", id);
        formData.append('id_radnik', localStorage.getItem("user_id"));
        formData.append('sifra', sifra);
        formData.append('naziv', naziv);
        formData.append('tip', tip);
        formData.append('jedinica', jedinica);
        formData.append('opis', opis);

        noviPIR.mutateAsync(formData).catch((err) => {
        });
    };

    const handlePrimiProizvod = () => {
        setDobavljac(null);
        setKolicina(1);
        setRoba(null);
        setStatus(0);
        setBarkodLokacija("");
    };

    const handleZavrsiPrijem = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id', id);

        zavrsiPrijem.mutateAsync(formData).catch((err) => {
        });
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={open}
                setOpen={setOpen}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Novi prijem robe
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Box component="form" noValidate onSubmit={handlePrimanjeRobe}>
                        <Autocomplete
                            fullWidth
                            open={openAutocomplete}
                            onOpen={handleOpenAutocomplete}
                            onClose={handleCloseAutocomplete}
                            isOptionEqualToValue={(option, value) =>
                                option.naziv == value.naziv || option.gln == value.gln || option.sifra == value.sifra
                            }
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setDobavljac(newValue);
                                }
                            }}
                            value={dobavljac}
                            getOptionLabel={(option) => {
                                return "Naziv: " + option.naziv + " | GLN: " + option.gln + " | Šifra: " + option.sifra;
                            }}
                            options={loadingDobavljaci ? [] : dobavljaci?.data}
                            loading={loadingDobavljaci}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    margin="normal"
                                    label="Izaberite dobavljača"
                                    error={dobavljacError}
                                    helperText={dobavljacErrorMessage}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingDobavljaci ? (
                                                        <CircularProgress color="inherit" size={20}/>
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        },
                                    }}
                                />
                            )}
                        />

                        <Autocomplete
                            fullWidth
                            open={openAutocomplete1}
                            onOpen={handleOpenAutocomplete1}
                            onClose={handleCloseAutocomplete1}
                            onInputChange={handleInputChange}
                            isOptionEqualToValue={(option, value) =>
                                option.naziv == value.naziv || option.sifra == value.sifra
                            }
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setRoba(newValue);
                                } else {
                                    console.log("Nema ove robe u sistemu!");
                                }
                            }}
                            value={roba}
                            getOptionLabel={(option) => {
                                return "Naziv: " + option.naziv + " | Šifra: " + option.sifra + " | Tip: " + option.tip_robe;
                            }}
                            options={loadingPIR ? [] : proizvodi_i_repromaterijali?.data}
                            loading={loadingPIR}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    margin="normal"
                                    label="Izaberite robu koju primate"
                                    error={robaError}
                                    helperText={robaErrorMessage}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingPIR ? (
                                                        <CircularProgress color="inherit" size={20}/>
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        },
                                    }}
                                />
                            )}
                        />

                        {roba == null ? null : (
                            roba?.tip == 0 ? null : (
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="kolicina"
                                    name="kolicina"
                                    label="Unesite količinu repromaterijala"
                                    helperText="Ceo broj ili dve decimale"
                                    variant="outlined"
                                    value={kolicina}
                                    onChange={(event) => setKolicina(event.target.value)}
                                    slotProps={{
                                        input: {
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {roba?.jedinica}
                                                </InputAdornment>,
                                        },
                                    }}
                                />
                            )
                        )}

                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            label="Status robe"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            required
                        >
                            <MenuItem value={0}>Ispravna roba</MenuItem>
                            <MenuItem value={1}>Rizična roba</MenuItem>
                            <MenuItem value={2}>Roba za povrat dobavljaču</MenuItem>
                        </TextField>

                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="barkod_lokacija"
                            name="barkod_lokacija"
                            label="Unesite QR lokacije"
                            placeholder="Unesite QR lokacije"
                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                            variant="outlined"
                            value={barkodLokacija}
                            onChange={(event) => setBarkodLokacija(event.target.value)}
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <QrCodeScannerTwoToneIcon/>
                                        </InputAdornment>,
                                },
                            }}
                        />

                        <Button
                            variant="contained"
                            sx={{mt: 2}}
                            fullWidth
                            type="submit"
                            endIcon={<MoveToInboxTwoToneIcon/>}
                        >
                            Potvrdi primanje robe
                        </Button>
                    </Box>
                </Paper>
            </Grid>

            <Grid size={{xs: 12}}>
                <Paper sx={{justifyContent: "center", alignItems: "center", height: 700}}>
                    {isLoading ? <LinearProgress size={24}/> : (
                        <PrimljeniProizvodiTable rows={data?.data}/>
                    )}
                </Paper>
            </Grid>

            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <Button
                            variant="contained"
                            fullWidth
                            type="button"
                            endIcon={<CheckCircleTwoToneIcon/>}
                            onClick={handleZavrsiPrijem}
                        >
                            Završi prijem robe
                        </Button>
                    </Typography>
                </Paper>
            </Grid>

            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
                <Alert severity="warning" onClose={() => setOpenSnackbar(false)}>
                    Nema rezultata za pretragu "{searchQuery}". Želite li dodati novi proizvod?
                    <Button onClick={handleOpenPopup} color="success" variant="contained" sx={{ml: 1, mr: 1}}><b>DA</b></Button>
                    <Button onClick={() => setOpenSnackbar(false)} color="error" variant="contained"
                            sx={{ml: 1, mr: 1}}><b>NE</b></Button>
                </Alert>
            </Snackbar>

            <Dialog open={openDialog} onClose={handleClosePopup} fullWidth maxWidth="sm">
                <DialogTitle>Dodaj novu robu</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Šifra"
                        value={sifra}
                        onChange={(e) => setSifra(e.target.value)}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Naziv"
                        value={naziv}
                        onChange={(e) => setNaziv(e.target.value)}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        select
                        label="Tip"
                        value={tip}
                        onChange={(e) => setTip(e.target.value)}
                        required
                    >
                        <MenuItem value={0}>Proizvod</MenuItem>
                        <MenuItem value={1}>Repromaterijal</MenuItem>
                    </TextField>

                    {/* Show these fields only if "Tip" is NOT "Proizvod" */}
                    {tip != null ? (tip != 0 && (
                        <>
                            <TextField
                                fullWidth
                                margin="dense"
                                select
                                label="Jedinica"
                                value={jedinica}
                                onChange={(e) => setJedinica(e.target.value)}
                                required
                            >
                                {jedinicaOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                margin="dense"
                                select
                                label="Opis"
                                value={opis}
                                onChange={(e) => setOpis(e.target.value)}
                                required
                            >
                                {opisOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </>
                    )) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePopup} color="error">Otkaži</Button>
                    <Button onClick={handleSaveProduct} color="success" variant="contained">Sačuvaj</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default NoviPrijemRobePage;