import axios from "axios";
import {url} from "./urls";

export const sastavnice = async () => {
    return axios.get(url + `/sastavnice`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const gotoviProizvodiKojiImajuVezu = async () => {
    return axios.get(url + `/gotovi-proizvodi-koji-imaju-vezu`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const sastavnica = async (id) => {
    return axios.get(url + `/sastavnica/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createSastavnica = async (formData) => {
    return axios.post(url + `/create-sastavnica`, formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const reproMaterijali = async () => {
    return axios.get(url + `/mubea-materijali`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const sastavnicaPrekoSifreGotovogProizvoda = async (sifra) => {
    return axios.get(url + `/sastavnica-preko-sifre-gotovog-proizvoda/${sifra}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};