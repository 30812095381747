import axios from "axios";
import { url } from "./urls";

export const getUsers = async () => {
    return axios.get(url + "/users", {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const getUserInfo = async (id) => {
    return axios.get(url + `/user-info/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const changeUserStatus = async (formData) => {
    return axios.post(url + "/change-status", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const updateUserPassword = async (formData) => {
    return axios.post(url + "/update-password", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createUser = async (formData) => {
    return axios.post(url + "/create-user", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

