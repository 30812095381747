import React from "react";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import Button from "@mui/material/Button";
import ArrowOutwardTwoToneIcon from "@mui/icons-material/ArrowOutwardTwoTone";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import {useNavigate} from "react-router";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import FormatColorFillTwoToneIcon from "@mui/icons-material/FormatColorFillTwoTone";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import PolylineTwoToneIcon from "@mui/icons-material/PolylineTwoTone";
import OutboxSharpIcon from "@mui/icons-material/OutboxSharp";

const mainListItems = [
    {
        text: "Dobavljači",
        icon: <LocalShippingIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/dobavljaci",
    },
    {
        text: "Kupci",
        icon: <ShoppingBagIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/kupci",
    },
    {
        text: "Radni centri",
        icon: <BusinessCenterTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/radni-centri",
    },
    {
        text: "Korisnici",
        icon: <PeopleAltTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/korisnici",
    },
    {
        text: "Skladišta",
        icon: <WarehouseTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/skladista",
    },
    {
        text: "Stanja skladišta",
        icon: <WarehouseTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/stanja_skladista",
    },
    {
        text: "Lokacije",
        icon: <LocationOnTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/lokacije",
    },
    {
        text: "Šifrarnici",
        icon: <VpnKeyTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/sifrarnici",
    },
    {
        text: "Veze izmedju sirovog i gotovog proizvoda",
        icon: <PolylineTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/veze-izmedju-sirovog-i-gotovog-proizvoda",
    },
    {
        text: "Sastavnice",
        icon: <ArticleTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/sastavnice",
    },
    {
        text: "Tovarni medijumi",
        icon: <ArchiveTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/tovarni-medijumi",
    },
    {
        text: "Mašine",
        icon: <HandymanTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/masine",
    },
    {
        text: "Repromaterijali",
        icon: <FormatColorFillTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/repromaterijali",
    },
    {
        text: "Radni nalozi",
        icon: <SummarizeTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/radni-nalozi",
    },
    {
        text: "Otpremnice",
        icon: <OutboxSharpIcon size={22} sx={{mr: 2}}/>,
        route: "../../administrator/otpremnice",
    },
];

const PocetnaPage = () => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AdminPanelSettingsTwoToneIcon size={22} sx={{mr: 2}}/>
                        Dobro došli na administratorski panel
                    </Typography>
                </Paper>
            </Grid>
            {mainListItems.map((item, index) => (
                <Grid size={{xs: 12, md: 6, lg: 6, xl: 6}}>
                    <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                        <Typography sx={{fontSize: 22, textAlign: "center"}}>
                            {item.icon}
                            {item.text}
                        </Typography>
                        <Button
                            endIcon={<ArrowOutwardTwoToneIcon/>}
                            sx={{mt: 2}}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                localStorage.setItem("index", index + 1);
                                navigate(item.route);
                            }}
                        >
                            Pristup
                        </Button>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default PocetnaPage;
