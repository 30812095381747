import axios from "axios";
import {url} from "./urls";

export const slajfanja = async (id_radnik) => {
    return axios.get(url + '/slajfanja/' + id_radnik, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const slajfanje = async (id) => {
    return axios.get(url + '/slajfanje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const startSlajfanje = async (formData) => {
    return axios.post(url + '/start-slajfanje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaSlajfanje = async (id) => {
    return axios.get(url + '/za-slajfanje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaKontrolu = async (id) => {
    return axios.get(url + '/za-kontrolu/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const odslajfujteProizvod = async (formData) => {
    return axios.post(url + '/odslajfujte-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const iskontrolisiteProizvod = async (formData) => {
    return axios.post(url + '/iskontrolisite-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const proslediteKontroloruSlajfanje = async (formData) => {
    return axios.post(url + '/prosledite-kontroloru-slajfanje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const finishSlajfanje = async (formData) => {
    return axios.post(url + '/end-slajfanje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const slajfanjaZaKontrolu = async () => {
    return axios.get(url + '/slajfanja-za-kontrolu', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
