import * as React from 'react';
import Stack from '@mui/material/Stack';
import {Box} from "@mui/material";
import Notifications from "./Notifications";

const Header = () => {
    return (
        <Stack
            direction="row"
            sx={{
                display: {xs: 'none', md: 'flex'},
                width: '100%',
                alignItems: {xs: 'flex-start', md: 'center'},
                justifyContent: 'right',
                maxWidth: {sm: '100%', md: '1700px'},
            }}
            spacing={2}
        >
            <Stack direction="row" sx={{
                gap: 1,
            }}>
                <Box sx={{mr: 5, pt: 2}}>
                    <Notifications/>
                </Box>
            </Stack>
        </Stack>
    );
}

export default Header;