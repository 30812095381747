import axios from "axios";
import {url} from "./urls";

export const podesavanja = async () => {
    return axios.get(url + '/podesavanja', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const updatePodesavanja = async (formData) => {
    return axios.post(url + '/update-podesavanja', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}