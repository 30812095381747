import React from "react";
import { Navigate, Outlet } from "react-router";
import AdminLayout from "../layouts/AdminLayout";

const AdminProtectedRoute = () => {
  let role_id = localStorage.getItem("role_id");

  if (role_id != 1) {
    return <Navigate to="/" />;
  }

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default AdminProtectedRoute;
