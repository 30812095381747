import React from 'react';
import {Navigate, Outlet} from "react-router";
import PIPLayout from "../layouts/PIPLayout";

const PipProtectedRoute = () => {
    let role_id = localStorage.getItem("role_id");

    if (role_id != 7) {
        return <Navigate to="/"/>;
    }

    return (
        <PIPLayout>
            <Outlet/>
        </PIPLayout>
    );
};

export default PipProtectedRoute;