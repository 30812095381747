import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { kupac, updateKupac } from "../../../apis/kupciApi";
import {
    Alert,
    Button,
    Divider,
    LinearProgress,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";

export const KupacPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [kupacData, setKupacData] = useState({});

    const { data, isLoading, isError } = useQuery({
        queryKey: ["kupac", id],
        queryFn: () => kupac(id),
    });

    useEffect(() => {
        if (data?.data) {
            setKupacData(data.data);
        }
    }, [data]);

    const mutation = useMutation({
        mutationFn: updateKupac,
        onSuccess: () => navigate("../kupci"),
    });

    const handleChange = (e) => {
        setKupacData({ ...kupacData, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        mutation.mutate(kupacData);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, textAlign: "center" }}>
                    <Typography sx={{ fontSize: 22 }}>
                        <PersonIcon sx={{ mr: 2 }} />
                        Informacije o kupcu
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podataka o kupcu!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress />
                    ) : (
                        <Stack spacing={2}>
                            <TextField
                                label="Naziv"
                                fullWidth
                                name="naziv"
                                value={kupacData.naziv || ""}
                                onChange={handleChange}
                            />
                            <Divider />
                            <TextField
                                label="Adresa"
                                fullWidth
                                name="adresa"
                                value={kupacData.adresa || ""}
                                onChange={handleChange}
                            />
                            <Divider />
                            <TextField
                                label="Telefon"
                                fullWidth
                                name="telefon"
                                value={kupacData.telefon || ""}
                                onChange={handleChange}
                            />
                            <Divider />
                            <TextField
                                label="Email"
                                fullWidth
                                name="email"
                                value={kupacData.email || ""}
                                onChange={handleChange}
                            />
                            <Divider />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={mutation.isLoading}
                            >
                                Sačuvaj
                            </Button>
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};