import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {potrebnoTrebovati, trebujDodatniProizvod, trebujProizvod, zavrsiTrebovanje} from "../../../apis/trebovanjeApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {
    Alert,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    LinearProgress
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import PolicyIcon from "@mui/icons-material/Policy";
import Typography from "@mui/material/Typography";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import TextField from "@mui/material/TextField";
import MoveToInboxTwoToneIcon from "@mui/icons-material/MoveToInboxTwoTone";
import Notification from "../../../components/Notification";
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';

const NovoTrebovanjePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open1, setOpen1] = React.useState(false);

    const handleClose1 = () => {
        setOpen1(false);
    };

    const [open2, setOpen2] = React.useState(false);

    const handleClose2 = () => {
        setOpen2(false);
    };

    const [dodatnoTrebovanje, setDodatnoTrebovanje] = useState(false);

    const [serijskiBroj, setSerijskiBroj] = useState("");
    const [dodatniSerijskiBroj, setDodatniSerijskiBroj] = useState("");
    const [postoji, setPostoji] = useState(false);

    const {
        data: podaci,
        isLoading: loadingPodaci,
        error: errorPodaci
    } = useQuery({
        queryKey: ['potrebnoTrebovati', id],
        queryFn: () => potrebnoTrebovati(id)
    });

    const mutation = useMutation({
        mutationFn: (formData) => trebujProizvod(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSerijskiBroj("");

            queryClient.invalidateQueries(['potrebnoTrebovati']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationDodatniProizvodi = useMutation({
        mutationFn: (formData) => trebujDodatniProizvod(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['potrebnoTrebovati', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationZavrsiTrebovanje = useMutation({
        mutationFn: (formData) => zavrsiTrebovanje(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            handleClose1();

            setTimeout(() => {
                navigate('../trebovanja');
            }, 2000);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const columns_repromaterijali = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "opis",
            headerName: "Opis",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        }
    ];
    const columns_potrebno_trebovati = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_proizvoda",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "id_lokacija",
            headerName: "Lokacija",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../lokacija/" + params.row.id_lokacije)}
                    >
                        Lokacija
                    </Button>
                );
            },
        },
    ];
    const columns_trebovano = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const checkSerijskiBroj = (value) => {
        setPostoji(podaci?.data?.potrebno_trebovati?.some(item => item.serijski_broj === value) || false);
    }

    const handlePotvrdiTrebovanje = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (!postoji) {
            setType(2);
            setMessage("Ovaj serijski broj mora da bude u tabeli potrebno trebovati!");
            setOpenN(true);
            return;
        }

        if (serijskiBroj == "") {
            setType(2);
            setMessage("Morate uneti serijski broj!");
            setOpenN(true);
            return;
        }

        formData.append('id_trebovanje', id);
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('serijski_broj', serijskiBroj);

        mutation.mutateAsync(formData).catch((err) => {
        });
    }

    const handleZavrsiTrebovanje = (event) => {
        event.preventDefault();
        if (podaci?.data.potrebno_trebovati.length > 0) {
            setOpen2()
            return;
        }

        if (podaci?.data.potrebno_trebovati.length == 0) {
            setOpen1(true);
            return;
        }

        handleZavrsiSkroz(event);
    }

    const handleZavrsiSkroz = (event) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append('id_trebovanje', id);

        mutationZavrsiTrebovanje.mutateAsync(formData).catch((err) => {
        });
    }

    const handlePotvrdiDodatnoTrebovanje = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (dodatniSerijskiBroj == "") {
            setType(2);
            setMessage("Morate uneti dodatni serijski broj!");
            setOpenN(true);
            return;
        }

        formData.append('id_trebovanje', id);
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('serijski_broj', dodatniSerijskiBroj);

        mutationDodatniProizvodi.mutateAsync(formData).catch((err) => {
        });
    }

    return (
        <Grid container spacing={1}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Repromaterijali
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja repromaterijala!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_repromaterijali}
                            rows={loadingPodaci ? [] : podaci?.data.repromaterijali}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Potrebno trebovati
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Trebovano
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koje treba trebovati!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_potrebno_trebovati}
                            rows={loadingPodaci ? [] : podaci?.data.potrebno_trebovati}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su trebovani!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_trebovano}
                            rows={loadingPodaci ? [] : podaci?.data.trebovano}
                        />
                    )}
                </Paper>
            </Grid>
            {loadingPodaci ? null : podaci?.data.potrebno_trebovati.length == 0 ? (
                <Grid size={{xs: 12}}>
                    <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                        <Typography sx={{fontSize: 22, textAlign: "center"}}>
                            <QuestionMarkTwoToneIcon size={22} sx={{mr: 2}}/>
                            Da li želite da trebujete još neki proizvod
                            <QuestionMarkTwoToneIcon size={22} sx={{ml: 2}}/>
                        </Typography>
                        <ButtonGroup fullWidth sx={{ml: 1}} variant="contained"
                                     aria-label="Da li želite da unesete još neki proizvod?">
                            <Button onClick={() => setDodatnoTrebovanje(true)} color="success"><b>Da, Želim da
                                trebujem još neki proizvod</b></Button>
                            <Button onClick={(event) => {
                                handleZavrsiTrebovanje(event);
                                setDodatnoTrebovanje(false);
                            }} color="error"><b>Ne, završi
                                trebovanje</b></Button>
                        </ButtonGroup>
                    </Paper>
                </Grid>
            ) : (
                <Grid size={{xs: 12}}>
                    <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="serijski_broj"
                            name="serijski_broj"
                            label="Unesite serijski broj"
                            placeholder="Unesite serijski broj"
                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                            variant="outlined"
                            value={serijskiBroj}
                            onChange={(event) => {
                                setSerijskiBroj(event.target.value);
                                checkSerijskiBroj(event.target.value);
                            }}
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <QrCodeScannerTwoToneIcon/>
                                        </InputAdornment>,
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{mt: 2}}
                            fullWidth
                            type="button"
                            endIcon={<MoveToInboxTwoToneIcon/>}
                            disabled={!postoji}
                            onClick={handlePotvrdiTrebovanje}
                        >
                            Potvrdi trebovanje
                        </Button>
                    </Paper>
                </Grid>
            )}
            {dodatnoTrebovanje ? (
                <Grid size={{xs: 12}}>
                    <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="serijski_broj"
                            name="serijski_broj"
                            label="Unesite dodatni serijski broj"
                            placeholder="Unesite dodatni serijski broj"
                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                            variant="outlined"
                            value={dodatniSerijskiBroj}
                            onChange={(event) => {
                                setDodatniSerijskiBroj(event.target.value);
                            }}
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <QrCodeScannerTwoToneIcon/>
                                        </InputAdornment>,
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{mt: 2}}
                            fullWidth
                            type="button"
                            endIcon={<MoveToInboxTwoToneIcon/>}
                            onClick={handlePotvrdiDodatnoTrebovanje}
                        >
                            Potvrdi dodatno trebovanje
                        </Button>
                    </Paper>
                </Grid>
            ) : null}
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        variant="contained"
                        fullWidth
                        type="button"
                        endIcon={<AssignmentTurnedInTwoToneIcon/>}
                        onClick={handleZavrsiTrebovanje}
                    >
                        Završite trebovanje
                    </Button>
                </Paper>
            </Grid>
            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Da li ste sigurni da hoćete da završite proces trebovanja?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ako niste sigurni da li želite da završite proces trebovanja slobodno izadjite iz programa, uvek
                        možete nastaviti kasnije!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose1}>Neću da završim</Button>
                    <Button variant="contained" color="success" onClick={handleZavrsiSkroz} autoFocus>
                        Hoću da završim
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Da li ste sigurni da hoćete da završite proces trebovanja sa manjim brojem trebovanih proizvoda?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ako ste sigurni, pritisnite dugme hoću da završim. Naravno ovo nije preporučljivo ali ako {" "}
                        je nužno onda slobodno nastavite.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose2}>Neću da završim</Button>
                    <Button variant="contained" color="success" onClick={handleZavrsiSkroz} autoFocus>
                        Hoću da završim
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default NovoTrebovanjePage;