import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import {useNavigate} from "react-router";
import FormatColorFillTwoToneIcon from "@mui/icons-material/FormatColorFillTwoTone";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StorageIcon from '@mui/icons-material/Storage';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import PolylineTwoToneIcon from '@mui/icons-material/PolylineTwoTone';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppTwoTone';
import IosShareTwoToneIcon from '@mui/icons-material/IosShareTwoTone';
import OutboxSharpIcon from '@mui/icons-material/OutboxSharp';

let mainListItems = [];

export default function MenuContent() {
    let selectedIndex = localStorage.getItem("index");
    const role_id = localStorage.getItem("role_id");
    const navigate = useNavigate();

    if (role_id == 1) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../administrator/pocetna",
            },
            {
                text: "Dobavljači",
                icon: <LocalShippingIcon/>,
                route: "../administrator/dobavljaci",
            },
            {
                text: "Kupci",
                icon: <ShoppingBagIcon/>,
                route: "../administrator/kupci",
            },
            {
                text: "Radni centri",
                icon: <BusinessCenterTwoToneIcon/>,
                route: "../administrator/radni-centri",
            },
            {
                text: "Korisnici",
                icon: <PeopleAltTwoToneIcon/>,
                route: "../administrator/korisnici",
            },
            {
                text: "Skladišta",
                icon: <WarehouseTwoToneIcon/>,
                route: "../administrator/skladista",
            },
            {
                text: "Stanja skladišta",
                icon: <WarehouseTwoToneIcon/>,
                route: "../administrator/stanja_skladista",
            },
            {
                text: "Lokacije",
                icon: <LocationOnTwoToneIcon/>,
                route: "../administrator/lokacije",
            },

            {
                text: "Šifrarnici",
                icon: <VpnKeyTwoToneIcon/>,
                route: "../administrator/sifrarnici",
            },
            {
                text: "Veze izmedju sirovog i gotovog proizvoda",
                icon: <PolylineTwoToneIcon/>,
                route: "../administrator/veze-izmedju-sirovog-i-gotovog-proizvoda",
            },
            {
                text: "Sastavnice",
                icon: <ArticleTwoToneIcon/>,
                route: "../administrator/sastavnice",
            },
            {
                text: "Tovarni medijumi",
                icon: <ArchiveTwoToneIcon/>,
                route: "../administrator/tovarni-medijumi",
            },
            {
                text: "Mašine",
                icon: <HandymanTwoToneIcon/>,
                route: "../administrator/masine",
            },
            {
                text: "Repromaterijali",
                icon: <FormatColorFillTwoToneIcon/>,
                route: "../administrator/repromaterijali",
            },
            {
                text: "Radni nalozi",
                icon: <SummarizeTwoToneIcon/>,
                route: "../administrator/radni-nalozi",
            },
            {
                text: "Otpremnice",
                icon: <OutboxSharpIcon/>,
                route: "../administrator/otpremnice",
            },
        ];
    } else if (role_id == 2) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../slajfanje/pocetna",
            },
            {
                text: "Radni nalozi",
                icon: <ArticleTwoToneIcon/>,
                route: "../slajfanje/radni-nalozi",
            },
        ];
    } else if (role_id == 3) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../lakiranje/pocetna",
            },
            {
                text: "Radni nalozi",
                icon: <ArticleTwoToneIcon/>,
                route: "../lakiranje/radni-nalozi",
            },
        ];
    } else if (role_id == 4) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../poliranje/pocetna",
            },
            {
                text: "Radni nalozi",
                icon: <ArticleTwoToneIcon/>,
                route: "../poliranje/radni-nalozi",
            },
        ];
    } else if (role_id == 5) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../kontrola/pocetna",
            },
            {
                text: "Prijemi robe",
                icon: <CallReceivedIcon/>,
                route: "../kontrola/prijemi-robe",
            },
            {
                text: "Stanje skladišta",
                icon: <StorageIcon/>,
                route: "../kontrola/stanje-skladišta",
            },
            {
                text: "Provera serijskog broja",
                icon: <QrCodeScannerIcon/>,
                route: "../kontrola/provera-serijskog-broja",
            },
            {
                text: "Radni nalozi",
                icon: <SummarizeTwoToneIcon/>,
                route: "../kontrola/radni-nalozi",
            },
        ];
    } else if (role_id == 6) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../skladistar/pocetna",
            },
            {
                text: "Trebovanje",
                icon: <GetAppTwoToneIcon/>,
                route: "../skladistar/trebovanja",
            },
            {
                text: "Lansiranje naloga",
                icon: <RocketLaunchTwoToneIcon/>,
                route: "../skladistar/lansiranja",
            },
            {
                text: "Stanja skladišta",
                icon: <StorageTwoToneIcon/>,
                route: "../skladistar/stanja_skladista",
            },
            {
                text: "Otpremnice",
                icon: <IosShareTwoToneIcon/>,
                route: "../skladistar/otpremnice",
            },
        ];
    } else if (role_id == 7) {
        mainListItems = [
            {
                text: "Početna",
                icon: <HomeRoundedIcon/>,
                route: "../plazma-i-pranje/pocetna",
            },
            {
                text: "Radni nalozi",
                icon: <ArticleTwoToneIcon/>,
                route: "../plazma-i-pranje/radni-nalozi",
            },
        ];
    } else {
        mainListItems = [];
    }

    return (
        <Stack sx={{flexGrow: 1, p: 1, justifyContent: "space-between"}}>
            <List dense>
                {mainListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: "block"}}>
                        <ListItemButton
                            onClick={() => {
                                localStorage.setItem("index", index);
                                navigate(item.route);
                            }}
                            selected={index == selectedIndex}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}
