import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import Notification from "../../../components/Notification";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, LinearProgress} from "@mui/material";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PolicyIcon from "@mui/icons-material/Policy";
import Typography from "@mui/material/Typography";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {dodajteKutije, otpremnica, proveriKutiju, zavrsiOtpremnicu} from "../../../apis/otpremnicaApi";
import {tableLang} from "../../../language/tableLang";
import {DataGrid} from "@mui/x-data-grid";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

const NovaOtpremnicaPage = () => {
    const {id} = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const [sifraKutije, setSifraKutije] = useState("");

    const [kutija, setKutija] = useState(null);
    const [proizvodi, setProizvodi] = useState([]);

    const {data, isLoading, isError} = useQuery({
        queryKey: ['otpremnica', id],
        queryFn: () => otpremnica(id)
    });

    const mutationProveriKutiju = useMutation({
        mutationFn: (formData) => proveriKutiju(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            handleClickOpen();

            setKutija(response.data.kutija);
            setProizvodi(response.data.proizvodi);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationDodajKutiju = useMutation({
        mutationFn: (formData) => dodajteKutije(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSifraKutije("");
            setKutija(null);
            setProizvodi([]);

            queryClient.invalidateQueries(['otpremnica', id]).then((res) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationZavrsiOtpremnicu = useMutation({
        mutationFn: (formData) => zavrsiOtpremnicu(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setTimeout(() => {
                navigate('../otpremnice');
            }, 2000);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const handleDodajKutiju = (event) => {
        event.preventDefault();

        if (sifraKutije == "") {
            setType(2);
            setMessage("Morate uneti šifru kutije!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append("id_otpremnice", id);
        formData.append('sifra_kutije', sifraKutije);

        mutationDodajKutiju.mutateAsync(formData).catch((error) => {
        });
    }

    const handleProveriKutiju = (event) => {
        event.preventDefault();

        if (sifraKutije == "") {
            setType(2);
            setMessage("Morate uneti šifru kutije!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('sifra_kutije', sifraKutije);

        mutationProveriKutiju.mutateAsync(formData).catch((err) => {
        });
    }

    const handleZavrsiOtpremnicu = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("id_otpremnice", id);

        mutationZavrsiOtpremnicu.mutateAsync(formData).catch((error) => {
        });
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <TextField
                        margin="normal"
                        fullWidth
                        required
                        id="sifra_kutije"
                        name="sifra_kutije"
                        label="Unesite šifru kutije"
                        placeholder="Unesite šifru kutije"
                        helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                        variant="outlined"
                        value={sifraKutije}
                        onChange={(event) => setSifraKutije(event.target.value)}
                        slotProps={{
                            input: {
                                endAdornment:
                                    <InputAdornment position="end">
                                        <QrCodeScannerTwoToneIcon/>
                                    </InputAdornment>,
                            },
                        }}
                    />
                    <Button
                        endIcon={<SearchTwoToneIcon/>}
                        fullWidth
                        variant="contained"
                        onClick={handleProveriKutiju}
                    >
                        Proveri kutiju
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Dodati proizvodi
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 0.5, justifyContent: "center", alignItems: "center", height: 400}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data.proizvodi}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        endIcon={<CheckCircleSharpIcon/>}
                        fullWidth
                        variant="contained"
                        onClick={handleZavrsiOtpremnicu}
                    >
                        Završi otpremnicu
                    </Button>
                </Paper>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Informacije o kutiji</DialogTitle>
                <DialogContent>
                    <DataGrid
                        localeText={tableLang}
                        columns={columns}
                        rows={proizvodi}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">Odustani</Button>
                    <Button onClick={handleDodajKutiju} variant="contained" color="success">
                        Dodaj kutiju u otpremnicu
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default NovaOtpremnicaPage;