import React from "react";
import mes from "../../assets/mes.png";
import logo from "../../assets/logo.png";
import QRCode from "react-qr-code";

const NalepnicaKorisnik = ({ ime, prezime, email, barkod }) => {
    return (
        <div
            style={{
                backgroundColor: "white",
                display: "grid",
                gridTemplateColumns: "auto auto",
                width: "4in",
                height: "2in",
                padding: "5px",
                border: "1px solid black",
            }}
        >
            <div>
                <img src={mes} height={25} alt="MES Logo" />
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <img src={logo} height={25} alt="Company Logo" />
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <QRCode
                    size="0.6in"
                    style={{
                        height: "auto",
                        maxWidth: "65%",
                        width: "65%",
                    }}
                    value={barkod.toString()}
                />
            </div>

            <div style={{ justifyContent: "center", textAlign: "center" }}>
                <p style={{ fontSize: 16, color: "black", margin: 1, padding: 1 }}>
                    {ime} {prezime}
                </p>
                <p style={{ fontSize: 14, color: "black", margin: 1, padding: 1 }}>
                    {email}
                </p>
            </div>

        </div>
    );
};

export default NalepnicaKorisnik;
