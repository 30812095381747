import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
    Container,
    Paper,
    Typography,
    Button,
    CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PeopleIcon from "@mui/icons-material/People";
import { getUsers } from "../../../apis/korisniciApi";
import Notification from "../../../components/Notification";
import { tableLang } from "../../../language/tableLang";

const KorisniciPage = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const { data, isLoading, isError } = useQuery({
        queryKey: ["korisnici"],
        queryFn: getUsers,
    });

    const columns = [
        { field: "id", headerName: "ID", width: 90, headerAlign: "center", align: "center" },
        { field: "ime", headerName: "Ime", width: 150, flex: 1, headerAlign: "center", align: "center" },
        { field: "prezime", headerName: "Prezime", width: 150, flex: 1, headerAlign: "center", align: "center" },
        { field: "email", headerName: "Email", width: 200, flex: 1, headerAlign: "center", align: "center" },
        { field: "korisnicko_ime", headerName: "Korisničko Ime", width: 180, flex: 1, headerAlign: "center", align: "center" },
        { field: "barkod", headerName: "Barkod", width: 150, flex: 1, headerAlign: "center", align: "center" },
        { field: "status", headerName: "Status", width: 130, flex: 1, headerAlign: "center", align: "center" },
        { field: "radna_pozicija", headerName: "Radna Pozicija", width: 180, flex: 1, headerAlign: "center", align: "center" },
        { field: "vreme_kreiranja", headerName: "Vreme Kreiranja", width: 180, flex: 1, headerAlign: "center", align: "center" },
        {
            field: "actions",
            headerName: "Akcije",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`../user-info/${params.row.id}`)}
                >
                    Pregled
                </Button>
            ),
        },
    ];

    return (
        <Container>
            <Notification type={type} message={message} open={openN} setOpen={setOpenN} />
            <Paper sx={{ p: 3, mt: 2, textAlign: "center" }}>
                <Typography variant="h4" gutterBottom>
                    <PeopleIcon sx={{ mr: 2 }} /> Korisnici
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("../korisnik/create")}
                    sx={{ mb: 2 }}
                    startIcon={<PeopleIcon />}
                >
                    Novi Korisnik
                </Button>
                <div style={{ height: 400, width: "100%" }}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : isError ? (
                        <Typography color="error">Greška pri učitavanju podataka</Typography>
                    ) : (
                        <DataGrid
                            rows={data?.data || []}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            localeText={tableLang}
                        />
                    )}
                </div>
            </Paper>
        </Container>
    );
};

export default KorisniciPage;
