import axios from "axios";
import {url} from "./urls";

export const adminRadniNalozi = async () => {
    return axios.get(url + `/radni-nalozi-admin`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const adminRadniNalog = async (id) => {
    return axios.get(url + `/radni-nalog-admin/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createAdminRadniNalog = async (formData) => {
    return axios.post(url + '/create-radni-nalog-admin', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const adminRadniNaloziKojiSuOtvoreni = async () => {
    return axios.get(url + `/radni-nalozi-koji-su-otvoreni`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const adminRadniNaloziKojiSuTrebovani = async () => {
    return axios.get(url + `/radni-nalozi-koji-su-trebovani`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};
