import axios from "axios";
import {url} from "./urls";

export const lansiranja = async () => {
    return axios.get(url + '/lansiranja', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const lansiranje = async (id) => {
    return axios.get(url + '/lansiranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const createLansiranje = async (formData) => {
    return axios.post(url + '/create-lansiranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const potrebnoLansirati = async (id) => {
    return axios.get(url + '/potrebno-lansirati/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const formirajGajbicaRadnikVezu = async (formData) => {
    return axios.post(url + '/formiraj-gajbica-radnik-vezu', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const lansirajProizvod = async (formData) => {
    return axios.post(url + '/lansiraj-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zavrsiLansiranje = async (formData) => {
    return axios.post(url + '/finish-lansiranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}