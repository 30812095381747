import React, {useState} from 'react';
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import {createMubeaMaterijal, mubeaMaterijali} from "../../../apis/repromaterijaliApi";

const jedinicaOptions = ["STK", "L", "ROLLE", "PAK"];
const opisOptions = [
    "Reinigungswasser", "Politure", "Schleifpapier", "Mikrofasertücher",
    "Klarlack", "Härter", "Verdünner", "Primer",
    "Schleifteller", "Schleifmittel", "Schleifmaschine",
    "Waschmittel", "Folie"
];

const FormDialog = ({open, onClose, onSubmit}) => {
    const [formData, setFormData] = useState({
        naziv: "",
        sifra: "",
        opis: "",
        jedinica: "",
        komentar: "",
        napomena: ""
    });

    const handleChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    };

    const handleSubmit = () => {
        onSubmit(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Unos novog proizvoda (gotov ili sirov)</DialogTitle>
            <DialogContent>
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Naziv"
                    name="naziv"
                    value={formData.naziv}
                    onChange={handleChange}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Šifra"
                    name="sifra"
                    value={formData.sifra}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    select
                    label="Jedinica"
                    name="jedinica"
                    value={formData.jedinica}
                    onChange={handleChange}
                    required
                >
                    {jedinicaOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    fullWidth
                    margin="dense"
                    select
                    label="Opis"
                    name="opis"
                    value={formData.opis}
                    onChange={handleChange}
                    required
                >
                    {opisOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    fullWidth
                    margin="dense"
                    label="Komentar"
                    name="komentar"
                    value={formData.komentar}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    label="Napomena"
                    name="napomena"
                    value={formData.napomena}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const RepromaterijaliPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["mubeaMaterijali"],
        queryFn: mubeaMaterijali,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "opis",
            headerName: "Opis",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()} / {(date.getMonth() + 1)} / {date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../repromaterijal/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = (data) => {
        if (data.naziv == "") {
            setType(2);
            setMessage("Morate uneti naziv proizvoda!");
            setOpenN(true);
            return;
        }

        if (data.sifra == "") {
            setType(2);
            setMessage("Morate uneti šifru proizvoda!");
            setOpenN(true);
            return;
        }

        if (data.jedinica == null) {
            setType(2);
            setMessage("Morate izabrati jedinicu proizvoda!");
            setOpenN(true);
            return;
        }

        if (data.opis == null) {
            setType(2);
            setMessage("Morate izabrati opis proizvoda!");
            setOpenN(true);
            return;
        }

        mutation.mutateAsync(data).then(() => {
            data.naziv = "";
            data.sifra = "";
            data.opis = "";
            data.jedinica = "";
            data.komentar = "";
            data.napomena = "";
        }).catch((e) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createMubeaMaterijal(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['mubeaMaterijali']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Svi repromaterijali
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Novi repromaterijal</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja repromaterijala!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog open={open} onClose={() => setOpen(false)} onSubmit={handleSubmit}/>
        </Grid>
    );
};

export default RepromaterijaliPage;