import axios from "axios";
import {url} from "./urls";

export const getKupci = async () => {
    return axios.get(url + `/kupci`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const kupac = async (id) => {
    return axios.get(url + `/kupac/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const createKupac = async (formData) => {
    return axios.post(url + '/create-kupac', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const updateKupac = async (formData) => {
    return axios.post(url + '/update-kupac', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
