import React from 'react';
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getRadnaPozicijaById } from "../../../apis/radniCentriApi";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import WorkIcon from "@mui/icons-material/Work";
import { Alert, LinearProgress, Card, CardContent } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const RadnaPozicijaPage = () => {
    const { id } = useParams();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['radnaPozicija', id],
        queryFn: () => getRadnaPozicijaById(id)
    });

    return (
        <Grid container spacing={3} justifyContent="center" sx={{ p: 3, bgcolor: "#121212", color: "#fff" }}>
            <Grid item xs={12} md={8}>
                <Paper sx={{ p: 3, backgroundColor: "#1e1e1e", borderRadius: 2, boxShadow: 3 }}>
                    <Typography variant="h5" sx={{ textAlign: "center", display: "flex", alignItems: "center", gap: 1 }}>
                        <WorkIcon /> Informacije o radnoj poziciji
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} md={8}>
                <Paper sx={{ p: 3, backgroundColor: "#1e1e1e", borderRadius: 2, boxShadow: 3 }}>
                    {isError ? (
                        <Alert severity="error">Greška prilikom preuzimanja podataka o radnoj poziciji!</Alert>
                    ) : isLoading ? (
                        <LinearProgress color="secondary" />
                    ) : (
                        <Stack spacing={3}>
                            <Divider sx={{ bgcolor: "#444" }} />
                            <Typography variant="h6">ID: <b>{data?.data.radna_pozicija.id}</b></Typography>
                            <Typography variant="h6">Naziv: <b>{data?.data.radna_pozicija.naziv}</b></Typography>
                            <Divider sx={{ bgcolor: "#444" }} />

                            {data?.data.korisnici?.length > 0 && (
                                <>
                                    <Typography variant="h6">Korisnici</Typography>
                                    <Stack spacing={2}>
                                        {data?.data.korisnici.map((korisnik, index) => (
                                            <Card key={index} sx={{ backgroundColor: "#2a2a2a", color: "#fff" }}>
                                                <CardContent>
                                                    <Typography variant="body1">Ime: <b>{korisnik.ime}</b></Typography>
                                                    <Typography variant="body1">Prezime: <b>{korisnik.prezime}</b></Typography>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Stack>
                                    <Divider sx={{ bgcolor: "#444" }} />
                                </>
                            )}

                            {data?.data.masine?.length > 0 && (
                                <>
                                    <Typography variant="h6">Mašine</Typography>
                                    <Stack spacing={2}>
                                        {data?.data.masine.map((masina, index) => (
                                            <Card key={index} sx={{ backgroundColor: "#2a2a2a", color: "#fff" }}>
                                                <CardContent>
                                                    <Typography variant="body1">Naziv: <b>{masina.naziv}</b></Typography>
                                                    <Typography variant="body1">Serijski Broj: <b>{masina.serijski_broj}</b></Typography>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Stack>
                                    <Divider sx={{ bgcolor: "#444" }} />
                                </>
                            )}
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default RadnaPozicijaPage;
