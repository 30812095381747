import React from 'react';
import {useNavigate, useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, Chip, CircularProgress, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";
import {trebovanje} from "../../../apis/trebovanjeApi";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Button from "@mui/material/Button";

const TrebovanjePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['trebovanje', id],
        queryFn: () => trebovanje(id)
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o trebovanju
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o trebovanju!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.trebovanje.id}</b></Item>
                            <Divider/>
                            <Item>Ime i prezime
                                kreatora: <b>{data?.data.trebovanje.ime} {data?.data.trebovanje.prezime}</b></Item>
                            <Divider/>
                            <Item>Status: <b>{data?.data.trebovanje.status}</b></Item>
                            <Divider/>
                            <Item>Vreme početka: <b>{data?.data.trebovanje.vreme_pocetak}</b></Item>
                            <Divider/>
                            <Item>Vreme završetka: <b>{data?.data.trebovanje.vreme_kraj}</b></Item>
                            <Divider/>
                            <Button
                                variant="contained"
                                sx={{width: "100%", alignSelf: "center"}}
                                endIcon={<PrivacyTipTwoToneIcon/>}
                                onClick={() => navigate("../radni-nalog/" + data?.data.trebovanje.id_radni_nalog)}
                            >
                                Radni nalog
                            </Button>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{textAlign: 'center'}}>Broj trebovanih proizvoda(serijskih brojeva): {isLoading ?
                        <CircularProgress size={22}/> : <b>{data?.data.broj_trebovane_robe} komad/a</b>}</Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{textAlign: 'center'}}>Trajanje trebovanja: {isLoading ?
                        <CircularProgress size={22}/> : <b>{data?.data.trajanje}</b>}</Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o trebovanim proizvodima!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider>
                                <Chip label="Svi proizvodi u ovom radnom nalogu" size="small"/>
                            </Divider>
                            {data?.data.trebovana_roba.map((item) => {
                                return (
                                    <>
                                        <Item>Trebovan
                                            proizvod: <b>{item.ime} {item.prezime} | {item.serijski_broj} | {item.sifra} | {item.naziv} | {item.kolicina} {item.jedinica} | {item.vreme_kreiranja}</b></Item>
                                        <Divider/>
                                    </>
                                );
                            })}
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default TrebovanjePage;