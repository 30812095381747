import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { createUser } from "../../../apis/korisniciApi";
import {
    Container,
    Paper,
    Typography,
    Button,
    TextField,
    Alert,
} from "@mui/material";

const validationSchema = yup.object({
    ime: yup.string().required("Ime je obavezno"),
    prezime: yup.string().required("Prezime je obavezno"),
    email: yup.string().email("Neispravan email format").required("Email je obavezan"),
    korisnicko_ime: yup.string().required("Korisničko ime je obavezno"),
    sifra: yup.string().min(8, "Šifra mora imati najmanje 8 karaktera").required("Šifra je obavezna"),
    potvrda_sifre: yup.string()
        .oneOf([yup.ref("sifra"), null], "Šifre se moraju poklapati")
        .required("Potvrda šifre je obavezna"),
    barkod: yup.string().required("Barkod je obavezan"),
    status: yup.string().required("Status je obavezan"),
    proizvodnja: yup.string().required("Proizvodnja je obavezna"),
    radna_pozicija: yup.string().required("Radna pozicija je obavezna"),
});

export const KreirajKorisnikPage = () => {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            ime: "",
            prezime: "",
            email: "",
            korisnicko_ime: "",
            sifra: "",
            potvrda_sifre: "",
            barkod: "",
            status: "",
            proizvodnja: "",
            radna_pozicija: "",
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                await createUser(values);
                navigate("../korisnici");
            } catch (error) {
                if (error.response && error.response.data.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    setErrors({ general: "Greška pri kreiranju korisnika." });
                }
            }
            setSubmitting(false);
        },
    });

    return (
        <Container>
            <Paper sx={{ p: 3, mt: 2 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>Novi Korisnik</Typography>
                {formik.errors.general && <Alert severity="error">{formik.errors.general}</Alert>}
                <form onSubmit={formik.handleSubmit}>
                    {Object.keys(formik.initialValues).map((field) => (
                        <TextField
                            key={field}
                            label={field.replace(/_/g, " ")}
                            fullWidth
                            type={field.includes("sifra") ? "password" : "text"}
                            {...formik.getFieldProps(field)}
                            sx={{ mb: 2 }}
                            error={formik.touched[field] && Boolean(formik.errors[field])}
                            helperText={formik.touched[field] && formik.errors[field]}
                        />
                    ))}
                    <Button type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
                        Kreiraj
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};
