import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {sastavnica} from "../../../apis/sastavnicaApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';

const SastavnicaPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['sastavnica', id],
        queryFn: () => sastavnica(id),
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o sastavnici
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o sastavnici!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.sastavnica.id}</b></Item>
                            <Divider/>
                            <Item>Šifra: <b>{data?.data.sastavnica.sifra}</b></Item>
                            <Divider/>
                            <Item>Naziv: <b>{data?.data.sastavnica.naziv}</b></Item>
                            <Divider/>
                            <Item>Ime i
                                prezime: <b>{data?.data.sastavnica.ime} {data?.data.sastavnica.prezime}</b></Item>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.sastavnica.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 20, textAlign: "center"}}>
                        <ExtensionTwoToneIcon size={12} sx={{mr: 2}}/>
                        Sastojci
                    </Typography>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o sastavnici!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            {data?.data.sastojci.map((item, index) => (
                                <>
                                    <Divider/>
                                    <Item><b>{item.naziv} | {item.sifra} | {item.tip} | {item.kolicina} {item.jedinica} | {item.vreme_kreiranja}</b></Item>
                                </>
                            ))}
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SastavnicaPage;