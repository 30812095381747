import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import Notification from "../../components/Notification";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {odslajfujteProizvod, proslediteKontroloruSlajfanje, zaSlajfanje} from "../../apis/slajfanjeApi";
import {Alert, Box, InputAdornment, LinearProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../language/tableLang";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import SendToMobileTwoToneIcon from '@mui/icons-material/SendToMobileTwoTone';

const SlajfanjeProcesPage = () => {
    const {id} = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);
    const [postoji, setPostoji] = useState(false);

    const [serijskiBroj, setSerijskiBroj] = useState("");

    const columns_za_slajfanje = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 70,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_odslajfano = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stanje",
            headerName: "Stanje",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const {data, isLoading, isError} = useQuery({
        queryKey: ['zaSlajfanje', id],
        queryFn: () => zaSlajfanje(id)
    });

    const mutationOdslajfuj = useMutation({
        mutationFn: (formData) => odslajfujteProizvod(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSerijskiBroj("");

            queryClient.invalidateQueries(['zaSlajfanje', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationProslediKontroloru = useMutation({
        mutationFn: (formData) => proslediteKontroloruSlajfanje(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setTimeout(() => {
                navigate('../radni-nalozi');
            }, 2000);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const handleOdslajfuj = (event) => {
        event.preventDefault();

        if (serijskiBroj == "") {
            setType(2);
            setMessage("Unesite serijski broj koji je ispravan!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_slajfanje', id);
        formData.append('serijski_broj', serijskiBroj);

        mutationOdslajfuj.mutateAsync(formData).catch((error) => {
        });
    }

    const handleProslediKontroloru = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id_slajfanje', id);

        mutationProslediKontroloru.mutateAsync(formData).catch((err) => {
        });
    }

    const checkSerijskiBroj = (value) => {
        setPostoji(data?.data?.za_slajfanje?.some(item => item.serijski_broj === value) || false);
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Za šlajfanje
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Odšlajfano
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su za šlajfanje!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_za_slajfanje}
                            rows={isLoading ? [] : data?.data.za_slajfanje}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su odšlajfani!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_odslajfano}
                            rows={isLoading ? [] : data?.data.odslajfani}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                        data?.data.za_slajfanje.length == 0 ? (
                            <Alert severity="info">
                                Svi proizvodi su odšlajfani!
                            </Alert>
                        ) : (
                            <Box>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="serijski_broj"
                                    name="serijski_broj"
                                    label="Unesite serijski broj"
                                    placeholder="Unesite serijski broj"
                                    helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                    variant="outlined"
                                    value={serijskiBroj}
                                    onChange={(event) => {
                                        setSerijskiBroj(event.target.value);
                                        checkSerijskiBroj(event.target.value);
                                    }}
                                    slotProps={{
                                        input: {
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <QrCodeScannerTwoToneIcon/>
                                                </InputAdornment>,
                                        },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="button"
                                    endIcon={<ArrowForwardTwoToneIcon/>}
                                    onClick={handleOdslajfuj}
                                    disabled={!postoji}
                                >
                                    Odšlajfujte proizvod
                                </Button>
                            </Box>
                        )
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                        <Button
                            variant="contained"
                            fullWidth
                            type="button"
                            endIcon={<SendToMobileTwoToneIcon/>}
                            onClick={handleProslediKontroloru}
                            disabled={data?.data.za_slajfanje.length != 0}
                        >
                            Prosledite kontroloru
                        </Button>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SlajfanjeProcesPage;