import React from 'react';
import {useParams} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {masina} from "../../../apis/masinaApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";
import Nalepnica from "../../../components/stampanje/Nalepnica";
import Button from "@mui/material/Button";
import LocalPrintshopTwoToneIcon from "@mui/icons-material/LocalPrintshopTwoTone";

const MasinaPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['masina', id],
        queryFn: () => masina(id)
    });

    const printDiv = (divId) => {
        const container = document.getElementById(divId);
        const width = "100%";
        const height = "100%";
        const printWindow = window.open(
            "",
            "PrintMap",
            "width=" + width + ",height=" + height,
        );
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o mašini
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o mašini!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.id}</b></Item>
                            <Divider/>
                            <Item>Šifra: <b>{data?.data.barkod}</b></Item>
                            <Divider/>
                            <Item>Naziv: <b>{data?.data.naziv}</b></Item>
                            <Divider/>
                            <Item>Serijski broj: <b>{data?.data.serijski_broj}</b></Item>
                            <Divider/>
                            <Item>Maseni kapacitet: <b>{data?.data.maseni_kapacitet} {data?.data.mk_jedinica}</b></Item>
                            <Divider/>
                            <Item>Vremenski kapacitet: <b>{data?.data.vremenski_kapacitet} {data?.data.vk_jedinica}</b></Item>
                            <Divider/>
                            <div style={{
                                justifyContent: "center",
                                display: "flex",
                            }} id="stampanje">
                                <Nalepnica serijski_broj={data?.data.barkod} naziv={data?.data.naziv}
                                           tip={"Mašina"}/>
                            </div>
                            <Button
                                sx={{mt: 2, mb: 2}}
                                endIcon={<LocalPrintshopTwoToneIcon/>}
                                variant="contained"
                                onClick={() => printDiv("stampanje")}
                            >
                                Štampajte nalepnicu
                            </Button>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default MasinaPage;