import React from 'react';
import {useNavigate} from "react-router";
import {useQuery} from "@tanstack/react-query";
import Button from "@mui/material/Button";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../../language/tableLang";
import {poliranjaZaKontrolu} from "../../../../apis/poliranjeApi";

const KontrolorPoliranjaPage = () => {
    const navigate = useNavigate();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["poliranjaZaKontrolu"],
        queryFn: poliranjaZaKontrolu,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_pocetak",
            headerName: "Vreme početka",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kraj",
            headerName: "Vreme završetka",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()} / {(date.getMonth() + 1)} / {date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
        {
            field: "status_number",
            headerName: "Pristup",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<ArrowForwardTwoToneIcon/>}
                        onClick={() => navigate('../poliranje/' + params.row.id)}
                    >
                        Pristupi
                    </Button>
                );
            },
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Svi radni nalozi za kontrolu poliranja
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja radnih naloga za kontrolu poliranja!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default KontrolorPoliranjaPage;