import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {adminRadniNaloziKojiSuTrebovani} from "../../../apis/adminRadniNalogApi";
import {
    Alert,
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import SwitchAccessShortcutTwoToneIcon from "@mui/icons-material/SwitchAccessShortcutTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import {createLansiranje, lansiranja} from "../../../apis/lansiranjeApi";

const FormDialog = ({
                        open,
                        onClose,
                        onSubmit,
                        setOpenN,
                        setType,
                        setMessage,
                        radniNalog,
                        setRadniNalog,
                    }) => {
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const handleSubmit = () => {
        onSubmit();
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ['adminRadniNaloziKojiSuTrebovani'],
        queryFn: adminRadniNaloziKojiSuTrebovani,
        enabled: openAutocomplete
    });

    return (
        <Dialog maxWidth={"md"} fullWidth open={open} onClose={onClose}>
            <DialogTitle>Unos novog lansiranja</DialogTitle>
            <DialogContent>
                <Autocomplete
                    fullWidth
                    open={openAutocomplete}
                    onOpen={handleOpenAutocomplete}
                    onClose={handleCloseAutocomplete}
                    isOptionEqualToValue={(option, value) =>
                        option.naziv_sirov == value.naziv_sirov || option.sifra == value.sifra || option.serijski_broj == value.serijski_broj
                    }
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setRadniNalog(newValue);
                        } else {
                            console.log("Nema ove robe u sistemu!");
                        }
                    }}
                    value={radniNalog}
                    getOptionLabel={(option) => {
                        return "Status: " + option.txt_status + " | Ime i prezime: " + (option.ime + " " + option.prezime) + " | Vreme: " + option.vreme_kreiranja;
                    }}
                    options={isLoading ? [] : data?.data}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label="Izaberite radni nalog za koji hoćete da uradite lansiranje"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isLoading ? (
                                                <CircularProgress color="inherit" size={20}/>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                },
                            }}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Napravi novo lansiranje
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const LansiranjaPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [radniNalog, setRadniNalog] = useState(null);

    const {data, isLoading, isError} = useQuery({
        queryKey: ["lansiranja"],
        queryFn: lansiranja,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "id_radni_nalog",
            headerName: "Radni nalog",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../radni-nalog/" + params.row.id_radni_nalog)}
                    >
                        Radni nalog
                    </Button>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stat",
            headerName: "Ponovni pristup",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<SwitchAccessShortcutTwoToneIcon/>}
                        onClick={() => navigate("../novo-lansiranje/" + params.row.id)}
                        disabled={params.row.stat == 1}
                    >
                        Pristupi ponovo
                    </Button>
                );
            },
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_pocetak",
            headerName: "Vreme početka",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kraj",
            headerName: "Vreme završetka",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../lansiranje/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = () => {
        if (radniNalog == null) {
            setType(2);
            setMessage("Morate izabrati radni nalog!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('id_radni_nalog', radniNalog?.id);

        mutation.mutateAsync(formData).catch((err) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createLansiranje(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setOpen(false);

            setTimeout(() => {
                navigate('../novo-lansiranje/' + response.data.id);
            }, 2000);

            queryClient.invalidateQueries(['lansiranja']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Sva lansiranja
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Novo lansiranje</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja lansiranja!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleSubmit}
                setOpenN={setOpenN}
                setType={setType}
                setMessage={setMessage}
                radniNalog={radniNalog}
                setRadniNalog={setRadniNalog}
            />
        </Grid>
    );
};

export default LansiranjaPage;