import axios from "axios";
import {url} from "./urls";

export const masine = async () => {
    return axios.get(url + '/masine', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const radnePozicije = async () => {
    return axios.get(url + '/radne-pozicije', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const masina = async (id) => {
    return axios.get(url + '/masina/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const createMasina = async (formData) => {
    return axios.post(url + '/create-masina', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}