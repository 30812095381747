import React from 'react';
import {useNavigate} from "react-router";
import {useQuery} from "@tanstack/react-query";
import {otpremnice} from "../../../apis/otpremnicaApi";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, LinearProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";

const OtpremnicePage = () => {
    const navigate = useNavigate();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["otpremnice"],
        queryFn: otpremnice,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "radnik",
            headerName: "Radnik",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_kupca",
            headerName: "Naziv kupca",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "gln_kupca",
            headerName: "GLN Kupca",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra_kupca",
            headerName: "Šifra kupca",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra_mesta_isporuke",
            headerName: "Šifra mesta isporuke",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "gln_mesta_isporuke",
            headerName: "GLN mesta isporuke",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "registracija_kamiona",
            headerName: "Registracija kamiona",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_sofera",
            headerName: "Naziv šofera",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../otpremnica/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Sve otpremnice
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja otpremnica!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default OtpremnicePage;