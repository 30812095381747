import axios from "axios";
import {url} from "./urls";

export const login_1 = async (formData) => {
    return axios.post(url + "/login-1", formData, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
};

export const login_2 = async (formData) => {
    return axios.post(url + "/login-2", formData, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
};

export const logout = async (formData) => {
    return axios.post(url + "/logout", formData, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};
