import React from 'react';
import {Navigate, Outlet} from "react-router";
import SkladistarLayout from "../layouts/SkladistarLayout";

const SkladistarProtectedRoute = () => {
    let role_id = localStorage.getItem("role_id");

    if (role_id != 6) {
        return <Navigate to="/"/>;
    }

    return (
        <SkladistarLayout>
            <Outlet/>
        </SkladistarLayout>
    );
};

export default SkladistarProtectedRoute;