import React, {useState} from 'react';
import {useNavigate} from "react-router";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowOutwardTwoToneIcon from "@mui/icons-material/ArrowOutwardTwoTone";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import StorageIcon from "@mui/icons-material/Storage";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PolicyIcon from '@mui/icons-material/Policy';
import AddIcon from "@mui/icons-material/Add";
import {useMutation} from "@tanstack/react-query";
import {noviPrijemRobe} from "../../apis/prijemRobeApi";
import Notification from "../../components/Notification";
import {CircularProgress} from "@mui/material";
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';

const mainListItems = [
    {
        text: "Prijemi robe",
        icon: <CallReceivedIcon size={22} sx={{mr: 2}}/>,
        route: "../../kontrola/prijemi-robe",
    },
    {
        text: "Stanje skladišta",
        icon: <StorageIcon size={22} sx={{mr: 2}}/>,
        route: "../../kontrola/stanje-skladišta",
    },
    {
        text: "Provera serijskog broja",
        icon: <QrCodeScannerIcon size={22} sx={{mr: 2}}/>,
        route: "../../kontrola/provera-serijskog-broja",
    },
    {
        text: "Radni nalozi",
        icon: <SummarizeTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../kontrola/radni-nalozi",
    },
];

const KontrolaPocetnaPage = () => {
    const navigate = useNavigate();
    const user_id = localStorage.getItem("user_id");

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const mutation = useMutation({
        mutationFn: (formData) => noviPrijemRobe(formData),
        onSuccess: (response) => {
            setOpen(true);
            setType(1);
            setMessage(response.data.message);
            setLoading(false);

            setTimeout(() => {
                navigate("/kontrola/novi-prijem-robe/" + response.data.id);
            }, 2000);
        },
        onError: (error) => {
            setOpen(true);
            setType(2);
            setMessage(error.response.data.message);
            setLoading(false);
        }
    });

    const handleNoviPrijemRobe = (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("id_radnik", user_id);

        mutation.mutateAsync(formData).catch((error) => {
        });
    }

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={open}
                setOpen={setOpen}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Dobro došli na kontrolorski panel
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12, md: 6, lg: 6, xl: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <AddIcon size={22} sx={{mr: 2}}/>
                        Novi prijem robe
                    </Typography>
                    <Button
                        endIcon={<ArrowOutwardTwoToneIcon/>}
                        sx={{mt: 2}}
                        fullWidth
                        variant="contained"
                        onClick={handleNoviPrijemRobe}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={22}/> : "Pristup"}
                    </Button>
                </Paper>
            </Grid>
            {mainListItems.map((item, index) => (
                <Grid size={{xs: 12, md: 6, lg: 6, xl: 6}}>
                    <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                        <Typography sx={{fontSize: 22, textAlign: "center"}}>
                            {item.icon}
                            {item.text}
                        </Typography>
                        <Button
                            endIcon={<ArrowOutwardTwoToneIcon/>}
                            sx={{mt: 2}}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                localStorage.setItem("index", index + 1);
                                navigate(item.route);
                            }}
                        >
                            Pristup
                        </Button>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default KontrolaPocetnaPage;