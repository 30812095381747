import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import Notification from "../../../components/Notification";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {Alert, Autocomplete, Box, ButtonGroup, CircularProgress, InputAdornment, LinearProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    formirajGajbicaRadnikVezu,
    lansirajProizvod,
    potrebnoLansirati,
    zavrsiLansiranje
} from "../../../apis/lansiranjeApi";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import Button from "@mui/material/Button";
import {radniciNaSlajfanju} from "../../../apis/userApi";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import TextField from "@mui/material/TextField";
import TouchAppTwoToneIcon from '@mui/icons-material/TouchAppTwoTone';

const NovoLansiranjePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);
    const [postoji, setPostoji] = useState(false);

    const [radnik, setRadnik] = useState(null);
    const [sifraGajbica, setSifraGajbica] = useState("");
    const [serijskiBroj, setSerijskiBroj] = useState("");
    const [vezaId, setVezaId] = useState(null);

    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const columns_repromaterijali = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "opis",
            headerName: "Opis",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        }
    ];
    const columns_potrebno_lansirati = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_lansirano = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_veze = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 30,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "id_veza",
            headerName: "Pristup",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<TouchAppTwoToneIcon/>}
                        onClick={() => setVezaId(params.row.id)}
                        disabled={vezaId == params.row.id}
                    >
                        Izaberite
                    </Button>
                );
            },
        },
        {
            field: "ime",
            headerName: "Ime",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "prezime",
            headerName: "Prezime",
            minWidth: 75,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv tovarnog medijuma",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const {
        data: podaci,
        isLoading: loadingPodaci,
        error: errorPodaci
    } = useQuery({
        queryKey: ['potrebnoLansirati', id],
        queryFn: () => potrebnoLansirati(id)
    });

    const {
        data: radnici,
        isLoading: loadingRadnici,
        error: errorRadnici
    } = useQuery({
        queryKey: ['radniciNaSlajfanju'],
        queryFn: radniciNaSlajfanju,
        enabled: openAutocomplete
    });

    const mutationFormirajuNovuVezu = useMutation({
        mutationFn: (formData) => formirajGajbicaRadnikVezu(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setVezaId(response.data.id);

            queryClient.invalidateQueries(['potrebnoLansirati', id]).then((res) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationDodajProizvodUGajbicu = useMutation({
        mutationFn: (formData) => lansirajProizvod(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSerijskiBroj("");

            queryClient.invalidateQueries(['potrebnoLansirati', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationZavrsiLansiranje = useMutation({
        mutationFn: (formData) => zavrsiLansiranje(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setTimeout(() => {
                navigate('../lansiranja');
            }, 2000);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const handleDodajNovuVezu = (event) => {
        event.preventDefault();

        if (radnik == null) {
            setType(2);
            setMessage('Morate izabrati radnika kojeg želite da dodate u lansiranje!');
            setOpenN(true);
            return;
        }

        if (sifraGajbica == "") {
            setType(2);
            setMessage('Morate uneti šifru tovarnog medijuma!');
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_lansiranje', id);
        formData.append('id_radnik', radnik?.id);
        formData.append('sifra_gajbice', sifraGajbica)

        mutationFormirajuNovuVezu.mutateAsync(formData).catch((err) => {
        });
    }

    const handleLansirajProizvod = (event) => {
        event.preventDefault();
        if (vezaId == null) {
            setType(2);
            setMessage('Morate izabrati radnika kojem lansirate proizvod!');
            setOpenN(true);
            return;
        }

        if (serijskiBroj == "") {
            setType(2);
            setMessage('Morate izabrati serijski broj koji je odgovarajuć!');
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_gajbica_radnik', vezaId);
        formData.append('serijski_broj', serijskiBroj);

        mutationDodajProizvodUGajbicu.mutateAsync(formData).catch((err) => {
        });
    }

    const handleZavrsiLansiranje = (event) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append('id_lansiranje', id);

        mutationZavrsiLansiranje.mutateAsync(formData).catch((err) => {
        });
    }

    const checkSerijskiBroj = (value) => {
        setPostoji(podaci?.data?.proizvodi_za_lansiranje?.some(item => item.serijski_broj === value) || false);
    }

    return (
        <Grid container spacing={1}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Repromaterijali
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja repromaterijala!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_repromaterijali}
                            rows={loadingPodaci ? [] : podaci?.data.repromaterijali}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Potrebno lansirati
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Lansirano
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koje treba lansirati!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_potrebno_lansirati}
                            rows={loadingPodaci ? [] : podaci?.data.proizvodi_za_lansiranje}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja proizvoda koji su lansirani!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_lansirano}
                            rows={loadingPodaci ? [] : podaci?.data.lansirani_proizvodi}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xl: 6, lg: 6, md: 6, sm: 12, xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    {loadingPodaci ? (
                        <LinearProgress size={24} color="inherit"/>
                    ) : podaci?.data.proizvodi_za_lansiranje.length == 0 ? (
                        <Alert severity="info">
                            Svi proizvodi iz ovog trebovanja su lansirani!
                        </Alert>
                    ) : vezaId == null ? (
                        <Box>
                            <Autocomplete
                                fullWidth
                                open={openAutocomplete}
                                onOpen={handleOpenAutocomplete}
                                onClose={handleCloseAutocomplete}
                                isOptionEqualToValue={(option, value) =>
                                    option.ime == value.ime || option.prezime == value.prezime
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setRadnik(newValue);
                                    }
                                }}
                                value={radnik}
                                getOptionLabel={(option) => {
                                    return "Ime: " + option.ime + " | Prezime: " + option.prezime + " | Pozicija: " + option.naziv;
                                }}
                                options={loadingRadnici ? [] : radnici?.data}
                                loading={loadingRadnici}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        margin="normal"
                                        label="Izaberite radnika"
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loadingRadnici ? (
                                                            <CircularProgress color="inherit" size={20}/>
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            },
                                        }}
                                    />
                                )}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                id="tovarni_medijum"
                                name="tovarni_medijum"
                                label="Unesite šifru tovarnog medijuma"
                                placeholder="Unesite šifru tovarnog medijuma"
                                helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                variant="outlined"
                                value={sifraGajbica}
                                onChange={(event) => {
                                    setSifraGajbica(event.target.value);
                                }}
                                slotProps={{
                                    input: {
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <QrCodeScannerTwoToneIcon/>
                                            </InputAdornment>,
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                type="button"
                                endIcon={<AssignmentTurnedInTwoToneIcon/>}
                                onClick={handleDodajNovuVezu}
                                disabled={vezaId != null}
                            >
                                Dodajte gajbicu i radnika u lansiranje
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                id="serijski_broj"
                                name="serijski_broj"
                                label="Unesite serijski broj proizvoda"
                                placeholder="Unesite serijski broj proizvoda"
                                helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                variant="outlined"
                                value={serijskiBroj}
                                onChange={(event) => {
                                    setSerijskiBroj(event.target.value);
                                    checkSerijskiBroj(event.target.value);
                                }}
                                slotProps={{
                                    input: {
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <QrCodeScannerTwoToneIcon/>
                                            </InputAdornment>,
                                    },
                                }}
                            />
                            <ButtonGroup fullWidth variant="contained" aria-label="Basic button group">
                                <Button color="error" onClick={() => setVezaId(null)}>Završi dodavanje za ovog
                                    radnika</Button>
                                <Button color="success" disabled={!postoji} onClick={handleLansirajProizvod}>Dodaj
                                    proizvod</Button>
                            </ButtonGroup>
                        </Box>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xl: 6, lg: 6, md: 6, sm: 12, xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                    {errorPodaci ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja tovarnih medijuma i radnika koji su u ovom lansiranju!
                        </Alert>
                    ) : loadingPodaci ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns_veze}
                            rows={loadingPodaci ? [] : podaci?.data.veze}
                        />
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                    {loadingPodaci ? (
                        <LinearProgress size={24} color="inherit"/>
                    ) : (
                        <Button
                            variant="contained"
                            fullWidth
                            type="button"
                            endIcon={<AssignmentTurnedInTwoToneIcon/>}
                            onClick={handleZavrsiLansiranje}
                            disabled={podaci?.data.proizvodi_za_lansiranje.length != 0}
                        >
                            Završite lansiranje
                        </Button>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default NovoLansiranjePage;