import axios from "axios";
import {url} from "./urls";

export const prijemiRobe = async () => {
    return axios.get(url + `/prijemi-robe`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const prijemRobe = async (id) => {
    return axios.get(url + `/prijem-robe/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const proizvodiIRepromaterijali = async () => {
    return axios.get(url + `/proizvodi-i-repromaterijali`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const primljenaRoba = async (id) => {
    return axios.get(url + `/primljena-roba/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const noviPrijemRobe = async (formData) => {
    return axios.post(url + '/create-prijem-robe', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const primiRobu = async (formData) => {
    return axios.post(url + '/primi-robu', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const noviProizvodIliRepromaterijal = async (formData) => {
    return axios.post(url + '/novi-proizvod-ili-repromaterijal', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const finishPrijem = async (formData) => {
    return axios.post(url + '/finish-prijem-robe', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}