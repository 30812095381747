import React from 'react';
import {useParams} from "react-router";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {Alert, Chip, CircularProgress, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {Item} from "../../../components/additional/Item";
import {useQuery} from "@tanstack/react-query";
import {adminRadniNalog} from "../../../apis/adminRadniNalogApi";

const RadniNalogPage = () => {
    const {id} = useParams();

    const {data, isLoading, isError} = useQuery({
        queryKey: ['radniNalogAdmin', id],
        queryFn: () => adminRadniNalog(id)
    });

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Informacije o radnom nalogu
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o radnom nalogu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider/>
                            <Item>Sistemski ID: <b>{data?.data.radni_nalog.id}</b></Item>
                            <Divider/>
                            <Item>Ime i prezime
                                kreatora: <b>{data?.data.radni_nalog.ime} {data?.data.radni_nalog.prezime}</b></Item>
                            <Divider/>
                            <Item>Status: <b>{data?.data.radni_nalog.txt_status}</b></Item>
                            <Divider/>
                            <Item>Vreme kreiranja: <b>{data?.data.radni_nalog.vreme_kreiranja}</b></Item>
                            <Divider/>
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{textAlign: 'center'}}>Broj proizvoda(serijskih brojeva): {isLoading ?
                        <CircularProgress size={22}/> : <b>{data?.data.broj_proizvoda} komad/a</b>}</Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 6}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{textAlign: 'center'}}>Broj repromaterijala: {isLoading ?
                        <CircularProgress size={22}/> : <b>{data?.data.broj_repromaterijala} vrsta/e</b>}</Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o radnom nalogu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider>
                                <Chip label="Svi proizvodi u ovom radnom nalogu" size="small"/>
                            </Divider>
                            {data?.data.proizvodi.map((item) => {
                                return (
                                    <>
                                        <Divider/>
                                        <Item>Trenutni
                                            proizvod: <b>{item.serijski_broj} | {item.sifra_sirov} | {item.naziv_sirov} | {item.kolicina} {item.jedinica}</b></Item>
                                        <Item>Finalni proizvod: <b>{item.sifra_gotov} | {item.naziv_gotov}</b></Item>
                                        <Divider/>
                                    </>
                                );
                            })}
                        </Stack>
                    )}
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podatak o radnom nalogu!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit"/> : (
                        <Stack spacing={2}>
                            <Divider>
                                <Chip label="Svi repromaterijali (ukupna količina) u ovom radnom nalogu"
                                      size="small"/>
                            </Divider>
                            {data?.data.trebovanje.map((item) => {
                                return (
                                    <>
                                        <Item><b>{item.sifra} | {item.naziv} | {item.opis} | {item.kolicina} {item.jedinica}</b></Item>
                                        <Divider/>
                                    </>
                                );
                            })}
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default RadniNalogPage;