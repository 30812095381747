import React, {useState} from 'react';
import {
    Alert,
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import Button from "@mui/material/Button";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createVeza, gotoviProizvodi, siroviProizvodi, vezeSirovGotov} from "../../../apis/sifrarniciApi";
import Notification from "../../../components/Notification";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import TextField from "@mui/material/TextField";

const FormDialog = ({open, onClose, onSubmit, setOpenN, setType, setMessage}) => {
    const [sirovProizvod, setSirovProizvod] = useState(null);
    const [gotovProizvod, setGotovProizvod] = useState(null);

    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const [openAutocomplete1, setOpenAutocomplete1] = useState(false);
    const handleOpenAutocomplete1 = () => setOpenAutocomplete1(true);
    const handleCloseAutocomplete1 = () => setOpenAutocomplete1(false);

    const {data: sirovi_proizvodi, isLoading: loadingSiroviProizvodi, isError: errorSiroviProizvodi} = useQuery({
        queryKey: ['siroviProizvodi'],
        queryFn: siroviProizvodi,
        enabled: openAutocomplete
    });

    const {data: gotovi_proizvodi, isLoading: loadingGotoviProizvodi, isError: errorGotoviProizvodi} = useQuery({
        queryKey: ['gotoviProizvodi'],
        queryFn: gotoviProizvodi,
        enabled: openAutocomplete1
    });

    const handleSubmit = () => {
        if (sirovProizvod == null) {
            return;
        }

        if (gotovProizvod == null) {
            return;
        }
        const formData = new FormData();
        formData.append('sifra_sirov', sirovProizvod?.sifra);
        formData.append('sifra_gotov', gotovProizvod?.sifra);
        onSubmit(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Unos nove veze izmedju sirovih i gotovih proizvoda</DialogTitle>
            <DialogContent>
                <Autocomplete
                    fullWidth
                    open={openAutocomplete}
                    onOpen={handleOpenAutocomplete}
                    onClose={handleCloseAutocomplete}
                    isOptionEqualToValue={(option, value) =>
                        option.naziv == value.naziv || option.sifra == value.sifra
                    }
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSirovProizvod(newValue);
                        } else {
                            console.log("Nema ove robe u sistemu!");
                        }
                    }}
                    value={sirovProizvod}
                    getOptionLabel={(option) => {
                        return "Naziv: " + option.naziv + " | Šifra: " + option.sifra;
                    }}
                    options={loadingSiroviProizvodi ? [] : sirovi_proizvodi?.data}
                    loading={loadingSiroviProizvodi}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            margin="normal"
                            label="Izaberite sirov proizvod"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loadingSiroviProizvodi ? (
                                                <CircularProgress color="inherit" size={20}/>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                },
                            }}
                        />
                    )}
                />
                <Autocomplete
                    fullWidth
                    open={openAutocomplete1}
                    onOpen={handleOpenAutocomplete1}
                    onClose={handleCloseAutocomplete1}
                    isOptionEqualToValue={(option, value) =>
                        option.naziv == value.naziv || option.sifra == value.sifra
                    }
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setGotovProizvod(newValue);
                        } else {
                            console.log("Nema ove robe u sistemu!");
                        }
                    }}
                    value={gotovProizvod}
                    getOptionLabel={(option) => {
                        return "Naziv: " + option.naziv + " | Šifra: " + option.sifra;
                    }}
                    options={loadingGotoviProizvodi ? [] : gotovi_proizvodi?.data}
                    loading={loadingGotoviProizvodi}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            margin="normal"
                            label="Izaberite gotov proizvod"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loadingGotoviProizvodi ? (
                                                <CircularProgress color="inherit" size={20}/>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                },
                            }}
                        />
                    )}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SiroviGotoviSifrarniciPage = () => {
    const queryClient = useQueryClient();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["vezeSirovGotov"],
        queryFn: vezeSirovGotov,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra_sirov",
            headerName: "Šifra sirovog proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_sirov",
            headerName: "Naziv sirovog proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra_gotov",
            headerName: "Šifra gotovog proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_gotov",
            headerName: "Naziv gotovog proizvoda",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = (data) => {
        mutation.mutateAsync(data).then(() => {
        }).catch((e) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createVeza(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['vezeSirovGotov']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Svi veze izmedju sirovog i gotovog proizvoda
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Nova veza izmedju proizvoda</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanje veza izmedju sirovog i gotovog proizvoda!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleSubmit}
                setOpenN={setOpenN}
                setType={setType}
                setMessage={setMessage}
            />
        </Grid>
    );
};

export default SiroviGotoviSifrarniciPage;