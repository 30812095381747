import React from 'react';
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import {useNavigate} from "react-router";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import Button from "@mui/material/Button";
import ArrowOutwardTwoToneIcon from "@mui/icons-material/ArrowOutwardTwoTone";

const mainListItems = [
    {
        text: "Radni nalozi",
        icon: <ArticleTwoToneIcon size={22} sx={{mr: 2}}/>,
        route: "../../poliranje/radni-nalozi",
    },
];

const PoliranjePocetnaPage = () => {
    const navigate = useNavigate();

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Dobro došli na panel za poliranje
                    </Typography>
                </Paper>
            </Grid>
            {mainListItems.map((item, index) => (
                <Grid size={{xs: 12, md: 6, lg: 6, xl: 6}}>
                    <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                        <Typography sx={{fontSize: 22, textAlign: "center"}}>
                            {item.icon}
                            {item.text}
                        </Typography>
                        <Button
                            endIcon={<ArrowOutwardTwoToneIcon/>}
                            sx={{mt: 2}}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                localStorage.setItem("index", index + 1);
                                navigate(item.route);
                            }}
                        >
                            Pristup
                        </Button>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default PoliranjePocetnaPage;