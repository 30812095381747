import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    MenuItem
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import {createSkladiste, skladista, tipoviSkladista} from "../../../apis/skladistaApi";

const FormDialog = ({open, onClose, onSubmit}) => {
    const [formData, setFormData] = useState({
        naziv: "",
        tip: 1,
    });

    const handleChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    };

    const handleSubmit = () => {
        onSubmit(formData);
        onClose();
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ['tipoviSkladista'],
        queryFn: tipoviSkladista
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Unos novog skladišta</DialogTitle>
            <DialogContent>
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Naziv"
                    name="naziv"
                    value={formData.naziv}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    select
                    label="Tip skladista"
                    name="tip"
                    value={formData.tip}
                    onChange={handleChange}
                    required
                >
                    {isLoading ? <CircularProgress/> : (data?.data.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.naziv}
                        </MenuItem>
                    )))}
                </TextField>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SkladistaPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["skladista"],
        queryFn: skladista,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "barkod",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tip",
            headerName: "Tip skladišta",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()} / {(date.getMonth() + 1)} / {date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../skladiste/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = (data) => {
        if (data.naziv == "") {
            setType(2);
            setMessage("Morate uneti naziv mašine!");
            setOpenN(true);
            return;
        }


        if (data.tip == null) {
            setType(2);
            setMessage("Morate izabrati tip skladišta!");
            setOpenN(true);
            return;
        }

        mutation.mutateAsync(data).then(() => {
            data.naziv = "";
            data.tip = 1;
        }).catch((e) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createSkladiste(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['skladista']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Sva skladišta
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Novo skladište</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja svih skladišta!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog open={open} onClose={() => setOpen(false)} onSubmit={handleSubmit}/>
        </Grid>
    );
};

export default SkladistaPage;