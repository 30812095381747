import axios from "axios";
import {url} from "./urls";

export const stanjeSkladistaSirovihProizvodaKojiImajuVezu = async () => {
    return axios.get(url + '/stanje-skladista-sirovih-proizvoda-koji-imaju_vezu', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const proveriStanjeRepromaterijala = async (formData) => {
    return axios.post(url + '/proveri-stanje-repromaterijala', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}