import {alpha, createTheme, ThemeProvider} from "@mui/material/styles";
import {srRS} from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SideMenu from "../components/layout/SideMenu";
import AppNavbar from "../components/layout/AppNavbar";
import Copyright from "../components/layout/Copyright";
import React from "react";

const theme = createTheme(
    {
        palette: {
            mode: "dark",
        },
    },
    srRS,
);

const SlafjanjeLayout = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <Box sx={{display: "flex"}}>
                <SideMenu/>
                <AppNavbar/>
                {/* Main content */}
                <Box
                    component="main"
                    sx={(theme) => ({
                        flexGrow: 1,
                        backgroundColor: theme.vars
                            ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                            : alpha(theme.palette.background.default, 1),
                        overflow: "auto",
                        mt: {xs: 5, md: 0, lg: 0, xl: 0},
                    })}
                >
                    <Box sx={{m: 4}}>{children}</Box>
                    <Box sx={{m: 4}}>
                        <Copyright/>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default SlafjanjeLayout;