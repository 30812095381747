import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Container, Paper, Typography, Alert, LinearProgress, Stack, Divider } from "@mui/material";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { stanjaSkladistaAllInfo } from "../../../apis/skladistaApi";
import { Item } from "../../../components/additional/Item";

const StanjeSkladistaPage = () => {
    const { id } = useParams(); // Get the id from the URL params

    const { data, isLoading, isError } = useQuery({
        queryKey: ["stanjaSkladista"], // Modify the query key to fetch all data once
        queryFn: stanjaSkladistaAllInfo, // Your existing function to get all data
    });

    // Check if data is available and filter based on the id
    const skladiste = data?.data?.find(item => item.id === parseInt(id)) || {}; // Find the specific skladiste by id

    return (
        <Container>
            <Paper sx={{ p: 3, mt: 2, textAlign: "center" }}>
                <Typography variant="h4">
                    <WarehouseIcon sx={{ mr: 2 }} /> Informacije o skladištu
                </Typography>
            </Paper>
            <Paper sx={{ p: 3, mt: 2 }}>
                {isError ? (
                    <Alert severity="error">Greška prilikom preuzimanja podataka o skladištu!</Alert>
                ) : isLoading ? (
                    <LinearProgress size={24} color="inherit" />
                ) : (
                    <Stack spacing={2}>
                        <Divider />
                        <Item>ID: <b>{skladiste.id}</b></Item>
                        <Divider />
                        <Item>Naziv: <b>{skladiste.skladiste_naziv}</b></Item>
                        <Divider />
                        <Item>Tip: <b>{skladiste.tip_skladista_naziv}</b></Item>
                        <Divider />
                        <Item>Barkod: <b>{skladiste.skladiste_barkod}</b></Item>
                        <Divider />
                        <Item>Vreme Kreiranja: <b>{skladiste.skladiste_vreme_kreiranja}</b></Item>
                        <Divider />
                        {skladiste.lokacije?.map((lokacija) => (
                            <Paper key={lokacija.id} sx={{ p: 2, mt: 2 }}>
                                <Typography variant="h6">Lokacija: {lokacija.lokacija_naziv}</Typography>
                                <Divider />
                                <Item>Barkod: <b>{lokacija.lokacija_barkod}</b></Item>
                                <Item>Koordinate: X: <b>{lokacija.lokacija_x}</b>, Y: <b>{lokacija.lokacija_y}</b>, Z: <b>{lokacija.lokacija_z}</b></Item>
                                <Item>Fiksna Lokacija: <b>{lokacija.fiksna_lokacija ? "Da" : "Ne"}</b></Item>
                                <Item>Šifra Proizvoda: <b>{lokacija.sifra_proizvoda}</b></Item>
                                <Item>Vreme Kreiranja: <b>{lokacija.lokacija_vreme_kreiranja}</b></Item>
                                <Divider />
                                {lokacija.stanja_skladista?.map((stanje, index) => (
                                    <Paper key={index} sx={{ p: 2, mt: 2 }}>
                                        <Typography variant="body1">Stanje Skladišta</Typography>
                                        <Divider />
                                        <Item>Serijski Broj: <b>{stanje.serijski_broj}</b></Item>
                                        <Item>Šifra: <b>{stanje.sifra}</b></Item>
                                        <Item>Tip: <b>{stanje.tip}</b></Item>
                                        <Item>Količina: <b>{stanje.kolicina} {stanje.jedinica}</b></Item>
                                        <Item>Vreme: <b>{stanje.vreme}</b></Item>
                                    </Paper>
                                ))}
                            </Paper>
                        ))}
                        <Divider />
                    </Stack>
                )}
            </Paper>
        </Container>
    );
};

export default StanjeSkladistaPage;
