import axios from "axios";
import {url} from "./urls";

export const skladista = async () => {
    return axios.get(url + '/skladista', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const tipoviSkladista = async () => {
    return axios.get(url + '/tipovi-skladista', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const skladiste = async (id) => {
    return axios.get(url + '/skladiste/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const createSkladiste = async (formData) => {
    return axios.post(url + '/create-skladiste', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const stanjaSkladistaAllInfo = async () => {
    return axios.get(url + '/skladista-all-info', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}