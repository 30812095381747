import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
    Container,
    Paper,
    Typography,
    Button,
    CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { getRadnePozicije } from "../../../apis/radniCentriApi";
import Notification from "../../../components/Notification";
import { tableLang } from "../../../language/tableLang";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";

const RadnePozicijePage = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const { data, isLoading, isError } = useQuery({
        queryKey: ["radnePozicije"],
        queryFn: getRadnePozicije,
    });

    const columns = [
        { field: "id", headerName: "ID", width: 90, headerAlign: "center", align: "center" },
        { field: "naziv", headerName: "Naziv", width: 180, flex: 1, headerAlign: "center", align: "center" },
        {
            field: "actions",
            headerName: "Akcije",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`../radni-centar/${params.row.id}`)}
                >
                    Pregled
                </Button>
            ),
        },
    ];

    return (
        <Container>
            <Notification type={type} message={message} open={openN} setOpen={setOpenN} />
            <Paper sx={{ p: 3, mt: 2, textAlign: "center" }}>
                <Typography variant="h4" gutterBottom>
                    <BusinessCenterTwoToneIcon size={22} sx={{mr: 2}}/> Radni Centri
                </Typography>
                <div style={{ height: 400, width: "100%" }}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : isError ? (
                        <Typography color="error">Greška pri učitavanju podataka</Typography>
                    ) : (
                        <DataGrid
                            rows={data?.data || []}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            localeText={tableLang}
                        />
                    )}
                </div>
            </Paper>
        </Container>
    );
};

export default RadnePozicijePage;
