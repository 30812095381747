import * as React from "react";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import Divider, {dividerClasses} from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import {paperClasses} from "@mui/material/Paper";
import {listClasses} from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon, {listItemIconClasses} from "@mui/material/ListItemIcon";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import MenuButton from "./MenuButton";
import {useNavigate} from "react-router";
import {useMutation} from "@tanstack/react-query";
import {logout} from "../../apis/authApi";
import Notification from "../Notification";

const MenuItem = styled(MuiMenuItem)({
    margin: "2px 0",
});

export default function OptionsMenu() {
    const navigate = useNavigate();
    let id = localStorage.getItem("user_id");
    let role_id = localStorage.getItem("role_id");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);
    const logoutMutation = useMutation({
        mutationFn: (formData) => logout(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setTimeout(() => {
                localStorage.clear();
                navigate("/");
            }, 2000);
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        },
    });

    const handleLogout = () => {
        const formData = new FormData();

        formData.append("id", id);

        logoutMutation.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <React.Fragment>
            <Notification
                open={openN}
                message={message}
                setOpen={setOpenN}
                type={type}
            />
            <MenuButton
                aria-label="Open menu"
                onClick={handleClick}
                sx={{borderColor: "transparent"}}
            >
                <MoreVertRoundedIcon/>
            </MenuButton>
            <Menu
                anchorEl={anchorEl}
                id="menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                sx={{
                    [`& .${listClasses.root}`]: {
                        padding: "4px",
                    },
                    [`& .${paperClasses.root}`]: {
                        padding: 0,
                    },
                    [`& .${dividerClasses.root}`]: {
                        margin: "4px -4px",
                    },
                }}
            >
                <MenuItem onClick={() => navigate("../administrator/profil/" + id)}>
                    Moj nalog
                </MenuItem>
                <Divider/>
                {role_id == 1 ? (
                    <MenuItem onClick={() => navigate('../administrator/podesavanja')}>Podešavanja</MenuItem>
                ) : null}
                <Divider/>
                <MenuItem
                    onClick={handleLogout}
                    sx={{
                        [`& .${listItemIconClasses.root}`]: {
                            ml: "auto",
                            minWidth: 0,
                        },
                    }}
                >
                    <ListItemText>Odjavite se</ListItemText>
                    <ListItemIcon>
                        <LogoutRoundedIcon fontSize="small"/>
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
