import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
    Container,
    Paper,
    Typography,
    Button,
    CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { getKupci } from "../../../apis/kupciApi";
import Notification from "../../../components/Notification";
import { tableLang } from "../../../language/tableLang";

const KupciPage = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const { data, isLoading, isError } = useQuery({
        queryKey: ["kupci"],
        queryFn: getKupci,
    });

    const columns = [
        { field: "id", headerName: "ID", width: 90, headerAlign: "center", align: "center" },
        { field: "naziv", headerName: "Naziv", width: 180, flex: 1, headerAlign: "center", align: "center" },
        { field: "gln", headerName: "GLN", width: 150, flex: 1, headerAlign: "center", align: "center" },
        { field: "sifra", headerName: "Šifra", width: 150, flex: 1, headerAlign: "center", align: "center" },
        { field: "adresa", headerName: "Adresa", width: 180, flex: 1, headerAlign: "center", align: "center" },
        { field: "telefon", headerName: "Telefon", width: 180, flex: 1, headerAlign: "center", align: "center" },
        { field: "email", headerName: "Email", width: 180, flex: 1, headerAlign: "center", align: "center" },
        { field: "vreme_kreiranja", headerName: "Vreme Kreiranja", width: 180, flex: 1, headerAlign: "center", align: "center" },
        {
            field: "actions",
            headerName: "Akcije",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`../kupac/${params.row.id}`)}
                >
                    Pregled
                </Button>
            ),
        },
    ];

    return (
        <Container>
            <Notification type={type} message={message} open={openN} setOpen={setOpenN} />
            <Paper sx={{ p: 3, mt: 2, textAlign: "center" }}>
                <Typography variant="h4" gutterBottom>
                    <LocalShippingIcon sx={{ mr: 2 }} /> Kupci
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("../kupac/create")}
                    sx={{ mb: 2 }}
                    startIcon={<LocalShippingIcon />}
                >
                    Novi Kupac
                </Button>
                <div style={{ height: 400, width: "100%" }}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : isError ? (
                        <Typography color="error">Greška pri učitavanju podataka</Typography>
                    ) : (
                        <DataGrid
                            rows={data?.data || []}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            localeText={tableLang}
                        />
                    )}
                </div>
            </Paper>
        </Container>
    );
};

export default KupciPage;
