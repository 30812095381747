import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    Alert,
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import {createOtpremnica, otpremnice} from "../../../apis/otpremnicaApi";
import {getKupci} from "../../../apis/kupciApi";
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';

const FormDialog = ({
                        open,
                        onClose,
                        onSubmit,
                        setOpenN,
                        setType,
                        setMessage,
                        kupac,
                        setKupac,
                        sifraMI,
                        setSifraMI,
                        glnMI,
                        setGlnMI,
                        regKam,
                        setRegKam,
                        nazSof,
                        setNazSof,
                    }) => {
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const handleOpenAutocomplete = () => setOpenAutocomplete(true);
    const handleCloseAutocomplete = () => setOpenAutocomplete(false);

    const handleSubmit = () => {
        onSubmit();
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ['getKupci'],
        queryFn: getKupci,
        enabled: openAutocomplete
    });

    return (
        <Dialog maxWidth={"md"} fullWidth open={open} onClose={onClose}>
            <DialogTitle>Unos nove otpremnice</DialogTitle>
            <DialogContent>
                <Autocomplete
                    fullWidth
                    open={openAutocomplete}
                    onOpen={handleOpenAutocomplete}
                    onClose={handleCloseAutocomplete}
                    isOptionEqualToValue={(option, value) =>
                        option.naziv == value.naziv || option.sifra == value.sifra || option.gln == value.gln
                    }
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setKupac(newValue);
                        } else {
                            console.log("Nema ovog kupca!");
                        }
                    }}
                    value={kupac}
                    getOptionLabel={(option) => {
                        return "Naziv: " + option.naziv + " | GLN: " + option.gln + " | Sifra: " + option.sifra;
                    }}
                    options={isLoading ? [] : data?.data}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label="Izaberite kupca"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isLoading ? (
                                                <CircularProgress color="inherit" size={20}/>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                },
                            }}
                        />
                    )}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Šifra mesta isporuke"
                    name="sifra_mesta_isporuke"
                    value={sifraMI}
                    onChange={(event) => setSifraMI(event.target.value)}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="GLN mesta isporuke"
                    name="gln_mesta_isporuke"
                    value={glnMI}
                    onChange={(event) => setGlnMI(event.target.value)}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Registracija kamiona"
                    name="reg_kam"
                    value={regKam}
                    onChange={(event) => setRegKam(event.target.value)}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Naziv šofera"
                    name="naz_sof"
                    value={nazSof}
                    onChange={(event) => setNazSof(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Napravi novo trebovanje
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SkladistarOtpremnicePage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [kupac, setKupac] = useState(null);
    const [sifraMI, setSifraMI] = useState("");
    const [glnMI, setGlnMI] = useState("");
    const [regKam, setRegKam] = useState("");
    const [nazSof, setNazSof] = useState("");

    const {data, isLoading, isError} = useQuery({
        queryKey: ["otpremnice"],
        queryFn: otpremnice,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "radnik",
            headerName: "Radnik",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_kupca",
            headerName: "Naziv kupca",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "gln_kupca",
            headerName: "GLN Kupca",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra_kupca",
            headerName: "Šifra kupca",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra_mesta_isporuke",
            headerName: "Šifra mesta isporuke",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "gln_mesta_isporuke",
            headerName: "GLN mesta isporuke",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "registracija_kamiona",
            headerName: "Registracija kamiona",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv_sofera",
            headerName: "Naziv šofera",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "pristup",
            headerName: "Pristup",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<ArrowForwardSharpIcon/>}
                        onClick={() => navigate("../nova-otpremnica/" + params.row.id)}
                        disabled={params.row.status == 1}
                    >
                        Pristup
                    </Button>
                );
            },
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../otpremnica/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = () => {
        if (kupac == null) {
            setType(2);
            setMessage("Morate izabrati kupca!");
            setOpenN(true);
            return;
        }

        if (sifraMI == "") {
            setType(2);
            setMessage("Morate uneti šifru mesta isporuke!");
            setOpenN(true);
            return;
        }

        if (glnMI == "") {
            setType(2);
            setMessage("Morate uneti GLN mesta isporuke!");
            setOpenN(true);
            return;
        }

        if (regKam == "") {
            setType(2);
            setMessage("Morate uneti registraciju kamiona!");
            setOpenN(true);
            return;
        }

        if (nazSof == "") {
            setType(2);
            setMessage("Morate uneti naziv šofera!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('id_kupac', kupac?.id);
        formData.append('sifra_mesta_isporuke', sifraMI);
        formData.append('gln_mesta_isporuke', glnMI);
        formData.append('registracija_kamiona', regKam);
        formData.append('naziv_sofera', nazSof);

        mutation.mutateAsync(formData).catch((err) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createOtpremnica(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setOpen(false);

            setTimeout(() => {
                navigate('../nova-otpremnica/' + response.data.id);
            }, 2000);

            queryClient.invalidateQueries(['otpremnice']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Sve otpremnice
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Nova otpremnica</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja otpremnica!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleSubmit}
                setOpenN={setOpenN}
                setType={setType}
                setMessage={setMessage}
                kupac={kupac}
                setKupac={setKupac}
                sifraMI={sifraMI}
                setSifraMI={setSifraMI}
                glnMI={glnMI}
                setGlnMI={setGlnMI}
                regKam={regKam}
                setRegKam={setRegKam}
                nazSof={nazSof}
                setNazSof={setNazSof}
            />
        </Grid>
    );
};

export default SkladistarOtpremnicePage;