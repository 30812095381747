import axios from "axios";
import {url} from "./urls";

export const lakiranja = async () => {
    return axios.get(url + '/lakiranja', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const lakiranje = async (id) => {
    return axios.get(url + '/lakiranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const startLakiranje = async (formData) => {
    return axios.post(url + '/start-lakiranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaLakiranje = async (id) => {
    return axios.get(url + '/za-lakiranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const izlakirajteProizvod = async (formData) => {
    return axios.post(url + '/izlakirajte-proizvod', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const proslediteKontroloruLakiranje = async (formData) => {
    return axios.post(url + '/prosledite-kontroloru-lakiranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const lakiranjeZaKontrolu = async () => {
    return axios.get(url + '/lakiranje-za-kontrolu', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const zaKontroluLakiranje = async (id) => {
    return axios.get(url + '/za-kontrolu-lakiranje/' + id, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const iskontrolisiteProizvodLakiranje = async (formData) => {
    return axios.post(url + '/iskontrolisite-proizvod-lakiranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const finishLakiranje = async (formData) => {
    return axios.post(url + '/end-lakiranje', formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
