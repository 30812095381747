import axios from "axios";
import {url} from "./urls";

export const userInfo = async (id) => {
    return axios.get(url + `/user-info/${id}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
};

export const notifications = async () => {
    return axios.get(url + `/notifications`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const radniciNaSlajfanju = async () => {
    return axios.get(url + `/radnici-koji-su-na-slajfanju`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}

export const radniciNaPoliranju = async () => {
    return axios.get(url + `/radnici-koji-su-na-poliranju`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}