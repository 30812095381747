import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Grid from "@mui/material/Grid2";
import Notification from "../../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import {
    Alert,
    Box,
    ButtonGroup,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../../language/tableLang";
import TextField from "@mui/material/TextField";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import Button from "@mui/material/Button";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import SendToMobileTwoToneIcon from "@mui/icons-material/SendToMobileTwoTone";
import {
    endZavrsnaKontrola,
    formirajtePakovanje,
    iskontrolisiteZK,
    spakujteProizvod,
    zaZavrsnuKontrolu
} from "../../../../apis/zavrsnaKontrolaApi";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import NalepnicaPakovanje from "../../../../components/stampanje/NalepnicaPakovanje";
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import OutputTwoToneIcon from '@mui/icons-material/OutputTwoTone';

const ZavrsnaKontrolaPage = () => {
    const {id} = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);
    const [postoji, setPostoji] = useState(false);

    const [serijskiBroj, setSerijskiBroj] = useState("");
    const [sifraLokacije, setSifraLokacije] = useState("");
    const [stanjeProizvoda, setStanjeProizvoda] = useState(1);

    const [idPakovanje, setIdPakovanje] = useState(null);

    const columns_za_kontrolu = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stanje",
            headerName: "Stanje",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 70,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_iskontrolisano = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "stanje",
            headerName: "Stanje",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "kolicina",
            headerName: "Količina",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "jedinica",
            headerName: "Jedinica",
            minWidth: 70,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const columns_za_rasporedjivanje = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columns_rasporedjeno = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "sifra",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 200,
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const columns = [
        {id: 'id', label: 'ID', minWidth: 50},
        {id: 'stampa', label: 'Štampa', minWidth: 150},
        {id: 'izbor', label: 'Izaberi', minWidth: 150},
        {id: 'sifra', label: 'Šifra', minWidth: 150},
        {id: 'vreme', label: 'Vreme', minWidth: 150},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ['zaZavrsnuKontrolu', id],
        queryFn: () => zaZavrsnuKontrolu(id)
    });

    const mutationIskontrolisi = useMutation({
        mutationFn: (formData) => iskontrolisiteZK(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSerijskiBroj("");

            queryClient.invalidateQueries(['zaZavrsnuKontrolu', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationZavrsi = useMutation({
        mutationFn: (formData) => endZavrsnaKontrola(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            if (response.data.status == 1) {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else if (response.data.status == 2) {
                setTimeout(() => {
                    navigate('../zavrsne-kontrole');
                }, 2000);
            }
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    const mutationSpakuj = useMutation({
        mutationFn: (formData) => spakujteProizvod(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setSerijskiBroj("");

            queryClient.invalidateQueries(['zaZavrsnuKontrolu', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    })

    const mutationFormirajPakovanje = useMutation({
        mutationFn: (formData) => formirajtePakovanje(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            setIdPakovanje(response.data.id);

            queryClient.invalidateQueries(['zaZavrsnuKontrolu', id]).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    })

    const handleIskontrolisi = (event) => {
        event.preventDefault();

        if (serijskiBroj == "") {
            setType(2);
            setMessage("Unesite serijski broj koji je ispravan!");
            setOpenN(true);
            return;
        }

        if (sifraLokacije == "" && stanjeProizvoda == 2) {
            setType(2);
            setMessage("Unesite ispravnu šifru lokacije!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('id_zavrsna_kontrola', id);
        formData.append('serijski_broj', serijskiBroj);
        formData.append('sifra_lokacije', sifraLokacije);
        formData.append('stanje', stanjeProizvoda);

        mutationIskontrolisi.mutateAsync(formData).catch((error) => {
        });
    }

    const handleZavrsi = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id', id);
        formData.append('id_radnik', localStorage.getItem('user_id'));

        mutationZavrsi.mutateAsync(formData).catch((err) => {
        });
    }

    const handleFormirajPakovanje = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (sifraLokacije == "") {
            setType(2);
            setMessage("Unesite ispravnu šifru lokacije!");
            setOpenN(true);
            return;
        }

        formData.append('id_zavrsna_kontrola', id);
        formData.append('sifra_lokacije', sifraLokacije);

        mutationFormirajPakovanje.mutateAsync(formData).catch((err) => {
        });
    }

    const handleSpakuj = (event) => {
        event.preventDefault();

        if (idPakovanje == null) {
            setType(2);
            setMessage("Izaberite pakovanje ili dodajte novo pakovanje!");
            setOpenN(true);
            return;
        }

        if (serijskiBroj == "") {
            setType(2);
            setMessage("Unesite serijski broj koji je ispravan!");
            setOpenN(true);
            return;
        }

        const formData = new FormData();
        formData.append('id_zavrsna_kontrola', id);
        formData.append('id_pakovanje', idPakovanje);
        formData.append('id_radnik', localStorage.getItem('user_id'));
        formData.append('serijski_broj', serijskiBroj);

        mutationSpakuj.mutateAsync(formData).catch((err) => {
        });
    }

    const checkSerijskiBrojZK = (value) => {
        setPostoji(data?.data?.za_kontrolu?.some(item => item.serijski_broj === value) || false);
    }

    const checkSerijskiBrojPAK = (value) => {
        setPostoji(data?.data?.za_pakovanje?.some(item => item.serijski_broj === value) || false);
    }

    const printDiv = (divId) => {
        const container = document.getElementById(divId);
        const width = "100%";
        const height = "100%";
        const printWindow = window.open(
            "",
            "PrintMap",
            "width=" + width + ",height=" + height,
        );
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            {isLoading ? (
                <Grid size={{xs: 6}}>
                    <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                        <LinearProgress size={24} color="inherit"/>
                    </Paper>
                </Grid>
            ) : data?.data.status == 1 ? (
                <>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                <PolicyIcon size={22} sx={{mr: 2}}/>
                                Proizvodi za kontrolu
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                <PolicyIcon size={22} sx={{mr: 2}}/>
                                Iskontrolisani proizvodi
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                            {isError ? (
                                <Alert severity="error">
                                    Greška prilikom učitavanja proizvoda koji su za kontrolu!
                                </Alert>
                            ) : isLoading ? (
                                <LinearProgress/>
                            ) : (
                                <DataGrid
                                    localeText={tableLang}
                                    columns={columns_za_kontrolu}
                                    rows={isLoading ? [] : data?.data.za_kontrolu}
                                />
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                            {isError ? (
                                <Alert severity="error">
                                    Greška prilikom učitavanja proizvoda koji su iskontrolisani!
                                </Alert>
                            ) : isLoading ? (
                                <LinearProgress/>
                            ) : (
                                <DataGrid
                                    localeText={tableLang}
                                    columns={columns_iskontrolisano}
                                    rows={isLoading ? [] : data?.data.iskontrolisani}
                                />
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                                data?.data.za_kontrolu.length == 0 ? (
                                    <Alert severity="info">
                                        Svi proizvodi su iskontrolisani!
                                    </Alert>
                                ) : (
                                    <Box>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            required
                                            id="serijski_broj"
                                            name="serijski_broj"
                                            label="Unesite serijski broj"
                                            placeholder="Unesite serijski broj"
                                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                            variant="outlined"
                                            value={serijskiBroj}
                                            onChange={(event) => {
                                                setSerijskiBroj(event.target.value);
                                                checkSerijskiBrojZK(event.target.value);
                                            }}
                                            slotProps={{
                                                input: {
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <QrCodeScannerTwoToneIcon/>
                                                        </InputAdornment>,
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            select
                                            label="Stanje proizvoda"
                                            name="stanje_proizvoda"
                                            value={stanjeProizvoda}
                                            onChange={(event) => setStanjeProizvoda(event.target.value)}
                                            required
                                        >
                                            <MenuItem value={1}>
                                                Dobar proizvod
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Za škart
                                            </MenuItem>
                                        </TextField>

                                        <TextField
                                            disabled={stanjeProizvoda == 1}
                                            margin="normal"
                                            fullWidth
                                            required={stanjeProizvoda != 1}
                                            id="sifra_lokacije"
                                            name="sifra_lokacije"
                                            label="Unesite šifru odgovarajuće lokacije"
                                            placeholder="Unesite šifru odgovarajuće lokacije"
                                            helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                            variant="outlined"
                                            value={sifraLokacije}
                                            onChange={(event) => setSifraLokacije(event.target.value)}
                                            slotProps={{
                                                input: {
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <QrCodeScannerTwoToneIcon/>
                                                        </InputAdornment>,
                                                },
                                            }}
                                        />

                                        <Button
                                            variant="contained"
                                            fullWidth
                                            type="button"
                                            endIcon={<ArrowForwardTwoToneIcon/>}
                                            onClick={handleIskontrolisi}
                                            disabled={!postoji}
                                        >
                                            Iskontrolišite proizvod
                                        </Button>
                                    </Box>
                                )
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="button"
                                    endIcon={<SendToMobileTwoToneIcon/>}
                                    onClick={handleZavrsi}
                                    disabled={data?.data.za_kontrolu.length != 0}
                                >
                                    Završite kontrolu
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                </>
            ) : data?.data.status == 2 ? (
                <>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                <PolicyIcon size={22} sx={{mr: 2}}/>
                                Potrebno spakovati
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            <Typography sx={{fontSize: 22, textAlign: "center"}}>
                                <PolicyIcon size={22} sx={{mr: 2}}/>
                                Spakovano
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                            {isError ? (
                                <Alert severity="error">
                                    Greška prilikom učitavanja proizvoda koje treba spakovati!
                                </Alert>
                            ) : isLoading ? (
                                <LinearProgress/>
                            ) : (
                                <DataGrid
                                    localeText={tableLang}
                                    columns={columns_za_rasporedjivanje}
                                    rows={isLoading ? [] : data?.data.za_pakovanje}
                                />
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                            {isError ? (
                                <Alert severity="error">
                                    Greška prilikom učitavanja proizvoda koji su spakovani!
                                </Alert>
                            ) : isLoading ? (
                                <LinearProgress/>
                            ) : (
                                <DataGrid
                                    localeText={tableLang}
                                    columns={columns_rasporedjeno}
                                    rows={isLoading ? [] : data?.data.spakovani}
                                />
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 6, sm: 12, xs: 12}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            {isLoading ? (
                                <LinearProgress size={24} color="inherit"/>
                            ) : data?.data.za_pakovanje.length == 0 ? (
                                <Alert severity="info">
                                    Svi proizvodi iz ovog trebovanja su rasporedjeni!
                                </Alert>
                            ) : idPakovanje == null ? (
                                <Box>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="sifra_lokacije"
                                        name="sifra_lokacije"
                                        label="Unesite šifru odgovarajuće lokacije"
                                        placeholder="Unesite šifru odgovarajuće lokacije"
                                        helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                        variant="outlined"
                                        value={sifraLokacije}
                                        onChange={(event) => setSifraLokacije(event.target.value)}
                                        slotProps={{
                                            input: {
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <QrCodeScannerTwoToneIcon/>
                                                    </InputAdornment>,
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        type="button"
                                        endIcon={<AssignmentTurnedInTwoToneIcon/>}
                                        onClick={handleFormirajPakovanje}
                                        disabled={idPakovanje != null}
                                    >
                                        Formirajte novo pakovanje
                                    </Button>
                                </Box>
                            ) : (
                                <Box>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        required
                                        id="serijski_broj"
                                        name="serijski_broj"
                                        label="Unesite serijski broj proizvoda"
                                        placeholder="Unesite serijski broj proizvoda"
                                        helperText="Ako niste u mogućnosti da skenirate QR kod, unesite ga ručno."
                                        variant="outlined"
                                        value={serijskiBroj}
                                        onChange={(event) => {
                                            setSerijskiBroj(event.target.value);
                                            checkSerijskiBrojPAK(event.target.value);
                                        }}
                                        slotProps={{
                                            input: {
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <QrCodeScannerTwoToneIcon/>
                                                    </InputAdornment>,
                                            },
                                        }}
                                    />
                                    <ButtonGroup fullWidth variant="contained" aria-label="Basic button group">
                                        <Button color="error" onClick={() => setIdPakovanje(null)}>
                                            Završi ubacivanje u ovo pakovanje
                                        </Button>
                                        <Button color="success" disabled={!postoji}
                                                onClick={handleSpakuj}>
                                            Spakuj proizvod
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xl: 6, lg: 6, md: 6, sm: 12, xs: 12}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center", height: 400}}>
                            {isError ? (
                                <Alert severity="error">
                                    Greška prilikom učitavanja postojećih pakovanja!
                                </Alert>
                            ) : isLoading ? (
                                <LinearProgress/>
                            ) : (
                                <>
                                    <TableContainer sx={{maxHeight: 700}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={1}
                                                            align="center"
                                                            style={{minWidth: column.minWidth}}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.data.pakovanja
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell key={1} align="center">
                                                                    {row.id}
                                                                </TableCell>
                                                                <TableCell key={2} align="center">
                                                                    <div
                                                                        style={{
                                                                            justifyContent: "center",
                                                                            display: "none",
                                                                        }}
                                                                        id={"stampa" + row.id}
                                                                    >
                                                                        <NalepnicaPakovanje
                                                                            barkod_pakovanja={row.barkod_pakovanja}/>
                                                                    </div>
                                                                    <Button onClick={() => printDiv("stampa" + row.id)}
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            endIcon={<LocalPrintshopTwoToneIcon/>}>
                                                                        Štampaj
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell key={2} align="center">
                                                                    <Button onClick={() => setIdPakovanje(row.id)}
                                                                            disabled={row.id == idPakovanje}
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            endIcon={<OutputTwoToneIcon/>}>
                                                                        Pakuj ovde
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell key={3} align="center">
                                                                    {row.barkod_pakovanja}
                                                                </TableCell>
                                                                <TableCell key={9} align="center">
                                                                    {row.vreme_kreiranja}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={data?.data.pakovanja.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            )}
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                            {isLoading ? <LinearProgress color="inherit" size={24}/> : (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="button"
                                    endIcon={<SendToMobileTwoToneIcon/>}
                                    onClick={handleZavrsi}
                                    disabled={data?.data.za_pakovanje.length != 0}
                                >
                                    Završite pakovanje
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                </>
            ) : (
                <Grid size={{xs: 6}}>
                    <Paper sx={{p: 1, justifyContent: "center", alignItems: "center"}}>
                        <Alert severity="warning">
                            Ovaj radni nalog za završnu kontrolu je uradjen!
                        </Alert>
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
};

export default ZavrsnaKontrolaPage;