import React from 'react';
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getUserInfo } from "../../../apis/korisniciApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { Alert, LinearProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Item } from "../../../components/additional/Item";
import Button from "@mui/material/Button";
import LocalPrintshopTwoToneIcon from "@mui/icons-material/LocalPrintshopTwoTone";
import NalepnicaKorisnik from "../../../components/stampanje/NalepnicaKorisnik";

const KorisnikPage = () => {
    const { id } = useParams();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['korisnik', id],
        queryFn: () => getUserInfo(id)
    });

    const printDiv = (divId) => {
        const container = document.getElementById(divId);
        const width = "100%";
        const height = "100%";
        const printWindow = window.open(
            "",
            "PrintUser",
            "width=" + width + ",height=" + height,
        );
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                <Paper sx={{ p: 2, justifyContent: "center", alignItems: "center" }}>
                    <Typography sx={{ fontSize: 22, textAlign: "center" }}>
                        <PersonIcon size={22} sx={{ mr: 2 }} />
                        Informacije o korisniku
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{ xs: 12 }}>
                <Paper sx={{ p: 2, justifyContent: "center", alignItems: "center" }}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podataka o korisniku!
                        </Alert>
                    ) : isLoading ? <LinearProgress size={24} color="inherit" /> : (
                        <Stack spacing={2}>
                            <Divider />
                            <Item>Ime: <b>{data?.data.ime}</b></Item>
                            <Divider />
                            <Item>Prezime: <b>{data?.data.prezime}</b></Item>
                            <Divider />
                            <Item>Email: <b>{data?.data.email}</b></Item>
                            <Divider />
                            <div style={{
                                justifyContent: "center",
                                display: "flex",
                            }} id="stampanje">
                                <NalepnicaKorisnik
                                    ime={data?.data.ime}
                                    prezime={data?.data.prezime}
                                    email={data?.data.email}
                                    barkod={data?.data.barkod}
                                />
                            </div>
                            <Button
                                sx={{ mt: 2, mb: 2 }}
                                endIcon={<LocalPrintshopTwoToneIcon />}
                                variant="contained"
                                onClick={() => printDiv("stampanje")}
                            >
                                Štampajte nalepnicu
                            </Button>
                            <Divider />
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default KorisnikPage;
