import React from 'react';
import {Navigate, Outlet} from "react-router";
import KontrolaLayout from "../layouts/KontrolaLayout";

const KontrolaProtectedRoute = () => {
    let role_id = localStorage.getItem("role_id");

    if (role_id != 5) {
        return <Navigate to="/"/>;
    }

    return (
        <KontrolaLayout>
            <Outlet/>
        </KontrolaLayout>
    );
};

export default KontrolaProtectedRoute;