import React from 'react';
import {Navigate, Outlet} from "react-router";
import SlajfanjeLayout from "../layouts/SlajfanjeLayout";

const SlajfanjeProtectedRoute = () => {
    let role_id = localStorage.getItem("role_id");

    if (role_id != 2) {
        return <Navigate to="/"/>;
    }

    return (
        <SlajfanjeLayout>
            <Outlet/>
        </SlajfanjeLayout>
    );
};

export default SlajfanjeProtectedRoute;