import React, {useState} from 'react';
import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PolicyIcon from "@mui/icons-material/Policy";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import {
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tableLang} from "../../../language/tableLang";
import TextField from "@mui/material/TextField";
import {createMasina, masine, radnePozicije} from "../../../apis/masinaApi";
import FormControl from "@mui/material/FormControl";

const FormDialog = ({open, onClose, onSubmit}) => {
    const [formData, setFormData] = useState({
        naziv: "",
        serijski_broj: "",
        radna_pozicija: "",
        maseni_kapacitet: 0,
        mk_jedinica: "STK",
        vremenski_kapacitet: 0,
        vk_jedinica: "H",
    });

    const handleChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    };

    const handleSubmit = () => {
        onSubmit(formData);
        onClose();
    };

    const {data, isLoading, isError} = useQuery({
        queryKey: ['radnePozicije'],
        queryFn: radnePozicije
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Unos nove mašine</DialogTitle>
            <DialogContent>
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Naziv"
                    name="naziv"
                    value={formData.naziv}
                    onChange={handleChange}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="Serijski broj"
                    name="serijski_broj"
                    value={formData.serijski_broj}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    select
                    label="Radni centar"
                    name="radna_pozicija"
                    value={formData.radna_pozicija}
                    onChange={handleChange}
                    required
                >
                    {isLoading ? <CircularProgress/> : (data?.data.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.naziv}
                        </MenuItem>
                    )))}
                </TextField>

                <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                    <TextField
                        required
                        fullWidth
                        margin="dense"
                        label="Maseni kapacitet"
                        name="maseni_kapacitet"
                        value={formData.maseni_kapacitet}
                        onChange={handleChange}
                    />
                    <FormControl sx={{width: 150}} margin="dense">
                        <InputLabel>Jedinica</InputLabel>
                        <Select
                            name="mk_jedinica"
                            label="Jedincica"
                            value={formData.mk_jedinica}
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="STK">STK</MenuItem>
                            <MenuItem value="ROLLE">ROLLE</MenuItem>
                            <MenuItem value="PAK">PAK</MenuItem>
                            <MenuItem value="L">L</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                    <TextField
                        required
                        fullWidth
                        margin="dense"
                        label="Vremenski kapacitet"
                        name="vremenski_kapacitet"
                        value={formData.vremenski_kapacitet}
                        onChange={handleChange}
                    />
                    <FormControl sx={{width: 150}} margin="dense">
                        <InputLabel>Jedinica</InputLabel>
                        <Select
                            name="vk_jedinica"
                            label="Jedincica"
                            value={formData.vk_jedinica}
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="H">Sati</MenuItem>
                            <MenuItem value="D">Dani</MenuItem>
                            <MenuItem value="M">Minuti</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">Odustani</Button>
                <Button onClick={handleSubmit} variant="contained" color="success">
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const MasinePage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {data, isLoading, isError} = useQuery({
        queryKey: ["masine"],
        queryFn: masine,
    });

    const columns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "barkod",
            headerName: "Šifra",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "naziv",
            headerName: "Naziv",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "serijski_broj",
            headerName: "Serijski broj",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "radna_pozicija",
            headerName: "Radni centar",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "vreme_kreiranja",
            headerName: "Vreme kreiranja",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                const date = new Date(params.row.vreme_kreiranja);
                return (
                    <span>{date.getDate()} / {(date.getMonth() + 1)} / {date.getFullYear()} | {date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span>
                );
            }
        },
        {
            field: "informacije",
            headerName: "Informacije",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        sx={{width: "100%", alignSelf: "center"}}
                        endIcon={<PrivacyTipTwoToneIcon/>}
                        onClick={() => navigate("../masina/" + params.row.id)}
                    >
                        Informacije
                    </Button>
                );
            },
        },
    ];

    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);
    const [openN, setOpenN] = useState(false);

    const [open, setOpen] = useState(false);
    const handleSubmit = (data) => {
        if (data.naziv == "") {
            setType(2);
            setMessage("Morate uneti naziv mašine!");
            setOpenN(true);
            return;
        }

        if (data.serijski_broj == "") {
            setType(2);
            setMessage("Morate uneti serijski broj mašine!");
            setOpenN(true);
            return;
        }

        if (data.radna_pozicija == "") {
            setType(2);
            setMessage("Morate izabrati radni centar u kom je mašina!");
            setOpenN(true);
            return;
        }

        if (data.maseni_kapacitet == 0) {
            setType(2);
            setMessage("Maseni kapacitet mora biti veći od nule!");
            setOpenN(true);
            return;
        }

        if (data.vremenski_kapacitet == 0) {
            setType(2);
            setMessage("Vremenski kapacitet mora biti veći od nule!");
            setOpenN(true);
            return;
        }

        mutation.mutateAsync(data).then(() => {
            data.naziv = "";
            data.sifra = "";
            data.serijski_broj = "";
            data.radna_pozicija = "";
            data.maseni_kapacitet = 0;
            data.mk_jedinica = "STK";
            data.vremenski_kapacitet = 0;
            data.vk_kapacitet = "H";
        }).catch((e) => {
        });
    };

    const mutation = useMutation({
        mutationFn: (formData) => createMasina(formData),
        onSuccess: (response) => {
            setType(1);
            setMessage(response.data.message);
            setOpenN(true);

            queryClient.invalidateQueries(['masine']).then((response) => {
            });
        },
        onError: (error) => {
            setType(2);
            setMessage(error.response.data.message);
            setOpenN(true);
        }
    });

    return (
        <Grid container spacing={2}>
            <Notification
                type={type}
                message={message}
                open={openN}
                setOpen={setOpenN}
            />
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Typography sx={{fontSize: 22, textAlign: "center"}}>
                        <PolicyIcon size={22} sx={{mr: 2}}/>
                        Sve mašine
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center"}}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        endIcon={<AddBoxTwoToneIcon/>}
                    >
                        <b>Nova mašina</b>
                    </Button>
                </Paper>
            </Grid>
            <Grid size={{xs: 12}}>
                <Paper sx={{p: 2, justifyContent: "center", alignItems: "center", height: 600}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom učitavanja mašina!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <DataGrid
                            localeText={tableLang}
                            columns={columns}
                            rows={isLoading ? [] : data?.data}
                        />
                    )}
                </Paper>
            </Grid>
            <FormDialog open={open} onClose={() => setOpen(false)} onSubmit={handleSubmit}/>
        </Grid>
    );
};

export default MasinePage;