import React from "react";
import mes from "../../assets/mes.png";
import logo from "../../assets/logo.png";
import QRCode from "react-qr-code";

const NalepnicaProizvod = ({naziv, serijski_broj, sifra,}) => {
    return (
        <div
            style={{
                backgroundColor: "white",
                display: "grid",
                gridTemplateColumns: "auto auto",
                width: "4in",
                height: "2in",
            }}
        >
            <div>
                <img src={mes} height={25} alt={"#"}/>
            </div>
            <div style={{display: "flex", justifyContent: "end"}}>
                <img src={logo} height={25} alt={"#"}/>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <QRCode
                    size="0.6in"
                    style={{
                        height: "auto",
                        maxWidth: "65%",
                        width: "65%",
                    }}
                    value={serijski_broj}
                />
            </div>

            <div style={{justifyContent: "center"}}>
                <p
                    style={{
                        textAlign: "center",
                        fontSize: 16,
                        color: "black",
                        margin: 1,
                        padding: 1,
                    }}
                >
                    {naziv}
                </p>
                <p
                    style={{
                        textAlign: "center",
                        fontSize: 16,
                        color: "black",
                        margin: 1,
                        padding: 1,
                    }}
                >
                    {sifra}
                </p>
            </div>
            <div
                style={{
                    gridColumn: "1 / span 2",
                    gridRow: 3,
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                {serijski_broj}
            </div>
        </div>
    );
};

export default NalepnicaProizvod;