import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {dobavljac, updateDobavljac} from "../../../apis/dobavljaciApi";
import {Alert, Button, Divider, LinearProgress, Paper, Stack, TextField, Typography,} from "@mui/material";
import Grid from "@mui/material/Grid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export const DobavljacPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [dobavljacData, setDobavljacData] = useState({});

    const {data, isLoading, isError} = useQuery({
        queryKey: ["dobavljac", id],
        queryFn: () => dobavljac(id),
    });

    useEffect(() => {
        if (data?.data) {
            setDobavljacData(data.data);
        }
    }, [data]);

    const mutation = useMutation({
        mutationFn: updateDobavljac,
        onSuccess: () => navigate("../dobavljaci"),
    });

    const handleChange = (e) => {
        setDobavljacData({...dobavljacData, [e.target.name]: e.target.value});
    };

    const handleSave = () => {
        mutation.mutate(dobavljacData);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper sx={{p: 2, textAlign: "center"}}>
                    <Typography sx={{fontSize: 22}}>
                        <LocalShippingIcon sx={{mr: 2}}/>
                        Informacije o dobavljaču
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper sx={{p: 2}}>
                    {isError ? (
                        <Alert severity="error">
                            Greška prilikom preuzimanja podataka o dobavljaču!
                        </Alert>
                    ) : isLoading ? (
                        <LinearProgress/>
                    ) : (
                        <Stack spacing={2}>
                            <TextField
                                label="Naziv"
                                fullWidth
                                name="naziv"
                                value={dobavljacData.naziv || ""}
                                onChange={handleChange}
                            />
                            <Divider/>
                            <TextField
                                label="Adresa"
                                fullWidth
                                name="adresa"
                                value={dobavljacData.adresa || ""}
                                onChange={handleChange}
                            />
                            <Divider/>
                            <TextField
                                label="Telefon"
                                fullWidth
                                name="telefon"
                                value={dobavljacData.telefon || ""}
                                onChange={handleChange}
                            />
                            <Divider/>
                            <TextField
                                label="Email"
                                fullWidth
                                name="email"
                                value={dobavljacData.email || ""}
                                onChange={handleChange}
                            />
                            <Divider/>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={mutation.isLoading}
                            >
                                Sačuvaj
                            </Button>
                        </Stack>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};